import { MutateOptions, useMutation } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { PhoneResendModel } from './PhoneResendModel'
import { adapter } from './adapters/adapter'
import { stringTemplate } from '@/utils/string-template'

export const phoneResend: PhoneResendModel.Func = async (props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.transfers.phoneResend,
    {} as ApiManagerModel.ApiOption
  )

  const response = await HttpClient.get<
    PhoneResendModel.ResponseData,
    void,
    PhoneResendModel.RequestHeaders,
    PhoneResendModel.ErrorResponse
  >({
    url: stringTemplate<{ requestId: string }>(path, { requestId: props.requestId }),
    headers: null,
    responseAdapter: adapter,
    control,
  })

  return response
}

export function usePhoneResendMutation(
  options?: MutateOptions<
    PhoneResendModel.Response,
    PhoneResendModel.ErrorResponse,
    PhoneResendModel.Props
  >
) {
  return useMutation(phoneResend, options)
}
