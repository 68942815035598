import React from 'react'
import { Accordion } from '@platform-ui/components/Accordion'
import { Fade } from '@platform-ui/components/effects/Fade'
import { CircularProgress } from '@platform-ui/components/CircularProgress'
import { useKeepExistingData } from '@platform-ui/utils'
import { RootInnerBlock, Title, Additional, Term, LoaderBlock } from './styled'
import { Body14 } from '@platform-ui/typography'
import { StepperSumProps } from './StepperSum'

export const InnerBlock = (props: {
  commission: StepperSumProps['commission']
  isLoading: StepperSumProps['isLoadingCommission']
}) => {
  const commissionData = useKeepExistingData(props.commission)

  return (
    <RootInnerBlock>
      <Title>
        {commissionData.title && <Body14>{commissionData.title}</Body14>}
        {props.isLoading && <CircularProgress size={18} />}
      </Title>
      {commissionData.description && (
        <Additional>
          <Term>{commissionData.description}</Term>
        </Additional>
      )}
    </RootInnerBlock>
  )
}

export const Commission = ({ commission, isLoadingCommission }) => {
  const hasCommissionData = !!commission

  return (
    <Accordion isOpen={hasCommissionData}>
      {hasCommissionData ? (
        <Fade isOpen={hasCommissionData}>
          <InnerBlock commission={commission} isLoading={isLoadingCommission} />
        </Fade>
      ) : isLoadingCommission ? (
        <LoaderBlock>
          <CircularProgress size={24} />
        </LoaderBlock>
      ) : null}
    </Accordion>
  )
}
