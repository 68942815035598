import { useQuery, UseQueryOptions } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { BetweenSelfModel } from './BetweenSelfModel'
import { adapter } from './adapters/adapter'

export const betweenSelf: BetweenSelfModel.Func = async (signal, props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.transfers.betweenSelf,
    {} as ApiManagerModel.ApiOption
  )

  return await HttpClient.get<
    BetweenSelfModel.ResponseData,
    void,
    BetweenSelfModel.RequestHeaders,
    BetweenSelfModel.ErrorResponse
  >({
    url: path,
    headers: null,
    responseAdapter: (data, _, status) => {
      return adapter(data, _, status)(props.contractNumber)
    },
    control,
    signal,
  })
}

function betweenSelfKey() {
  return '/api/transfers/betweenSelf'
}

export function useBetweenSelfQuery<
  T extends BetweenSelfModel.ResponseData = BetweenSelfModel.ResponseData
>(
  props: BetweenSelfModel.Props,
  options: Omit<
    UseQueryOptions<BetweenSelfModel.ResponseData, BetweenSelfModel.ErrorResponse, T>,
    'queryKey' | 'queryFn'
  > = {}
) {
  const key = betweenSelfKey()

  return useQuery(key, (state) => betweenSelf(state.signal, props).then((res) => res.response), {
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    cacheTime: Infinity,
    staleTime: Infinity,
    keepPreviousData: true,
    ...options,
  })
}
