import React from 'react'
import { CurrencyType } from '@/models/CurrencyType'
import { observer } from 'mobx-react-lite'
import { Control, useController, useFormContext } from 'react-hook-form'
import { Root, FieldControlCustom, CommissionFieldStyled } from './styled'
import { currency } from '@/utils/currency'
import { ApiStatus } from '@/core'
import { SuccessIcon } from '@platform-ui/icons/SuccessIcon'
import { Commission } from './Commission'
import { ButtonsGroup } from './ButtonsGroup'

export interface StepperSumProps {
  className?: string
  availableBalance: number
  control: Control<any, any>
  isDisabled: boolean
  limits:
    | {
        status: ApiStatus.Idle
      }
    | {
        status: ApiStatus.Pending
      }
    | {
        status: ApiStatus.Rejected
      }
    | {
        status: ApiStatus.Fulfilled
        maxTransferLimit: number
        minTransferLimit: number
      }
  placeholder: string
  currencyType: CurrencyType
  commission: {
    title?: string
    description?: string
  }
  name: string
  isLoadingCommission: boolean
  isPrepare: boolean
}

export const StepperSum = observer((props: StepperSumProps) => {
  const {
    className,
    availableBalance,
    control,
    isDisabled,
    limits,
    placeholder = 'Сумма',
    currencyType,
    commission,
    name,
    isLoadingCommission,
    isPrepare,
  } = props

  const { field, fieldState } = useController<{ sum: string }>({
    control: control,
    name: name as 'sum',
    defaultValue: '',
  })

  const { setValue } = useFormContext()

  const hasError = fieldState.error?.message

  return (
    <Root className={className}>
      <FieldControlCustom isBlock error={hasError}>
        <CommissionFieldStyled
          isBlock
          type="text"
          rightSlot={isPrepare && <SuccessIcon color="icons/colors/success" />}
          placeholder={placeholder}
          status={hasError ? 'error' : 'default'}
          isLoadingCommission={isLoadingCommission}
          isDisabled={isDisabled}
          commission={commission}
          currencySymbol={currency(currencyType)}
          onClear={() => {
            setValue(name, '')
          }}
          autoComplete="off"
          onChange={field.onChange}
          name={field.name}
          value={field.value}
          isReadOnly={isPrepare}
        />
      </FieldControlCustom>

      {!(isPrepare || isDisabled) && (
        <Commission commission={commission} isLoadingCommission={isLoadingCommission} />
      )}

      {!(isPrepare || isDisabled) && limits.status === ApiStatus.Fulfilled && (
        <ButtonsGroup
          name={name}
          availableBalance={availableBalance}
          limits={limits}
          currencyType={currencyType}
          setValue={setValue}
          control={control}
        />
      )}
    </Root>
  )
})
