import { usePhoneProductListQuery } from '@/api/transfers/phoneProductList'
import { statusToApiStatusEnum } from '@/utils/statusToApiStatusEnum'
import { createSelector } from 'reselect'

interface PhoneProductListSelectorState {
  phoneProductList: ReturnType<typeof usePhoneProductListQuery>
}
export const phoneProductListSelector = createSelector(
  (state: PhoneProductListSelectorState) => state.phoneProductList.data,
  (state: PhoneProductListSelectorState) => state.phoneProductList.status,
  (data, status) => ({
    data: data,
    status: statusToApiStatusEnum(status),
    refetchKey: 'phoneProductList',
  })
)
