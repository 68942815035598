import { useBetweenSelfTypeMutation } from '@/api/transfers/betweenSelfType'
import { ApiStatus } from '@/core'
import { ResponseType } from '@/models/ResponseType'
import { currency } from '@/utils/currency'
import { checkValid, requireRule, ValidationSchema } from '@/utils/validation-resolver'
import { useProductsSelector } from '../../hooks/useProductsSelector'
import { FormValues } from './useFormState'
import { sumToNumber } from './utils'

export const validationSchema =
  (props: {
    betweenSelfTypeMutation: ReturnType<typeof useBetweenSelfTypeMutation>
    productsSelector: ReturnType<typeof useProductsSelector>
  }): ValidationSchema<FormValues> =>
  (data, errors) => {
    errors.fromProductId = checkValid(data.fromProductId, [
      (productId) => {
        if (
          !productId ||
          props.productsSelector.status !== ApiStatus.Fulfilled ||
          !props.productsSelector.products[productId]
        ) {
          return
        }
        const product = props.productsSelector.products[productId]
        if (product.availableBalance !== 0) {
          return
        }

        return 'Недостаточно средств для списания'
      },
    ])

    errors.sum = checkValid(data.sum, [
      requireRule('Обязательное поле'),
      (value) => {
        if (
          !data.fromProductId ||
          props.productsSelector.status !== ApiStatus.Fulfilled ||
          !props.productsSelector.products[data.fromProductId]
        ) {
          return
        }
        const newValue = sumToNumber(value) || 0
        const product = props.productsSelector.products[data.fromProductId]
        if (newValue <= product.availableBalance) {
          return
        }

        return 'Превышен лимит'
      },
      (value) => {
        if (
          props.betweenSelfTypeMutation.data?.response?.type !== ResponseType.ResolveWithData ||
          !data.fromProductId ||
          props.productsSelector.status !== ApiStatus.Fulfilled ||
          !props.productsSelector.products[data.fromProductId]
        ) {
          return
        }
        const product = props.productsSelector.products[data.fromProductId]

        const { maxTransferLimit, minTransferLimit } =
          props.betweenSelfTypeMutation.data.response.limit

        const newValue = sumToNumber(value) || 0
        if (newValue < minTransferLimit) {
          return `Минимальная сумма ${currency(minTransferLimit, product.currencyType).format()}`
        }

        if (newValue > maxTransferLimit) {
          return 'Превышен лимит'
        }
      },
    ])

    return errors
  }
