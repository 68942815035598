import { useMutation, UseMutationOptions } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { BetweenSelfCommissionModel } from './BetweenSelfCommissionModel'
import { adapter } from './adapters/adapter'
import { currencyType } from '@/utils/adapter-helpers/currencyType'
import { stringTemplate } from '@/utils/string-template'

/**
 * Commission
 * @see https://confluence.homecredit.ru/confluence/pages/viewpage.action?pageId=92967966
 */
export const betweenSelfCommission: BetweenSelfCommissionModel.Func = async (props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.transfers.betweenSelfCommission,
    {} as ApiManagerModel.ApiOption
  )

  const response = await HttpClient.post<
    BetweenSelfCommissionModel.ResponseData,
    void,
    BetweenSelfCommissionModel.Requestdata,
    BetweenSelfCommissionModel.RequestHeaders,
    BetweenSelfCommissionModel.ErrorResponse
  >({
    url: stringTemplate<{ requestId: string }>(path, { requestId: props.requestId }),
    headers: null,
    responseAdapter: adapter,
    data: {
      currency: currencyType(props.currencyType).toServer(),
      sum: props.sum,
    },

    control,
  })

  return response
}

export function useBetweenSelfCommissionMutation(
  options?: Omit<
    UseMutationOptions<
      BetweenSelfCommissionModel.Response,
      BetweenSelfCommissionModel.ErrorResponse,
      BetweenSelfCommissionModel.Props,
      unknown
    >,
    'mutationFn'
  >
) {
  return useMutation(betweenSelfCommission, options)
}
