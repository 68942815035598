import { SelectProductsModel } from '@/components/SelectProducts'
import { ApiStatus } from '@/core'
import { createSelector } from 'reselect'
import { createSelectProductsItems } from '../utils/createSelectProductItems'
import { Response } from './productsSelector'

export const selectProductFromItemsSelector = createSelector(
  (state: { productsSelector: Response }) => state.productsSelector,
  (state: { ignoreProductId: string }) => state.ignoreProductId,
  (productsSelector, ignoreProductId): SelectProductsModel.Item[] => {
    if (productsSelector.status !== ApiStatus.Fulfilled) {
      return []
    }

    return createSelectProductsItems(
      productsSelector.fromProductIds,
      productsSelector.products,
      ignoreProductId ? [ignoreProductId] : []
    )
  }
)
