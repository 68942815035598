import styled from '@emotion/styled'
import { ButtonBase } from '@platform-ui/components/ButtonBase'
import { Headline16, Body14 } from '@platform-ui/typography'
import { Shimmer } from '@platform-ui/components/Shimmer'

export const Root = styled(ButtonBase)(({ theme }) => ({
  display: 'flex',
  background: theme.color['background/main'],
  borderRadius: '12px',
  width: '100%',
  minHeight: '66px',
  padding: '8px 16px',
  minWidth: 0,
  position: 'relative',

  ':after': {
    content: "''",
    display: 'block',
    position: 'absolute',
    bottom: 0,
    background: theme.color['surface/line'],
    height: 1,
    width: 'calc(100% - 32px)',
  },
}))

export const ImageCustom = styled.img(() => ({
  objectFit: 'cover',
}))

export const Title = styled(Headline16)(({ theme }) => ({
  color: theme.color['text/main'],
  whiteSpace: 'nowrap',
  flex: 1,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  minWidth: 0,
  textAlign: 'left',
  display: 'block',
}))

export const Desc = styled(Body14)(({ theme }) => ({
  textAlign: 'left',
  color: theme.color['text/secondary'],
}))

export const Icon = styled.div(() => ({
  flexShrink: 0,
  marginRight: '12px',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
}))

export const SbpIconWrapper = styled.div(() => ({
  position: 'absolute',
  right: 0,
  bottom: -4,
  height: 24,
}))

export const InfoContainer = styled.div(() => ({
  minWidth: 0,
  flexGrow: 1,
}))

export const IconShimmer = styled(Shimmer)(() => ({
  width: 40,
  height: 40,
  borderRadius: '50%',
}))
