import styled from '@emotion/styled'
import { FieldControl } from '@platform-ui/components/FieldControl'
import { InputPhoneNumber } from '@platform-ui/components/InputPhoneNumber'
import { Button } from '@platform-ui/components'

export const FieldControlCustom = styled(FieldControl)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  marginBottom: '16px',
}))

export const InputPhoneNumberStyled = styled(InputPhoneNumber)<{
  isReadOnly: boolean
  isDisabled: boolean
}>(({ theme, isReadOnly, isDisabled }) => ({
  input: {
    color: isReadOnly && theme.color['text/secondary'],
    cursor: isReadOnly && 'not-allowed',
    background: isDisabled && theme.color['surface/tertiary'],
  },
  span: {
    color: isDisabled && '#BCC0CC',
  },
}))

export const ButtonStyled = styled(Button)(() => ({
  borderRadius: 21,
  marginBottom: 24,
}))
