import { useProductsQuery } from '@/api/products/products'
import { useInjectRefetch } from '@/utils/useInjectRefetch'
import { productsSelector } from '../selectors/productsSelector'
import { usePhoneProductListQuery } from '@/api/transfers/phoneProductList'
import { useClientProductsQuery } from '@/api/products/clientProducts'

export function useProductsSelector(
  productsQuery: ReturnType<typeof useProductsQuery>,
  clientProductsQuery: ReturnType<typeof useClientProductsQuery>
) {
  const phoneProductList = usePhoneProductListQuery()

  return useInjectRefetch(productsSelector, {
    products: productsQuery,
    phoneProductList: phoneProductList,
    clientProducts: clientProductsQuery,
  })
}
