import { useQuery, UseQueryOptions } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { ClientCardsModel } from './ClientCardsModel'
import { adapter } from './adapters/adapter'
import { stringTemplate } from '@/utils/string-template'

export const clientCards = async (props: ClientCardsModel.Props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.pms.clientCards,
    {} as ApiManagerModel.ApiOption
  )

  const response = await HttpClient.get<
    ClientCardsModel.ResponseData,
    void,
    ClientCardsModel.RequestHeaders,
    ClientCardsModel.ErrorResponse
  >({
    url: stringTemplate<{ BeneficiaryId: number }>(path, {
      BeneficiaryId: props.beneficiaryId,
    }),
    headers: {
      _ver_: props.apiVersion,
      _os_: '4',
    },
    responseAdapter: adapter,
    control,
  })

  return response
}

export function clientCardsQueryKey(props: { beneficiaryId: number }) {
  return ['/v3/card/client-cards', props.beneficiaryId]
}

export function useClientCardsQuery<
  T extends ClientCardsModel.ResponseData = ClientCardsModel.ResponseData
>(
  props: ClientCardsModel.Props,
  options?: Omit<
    UseQueryOptions<ClientCardsModel.ResponseData, ClientCardsModel.ErrorResponse, T>,
    'queryKey' | 'queryFn'
  >
) {
  const key = clientCardsQueryKey(props)
  return useQuery(key, () => clientCards({ ...props }).then((res) => res.data), options)
}
