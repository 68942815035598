import { AdapterModel } from './AdapterModel'
import { ResponseType } from '@/models/ResponseType'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'
import { HttpClient } from '@/core'
import { currencyType } from '@/utils/adapter-helpers/currencyType'

/**
 * Если есть маска, то убираем все лишние символы, далее 0 и 9 меняем на hash
 * @example 8([000])[000] [00]-[00] => 8(###)### ##-##
 * @example [0999] [123] [9999] => #### 123 ####
 */
const getFormattedMask = (mask) => {
  if (!mask) {
    return mask
  }

  const formattedMask = mask.replace(/[^0-9-() ]/g, '').replace(/[09]/gi, '#')

  if (!formattedMask.includes('#')) {
    return null
  }

  return formattedMask
}

export const adapter: AdapterModel.Func = (data) => {
  const errorData = data as AdapterModel.ResponseDataError
  /**
   * Сервер всегда возвращает status=200 в заголовке
   * Реальный статус приходит в теле ответа
   */
  if (errorData?.StatusCode >= 400) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: errorData.ErrorDetails
        ? errorData.ErrorDetails.map(({ Error }) => ({
            type: Error,
            message: Error,
          }))
        : [],
    }
    throw HttpClient.createError(errorData?.StatusCode, exception)
  }
  try {
    const successData = data as AdapterModel.ResponseDataSuccess

    return {
      type: ResponseType.ResolveWithData,
      isFinalStep: successData.Result.IsFinalStep,
      processPaymentType: successData.Result.ProcessPaymentType,
      name: successData.Result.Name,
      paymentHubSession: successData.Result.PaymentHubSession,
      icon: successData.Result.Icon,
      feeInfo: successData.Result.FeeInfo,
      fields: successData.Result.Fields.filter((i) => !i.IsHidden).map((item) => {
        const InputRulesAmount = item.InputRules as AdapterModel.InputRulesTypeAmount
        const InputRulesString = item.InputRules as AdapterModel.InputRulesTypeString
        const InputRulesSupportValues = item.InputRules as AdapterModel.InputRulesTypeSupportValues

        const formattedHint =
          item.Hint && item.Hint.includes('<img')
            ? item.Hint.slice(0, item.Hint.indexOf('<img'))
            : item.Hint

        return {
          name: item.Name,
          fieldKey: item.FieldKey,
          type: item.Type,
          inputMethods: item.InputMethods,
          isRequired: item.IsRequired,
          isHidden: item.IsHidden,
          isReadOnly: item.IsReadOnly,
          hint: formattedHint || '',
          isRefreshRequired: item.IsRefreshRequired,
          mask: getFormattedMask(InputRulesString.Mask),
          regexp: InputRulesString.Regexp,
          minSumValue: InputRulesAmount.MinSumValue,
          maxSumValue: InputRulesAmount.MaxSumValue,
          currency: InputRulesAmount.Currency
            ? currencyType(InputRulesAmount.Currency).toClient()
            : null,
          allowedValue:
            item.Type === 'SupportValues'
              ? InputRulesSupportValues.AllowedValue.map((i) => ({
                  value: i.Value,
                  displayName: i.DisplayName,
                }))
              : [],
          defaultValue: item?.DefaultValue,
          errorText: item?.ErrorText,
        }
      }),
    }
  } catch (error) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: [{ message: 'Ошибка при обработке полученных данных', type: 'ADAPTER_ERROR' }],
    }

    throw HttpClient.createError(500, exception)
  }
}
