import styled from '@emotion/styled'
import { Input } from '@platform-ui/components/Input'
import { Shimmer } from '@platform-ui/components/Shimmer'

export const InputStyled = styled(Input)<{
  isOpen: boolean
  isDisabled: boolean
  isLoading: boolean
}>(({ theme, isOpen, isDisabled, isLoading }) => ({
  input: {
    background: isDisabled && theme.color['surface/tertiary'],
    cursor: isLoading ? 'not-allowed' : 'pointer',
    borderRadius: isOpen && '12px 12px 0 0',
    paddingLeft: 48,

    ':not(:disabled)': {
      ':focus, :active': {
        paddingLeft: 48,
      },
    },
  },
  span: {
    color: isDisabled && '#BCC0CC',
  },
  svg: {
    transition: 'transform 200ms linear',
    transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
  },
}))

export const PrepareSuccessInput = styled(Input)(({ theme }) => ({
  input: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '16px',
    color: theme.color['text/secondary'],
    paddingLeft: 67,

    ':not(:disabled)': {
      ':focus, :active': {
        paddingLeft: 66,
      },
    },
  },
  span: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
    paddingLeft: 52,
  },
}))

export const BankLogo = styled.img(() => ({
  position: 'absolute',
  zIndex: 2,
  left: 16,
  bottom: 12,
}))

export const IconShimmer = styled(Shimmer)(() => ({
  position: 'absolute',
  zIndex: 2,
  left: 16,
  bottom: 10,
  width: 24,
  height: 24,
  borderRadius: '50%',
}))

export const Icon = styled.div(() => ({
  marginRight: '12px',
}))

export const SbpIconWrapper = styled.div(({ theme }) => ({
  position: 'absolute',
  left: 36,
  bottom: 6,
  border: `1px solid ${theme.color['icons/disabled']}`,
  borderRadius: '100px',
  boxSizing: 'content-box',
  zIndex: 3,
  height: 24,
  width: 24,
}))
