import { HttpClientModel } from '@/core'
import { ResolveWithSystemErrorResponse } from '@/models/ResolveWithSystemErrorResponse'
import { ResponseType } from '@/models/ResponseType'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'

/**
 * @see https://confluence.homecredit.ru/confluence/pages/viewpage.action?pageId=95129183
 */
export namespace BetweenSelfTypeModel {
  export type Func = (props: Props) => Promise<Response>
  export interface Props {
    requestId: string
    productFrom:
      | {
          productType: 'card'
          /**
           * @example  '446915XXXXXX9498'
           */
          maskCardNumber: string
          /**
           * @example  '40817810790012545944'
           */
          accountNumber: string
        }
      | {
          productType: 'account'

          /**
           * @example  '40817810790012545944'
           */
          accountNumber: string
        }

    productTo:
      | {
          productType: 'card'
          /**
           * @example  '446915XXXXXX9498'
           */
          maskCardNumber: string
          /**
           * @example  '40817810790012545944'
           */
          accountNumber: string
        }
      | {
          productType: 'account'

          /**
           * @example  '40817810790012545944'
           */
          accountNumber: string
        }
  }

  export type Response = HttpClientModel.Response<ResponseData>
  export type ErrorResponse = HttpClientModel.ErrorResponse<ErrorResponseData>
  export type ErrorResponseData = RejectedWithSystemErrorResponse
  export type ResponseData = ResolveWithDataResponse
  export type RequestHeaders = null

  export enum TransferType {
    // Внешний. межбанк по карте
    External = 'external',

    // межбанк ЮЛ
    ExternalCompany = 'externalCompany',

    // межбанк ФЛ
    ExternalPerson = 'externalPerson',

    // межбанк ИП
    ExternalProprietor = 'externalProprietor',

    // Внутренний. внутренний по карте
    Internal = 'internal',

    // Внутренний. внутренний ЮЛ
    InternalCompany = 'internalCompany',

    // внутренний ФЛ
    InternalPerson = 'internalPerson',

    // Внутренний между своими счетами.
    InternalSelf = 'internalSelf',

    // Внутренний между своими счетами с разными валютами.
    InternalSelfExchange = 'internalSelfExchange',

    // внутренний ИП
    InternalProprietor = 'internalProprietor',

    // перевод СБП от ФЛ в пользу ЮЛ
    C2b = 'c2b',

    // бюджет РФ
    Budget = 'budget',
  }
  export interface Requestdata {
    productFrom: {
      /**
       * @example  '446915XXXXXX9498'
       */
      maskCardNumber?: string
      /**
       * @example  '40817810790012545944'
       */
      accountNumber: string
    }

    productTo: {
      /**
       * @example  '446915XXXXXX9498'
       */
      maskCardNumber?: string
      /**
       * @example  '40817810790012545944'
       */
      accountNumber: string
    }
  }
  export interface ResolveWithDataResponse {
    type: ResponseType.ResolveWithData
    transferType: TransferType
    recipientName: string
    enrolment: string
    limit: Limit
    externalTransfer?: boolean
    resident?: boolean
  }

  interface Limit {
    minTransferLimit: number
    maxTransferLimit: number
  }
}
