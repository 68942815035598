import { AdapterModel } from './AdapterModel'
import { ResponseType } from '@/models/ResponseType'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'
import { HttpClient } from '@/core'
import { currencyType } from '@/utils/adapter-helpers/currencyType'

export const adapter: AdapterModel.Func = (data) => {
  const errorData = data as AdapterModel.ResponseDataError

  /**
   * Сервер всегда возвращает status=200 в заголовке
   * Реальный статус приходит в теле ответа
   */
  if (errorData?.StatusCode >= 400) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors:
        errorData.ErrorDetails?.map(({ Error, Title }) => ({
          type: Error,
          message: Title,
        })) || [],
    }
    throw HttpClient.createError(errorData?.StatusCode, exception)
  }

  const resultCodeError = data as AdapterModel.ResultCodeNotFound

  if (resultCodeError?.resultCode && resultCodeError?.resultText) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: [
        {
          message: resultCodeError.resultText,
          type: resultCodeError.resultCode,
        },
      ],
    }
    throw HttpClient.createError(500, exception)
  }

  try {
    const { provider } = data as AdapterModel.ResponseDataSuccess

    return {
      type: ResponseType.ResolveWithData,
      provider: {
        bankId: provider.bankId,
        currencyType: currencyType(provider.currency).toClient(),
        fee: provider.fee,
        fullSum: provider.fullSum,
        limit: provider.limit,
        phoneNumber: provider.alias,
        id: provider.providerId,
        recipientName: provider.recipientName,
        sum: provider.sum,
        transactionProductFrom: provider.transactionProductFrom,
        transactionProductTo: provider.transactionProductTo,
        transferType: provider.transferType,
      },
    }
  } catch (error) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: [{ message: 'Ошибка при обработке полученных данных', type: 'ADAPTER_ERROR' }],
    }

    throw HttpClient.createError(500, exception, error)
  }
}
