import { AdapterModel } from './AdapterModel'
import { ResponseType } from '@/models/ResponseType'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'
import { HttpClient } from '@/core'
import { currencyType } from '@/utils/adapter-helpers/currencyType'
import { paymentSystem } from '@/utils/adapter-helpers/paymentSystem'
import { ClientCardsModel } from '../ClientCardsModel'
import { createDebitCardId } from '@/utils/adapter-helpers/createDebitCardId'
import { createCreditCardId } from '@/utils/adapter-helpers/createCreditCardId'
import { createInstalmentCardId } from '@/utils/adapter-helpers/createInstalmentCardId'
import { color } from '@platform-ui/core'

const bgColorMapping = (
  markType: ClientCardsModel.ProductStyle
): {
  bgColorTheme: 'light' | 'dark'
  bgColor: string
} => {
  switch (markType) {
    case ClientCardsModel.ProductStyle.Kids:
      return {
        bgColor: color['products/kids'],
        bgColorTheme: 'light',
      }
    default:
      return {
        bgColor: color['products/rebranding/Polza'],
        bgColorTheme: 'dark',
      }
  }
}
export const adapter: AdapterModel.Func = (data): any => {
  const errorData = data as AdapterModel.ResponseDataError
  /**
   * Сервер всегда возвращает status=200 в заголовке
   * Реальный статус приходит в теле ответа
   */
  if (errorData?.StatusCode >= 400) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: errorData.ErrorDetails
        ? errorData.ErrorDetails.map(({ Error }) => ({
            type: Error,
            message: Error,
          }))
        : [],
    }
    throw HttpClient.createError(errorData?.StatusCode, exception)
  }
  try {
    const successData = data as AdapterModel.ResponseDataSuccess

    const debitCards = successData.Result.ClientCards.filter(
      (card) => card.ProductSubType === ClientCardsModel.ProductSubType.DebitCard
    ).map((card) => {
      const { bgColor, bgColorTheme } = bgColorMapping(card.ProductStyle)

      return {
        id: createDebitCardId(card.MaskedCardNumber),
        productName: card.ProductName,
        productType: ClientCardsModel.ProductType.DebitCard,
        productSubType: card.ProductSubType,
        availableBalance: card.AvailableBalance,
        productStyle: card.ProductStyle,
        maskedCardNumber: card.MaskedCardNumber,
        paymentSystem: paymentSystem(card.PaymentSystem),
        accountNumber: card.AccountNumber,
        currencyType: currencyType(card.Currency).toClient(),
        lastFourCardDigits: card.MaskedCardNumber.slice(-4),
        bgColor,
        bgColorTheme,
      }
    })

    const creditCards = successData.Result.ClientCards.filter(
      (card) =>
        card.ProductSubType === ClientCardsModel.ProductSubType.CreditCardRD ||
        card.ProductSubType === ClientCardsModel.ProductSubType.CreditCardRDTW
    ).map((card) => {
      const isInstalmentCard =
        card.ProductSubType === ClientCardsModel.ProductSubType.CreditCardRDTW
      return {
        id: isInstalmentCard
          ? createInstalmentCardId(card.MaskedCardNumber)
          : createCreditCardId(card.MaskedCardNumber),
        productName: card.ProductName,
        productType: ClientCardsModel.ProductType.CreditCard,
        productSubType: card.ProductSubType,
        availableBalance: card.AvailableBalance,
        productStyle: card.ProductStyle,
        maskedCardNumber: card.MaskedCardNumber,
        paymentSystem: paymentSystem(card.PaymentSystem),
        accountNumber: card.AccountNumber,
        currencyType: currencyType(card.Currency).toClient(),
        lastFourCardDigits: card.MaskedCardNumber.slice(-4),
        bgColor: isInstalmentCard ? color['products/undefined'] : color['products/rebranding/days'],
        bgColorTheme: 'dark',
      }
    })

    return {
      type: ResponseType.ResolveWithData,
      products: [...debitCards, ...creditCards],
    }
  } catch (error) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: [{ message: 'Ошибка при обработке полученных данных', type: 'ADAPTER_ERROR' }],
    }

    throw HttpClient.createError(500, exception)
  }
}
