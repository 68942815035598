import { MutateOptions, useMutation } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { PhonePrepareModel } from './PhonePrepareModel'
import { adapter } from './adapters/adapter'
import { stringTemplate } from '@/utils/string-template'
import { currencyType } from '@/utils/adapter-helpers/currencyType'

/**
 * @see https://confluence.homecredit.ru/confluence/pages/viewpage.action?pageId=117227554
 */
export const phonePrepare: PhonePrepareModel.Func = async (props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.transfers.phonePrepare,
    {} as ApiManagerModel.ApiOption
  )
  props.phoneNumber = props.phoneNumber.replaceAll('+', '')

  const response = await HttpClient.post<
    PhonePrepareModel.ResponseData,
    void,
    PhonePrepareModel.Requestdata,
    PhonePrepareModel.RequestHeaders,
    PhonePrepareModel.ErrorResponse
  >({
    url: stringTemplate<{ requestId: string }>(path, { requestId: props.requestId }),
    headers: {
      _os_: '4',
    },
    responseAdapter: adapter,
    data: {
      alias: props.phoneNumber,
      bankId: props.bankId,
      currency: currencyType(props.currencyType).toServer(),
      sum: props.sum,
      transferType: props.transferType,
      provider: props.provider,
      authId: props.authId,
      transactionProductFrom:
        props.transactionProductFrom.productType === 'card'
          ? {
              accountNumber: props.transactionProductFrom.accountNumber,
              maskCardNumber: props.transactionProductFrom.maskCardNumber,
            }
          : {
              accountNumber: props.transactionProductFrom.accountNumber,
            },
    },
    options: {
      auth: true,
    },
    control,
  })

  return response
}

export function usePhonePrepareMutation(
  options?: MutateOptions<
    PhonePrepareModel.Response,
    PhonePrepareModel.ErrorResponse,
    PhonePrepareModel.Props
  >
) {
  return useMutation(phonePrepare, options)
}
