import { ResponseType } from '@/models/ResponseType'
import { createSelector } from 'reselect'
import { ProductsModel } from '@/api/products/products'
import { ApiStatus } from '@/core'
import _keyBy from 'lodash/keyBy'
import { betweenSelfSelector } from './betweenSelfSelector'
import { AggregationError, productsSelector } from './productsSelector'
import { ClientProductsModel } from '@/api/products/clientProducts'
import { Refetch, RefetchCollection } from '@/utils/useInjectRefetch'
import { betweenSelfProductToListSelector } from './betweenSelfProductToListSelector'
import { SystemError } from '@/models/SystemError'

export type FromProduct = ProductsModel.DebitCard | ProductsModel.CreditCard | ProductsModel.Account

export type ToProduct =
  | ProductsModel.DebitCard
  | ProductsModel.CreditCard
  | ClientProductsModel.CreditLoan
  | ProductsModel.Account
  | ProductsModel.Deposit
export type Product = FromProduct | ToProduct

export type Response = ResponseIdle | ResponsePending | ResponseFulfilled | ResponseRejected
export interface ResponseIdle {
  status: ApiStatus.Idle
}
export interface ResponsePending {
  status: ApiStatus.Pending
}
export interface ResponseFulfilled extends Refetch {
  error: AggregationError
  status: ApiStatus.Fulfilled
  productIds: string[]
  products: Record<string, Product>
  fromProductIds: string[]
  isPendingFromProductIds: boolean
  toProductIds: string[]
  isPendingToProductIds: boolean
  betweenSelfIsRefetching: boolean
  requestId: string
}
export interface ResponseRejected extends Refetch {
  status: ApiStatus.Rejected
  error: SystemError<'SYSTEM' | 'WARNING' | 'IS_EMPTY_PRODUCTS'>
}

export const productsByBetweenSelfSelector = createSelector(
  productsSelector,
  betweenSelfSelector,
  betweenSelfProductToListSelector,
  (products, betweenSelf, betweenSelfProductToList): Response => {
    /**
     * Хотя бы один запрос не был запрошен
     *
     * - betweenSelf является mutation запросом, при этом вызов refetch
     * может вызвать обновление status с ранее сохранненным данными к кеше.
     * - betweenSelfProductToList вызывается при выборе пользователем продукта списания
     */
    if (
      products.status === ApiStatus.Idle ||
      (betweenSelf.status === ApiStatus.Idle && !betweenSelf.data)
    ) {
      return {
        status: ApiStatus.Idle,
      }
    }

    /**
     * Хотя бы один запрос в ожидании получении ответа от сервера
     *
     * - betweenSelf является mutation запросом, при этом вызов refetch
     * может вызвать обновление status с ранее сохранненным данными к кеше.
     */
    if (
      products.status === ApiStatus.Pending ||
      (betweenSelf.status === ApiStatus.Pending && !betweenSelf.data)
    ) {
      return {
        status: ApiStatus.Pending,
      }
    }

    /**
     * Инициализация refetch для createSelector
     */
    const refetch = new RefetchCollection(products)

    /**
     * При наличии ошибки необходимо позволить пользователю повторно запросить данные
     */

    if (betweenSelf.status === ApiStatus.Rejected) {
      refetch.add(betweenSelf.refetchKey)
    }

    /**
     * Ошибка во всех запросах, отображаем ошибку
     */
    if (products.status === ApiStatus.Rejected || betweenSelf.status === ApiStatus.Rejected) {
      return {
        error: {
          message: 'При получении данных произошла ошибка',
          type: 'SYSTEM',
        },
        status: ApiStatus.Rejected,
        ...refetch,
      }
    }

    /**
     * Ошибка получения списка доступных для перевода/получения продуктов, отображаем ошибку
     */
    if (betweenSelf.data?.type !== ResponseType.ResolveWithData) {
      if (betweenSelf.data?.type === ResponseType.ResolveWithSystemError) {
        return {
          error: {
            message: betweenSelf.data.errorMessage,
            type: 'WARNING',
          },
          status: ApiStatus.Rejected,
          ...refetch,
        }
      }

      return {
        error: {
          message: 'При получении данных произошла ошибка',
          type: 'SYSTEM',
        },
        status: ApiStatus.Rejected,
        ...refetch,
      }
    }

    const betweenSelfResponse = betweenSelf.data

    /**
     * Мапка продуктов из betweenSelf доступных для списания
     */
    const productsFrom = _keyBy(betweenSelfResponse.productFrom, 'id')

    /**
     * Мапка продуктов из betweenSelf доступных для пополнения
     *
     * При наличии betweenSelfProductToList заменяет betweenSelf
     */
    const productsList =
      betweenSelfProductToList.data?.response?.type === ResponseType.ResolveWithData
        ? betweenSelfProductToList.data.response.productTo
        : betweenSelfResponse.productTo

    const productsTo = _keyBy(productsList, 'id')

    /**
     * Формируем новый массив только из тех продуктов, которые пришли в ответе betweenSelf
     */
    const newProductsIds = products.productIds.filter((productId) => {
      /**
       * У кредита из betweenSelf нет contractNumber, поэтому убираем последние 6 символов у кредита из GCP чтобы проверить id
       */
      if (productId.startsWith('cl')) return !!productsTo[productId.slice(0, -6)]

      return !!(productsTo[productId] || productsFrom[productId])
    })

    /**
     * Мапка продуктов с новой типизацией
     */
    const newProducts = newProductsIds.reduce<Record<string, Product>>((acc, curr) => {
      const betweenSelfProducts = productsTo[curr] || productsFrom[curr]

      acc[curr] = products.products[curr] as Product

      /**
       * Обновляем информацию по состоянию счета
       */

      acc[curr].availableBalance = betweenSelfProducts?.availableBalance
      return acc
    }, {})

    /**
     * Формирование доступных продуктов для списка списания
     */
    const fromProductIds = products.productIds.filter((productId) => {
      return !!productsFrom[productId]
    })

    /**
     * Нарушение бизнес логики, встречается только в моках
     * В агрегации между products и betweenSelf нет доступных продуктов для перевода
     */
    if (fromProductIds.length === 0) {
      return {
        error: {
          message: 'Нет доступных продуктов для перевода',
          type: 'IS_EMPTY_PRODUCTS',
        },
        status: ApiStatus.Rejected,
        ...refetch,
      }
    }

    /**
     * Формирование доступных продуктов для списка пополнения
     */
    const toProductIds = products.productIds.filter((productId) => {
      /**
       * У кредита из betweenSelf нет contractNumber, поэтому убираем последние 6 символов у кредита из GCP чтобы проверить id
       */
      if (productId.startsWith('cl')) return !!productsTo[productId.slice(0, -6)]

      return !!productsTo[productId]
    })

    return {
      error: products.error,
      products: newProducts,
      productIds: newProductsIds,
      status: ApiStatus.Fulfilled,
      fromProductIds,
      isPendingFromProductIds: betweenSelf.status === ApiStatus.Pending || betweenSelf.isRefetching,
      toProductIds,
      isPendingToProductIds:
        betweenSelf.status === ApiStatus.Pending ||
        betweenSelfProductToList.status === ApiStatus.Pending ||
        betweenSelf.isRefetching,
      requestId: betweenSelfResponse.requestId,
      betweenSelfIsRefetching: betweenSelf.isRefetching,
      ...refetch,
    }
  }
)
