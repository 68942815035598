import { HttpClientModel } from '@/core'
import { ResponseType } from '@/models/ResponseType'
import { ResolveWithSystemErrorResponse } from '@/models/ResolveWithSystemErrorResponse'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'
import { PaymentSystemType } from '@/models/PaymentSystemType'

/**
 * ClientCards
 * @see https://confluence.homecredit.ru/confluence/pages/viewpage.action?pageId=224555832
 */
export namespace ClientCardsModel {
  export type Func = (props: Props) => Promise<Response>
  export type Props = {
    apiVersion: string
    beneficiaryId: number
  }

  export type Response = HttpClientModel.Response<ResponseData>
  export type ErrorResponse = HttpClientModel.ErrorResponse<ErrorResponseData>

  export type ResolveWithDataResponse = {
    type: ResponseType.ResolveWithData
    products: {
      productName: string
      productType: string
      productSubType: string
      availableBalance: number
      productStyle: number
      maskedCardNumber: string
      paymentSystem: PaymentSystemType
      accountNumber: string
      currency: Currency
      cardMbr: number
      lastFourCardDigits: string
      bgColor: string
      bgColorTheme: 'light' | 'dark'
    }[]
  }

  export enum ProductStyle {
    NotSet = 0,
    PolzaGold = 2,
    Polza = 10,
    Kids = 18,
  }

  export enum ProductSubType {
    DebitCard = 'DC',
    CreditCardRDTW = 'RDTW',
    CreditCardRD = 'RD',
  }

  export enum ProductType {
    DebitCard = 'DebitCard',
    CreditCard = 'CreditCard',
  }
  export type Currency = 'RUB' | 'USD' | 'EUR'

  export type ResponseData = ResolveWithDataResponse | ResolveWithSystemErrorResponse
  export type ErrorResponseData = RejectedWithSystemErrorResponse
  export type RequestData = null
  export interface RequestHeaders {
    _ver_: string
    _os_: string
  }
}
