const PREFIX = '+'

export const TOP_UP_BUTTONS: {
  amount: number
  prefix: '+'
}[] = [
  {
    amount: 500,
    prefix: PREFIX,
  },
  {
    amount: 1000,
    prefix: PREFIX,
  },
  {
    amount: 5000,
    prefix: PREFIX,
  },
  {
    amount: 10000,
    prefix: PREFIX,
  },
]
