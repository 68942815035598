import { Button } from '@platform-ui/components/Button'
import styled from '@emotion/styled'
import { Body14 } from '@platform-ui/typography'

export const Root = styled.div(({ theme }) => ({
  textAlign: 'center',
  backgroundColor: theme.color['surface/tertiary'],
  borderRadius: '20px',
  padding: `20px 20px 8px`,
}))

export const Content = styled(Body14)(() => ({
  paddingBottom: 12,
}))

export const RetryButton = styled(Button)(() => ({
  padding: `16px 24px`,
}))
