import { HttpClientModel } from '@/core'
import { ResponseType } from '@/models/ResponseType'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'
import { CurrencyType } from '@/models/CurrencyType'

export namespace PhoneCommissionModel {
  export type Func = (props: Props) => Promise<Response>
  export interface Props {
    requestId: string
    transferType: string
    sum: number
    currencyType: CurrencyType
  }
  export type Response = HttpClientModel.Response<ResponseData>
  export type ErrorResponse = HttpClientModel.ErrorResponse<ErrorResponseData>
  export type ErrorResponseData = RejectedWithSystemErrorResponse
  export type ResponseData = ResolveWithDataResponse
  export type RequestHeaders = null
  export enum TransferType {
    // Внешний.
    External = 1,

    // Внутренний.
    Internal = 2,

    // Внутренний между своими счетами.
    InternalSelf = 3,

    // Внутренний между своими счетами с разными валютами.
    InternalSelfExchange = 4,

    // СБП перевод.
    Sbp = 5,

    // Прямые переводы в сбербанк.
    Sberbank = 6,
  }
  export interface Requestdata {
    currency: string
    sum: string
    transferType: string
  }
  export interface ResolveWithDataResponse {
    type: ResponseType.ResolveWithData
    fee: number
    sum: number
    fullSum: number
    currencyType: CurrencyType
  }
}
