import styled from '@emotion/styled'
import { FieldControl } from '@platform-ui/components/FieldControl'
import { StepperSum } from '@/components/StepperSum'

export const Root = styled(StepperSum)(({ theme }) => ({
  margin: '16px 0',

  [`${theme.media.md}`]: {
    margin: '16px 0',
  },
}))

export const FieldControlCustom = styled(FieldControl)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  marginBottom: '16px',
}))
