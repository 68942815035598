import { useProductsQuery } from '@/api/products/products'
import { useClientProductsQuery } from '@/api/products/clientProducts'
import { productsByBetweenSelfSelector } from '../selectors/productsByBetweenSelfSelector'
import { useInjectRefetch } from '@/utils/useInjectRefetch'
import { useBetweenSelfProductToListMutation } from '@/api/transfers/betweenSelfProductToList'
import { useBetweenSelfQuery } from '@/api/transfers/betweenSelf'

export function useProductsSelector(
  betweenSelf: ReturnType<typeof useBetweenSelfQuery>,
  betweenSelfProductToList: ReturnType<typeof useBetweenSelfProductToListMutation>,
  productsQuery: ReturnType<typeof useProductsQuery>,
  clientProductsQuery: ReturnType<typeof useClientProductsQuery>
) {
  return useInjectRefetch(productsByBetweenSelfSelector, {
    products: productsQuery,
    clientProducts: clientProductsQuery,
    betweenSelf,
    betweenSelfProductToList,
  })
}
