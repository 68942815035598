import { ClientProductsModel } from '@/api/products/clientProducts'
import { ProductsModel } from '@/api/products/products'
import { SelectProductsModel } from '@/components/SelectProducts'
import { pluralize } from '@/utils'
import { Product } from '../selectors/productsByBetweenSelfSelector'

export const createSelectProductsItems = (
  productIds: string[],
  products: Record<string, Product>,
  ignoreIds: string[] = []
): SelectProductsModel.Item[] => {
  const newProductIds =
    ignoreIds.length > 0
      ? productIds.filter((id) => !ignoreIds.some((ignoreId) => ignoreId === id))
      : productIds

  return [
    ...newProductIds
      .reduce<Map<'cards' | 'accounts' | 'credits' | 'deposit', SelectProductsModel.Category>>(
        (acc, productId) => {
          const item = products[productId]

          if (
            item.productType === ProductsModel.ProductType.DebitCard ||
            item.productType === ProductsModel.ProductType.CreditCard
          ) {
            acc.get('cards').items.push({
              objectType: SelectProductsModel.ObjectType.Card,
              amount: item.availableBalance,
              bgColor: item.bgColor,
              lastFourCardDigits: item.lastFourCardDigits,
              currencyType: item.currencyType,
              description: item.description,
              id: item.id,
              bgColorTheme: item.bgColorTheme,
              paymentSystem: item.paymentSystem,
              title: item.productName,
            })
            return acc
          }
          if (item.productType === ProductsModel.ProductType.Account) {
            acc.get('accounts').items.push({
              objectType: SelectProductsModel.ObjectType.Account,
              amount: item.availableBalance,
              currencyType: item.currencyType,
              description: item.description,
              id: item.id,
              title: item.accountName,
            })
            return acc
          }
          if (item.categoryType === ClientProductsModel.CategoryType.CreditLoan) {
            const creditLoans = newProductIds
              .map((id) => products[id])
              .filter((i) => i.categoryType === ClientProductsModel.CategoryType.CreditLoan)
            const creditLoansAccNums = creditLoans.map((i) => i.accountNumber)
            const creditLoansAccNumsDuplicates = creditLoansAccNums.filter(
              (item, idx) => creditLoansAccNums.indexOf(item) !== idx
            )
            const isMultipleCreditAccount = creditLoansAccNumsDuplicates.includes(
              item.accountNumber
            )

            const creditLoanList = acc.get('credits').items as SelectProductsModel.CreditLoan[]
            const idx = creditLoanList.findIndex((i) => i.accountNumber === item.accountNumber)
            if (idx > -1) {
              return acc
            }

            if (isMultipleCreditAccount) {
              const creditLoansDuplicates = creditLoans.filter(
                (element) => element.accountNumber === item.accountNumber
              )
              const creditsProductNames = creditLoansDuplicates.map((i) => i.productName).join('; ')
              const creditsCount = creditLoansDuplicates.length

              creditLoanList.push({
                objectType: SelectProductsModel.ObjectType.CreditLoan,
                amount: item.accountBalance,
                currencyType: item.currencyType,
                productName: item.productName,
                id: item.id,
                accountNumber: item.accountNumber,
                title: 'Единый кредитный счёт',
                description: `${creditsCount} ${pluralize(creditsCount, [
                  'кредит',
                  'кредита',
                  'кредитов',
                ])}`,
                details: isMultipleCreditAccount && creditsProductNames,
              })
            } else {
              creditLoanList.push({
                objectType: SelectProductsModel.ObjectType.CreditLoan,
                amount: item.accountBalance,
                currencyType: item.currencyType,
                productName: item.productName,
                id: item.id,
                accountNumber: item.accountNumber,
                title: 'Кредитный счёт',
                description: item.productName,
              })
            }

            return acc
          }
          if (item.productType === ProductsModel.ProductType.Deposit) {
            acc.get('deposit').items.push({
              objectType: SelectProductsModel.ObjectType.Deposit,
              amount: item.availableBalance,
              currencyType: item.currencyType,
              description: item.description,
              id: item.id,
              title: item.depositName,
            })
            return acc
          }
        },
        new Map([
          [
            'cards',
            {
              objectType: SelectProductsModel.ObjectType.Category,
              title: 'Карты',
              items: [],
            },
          ],
          [
            'accounts',
            {
              objectType: SelectProductsModel.ObjectType.Category,
              title: 'Счета',
              items: [],
            },
          ],
          [
            'credits',
            {
              objectType: SelectProductsModel.ObjectType.Category,
              title: 'Кредиты',
              items: [],
            },
          ],
          [
            'deposit',
            {
              objectType: SelectProductsModel.ObjectType.Category,
              title: 'Вклады',
              items: [],
            },
          ],
        ])
      )
      .values(),
  ].filter((item) => item.items.length > 0)
}
