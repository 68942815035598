import { useBetweenSelfQuery } from '@/api/transfers/betweenSelf'
import { statusToApiStatusEnum } from '@/utils/statusToApiStatusEnum'
import { createSelector } from 'reselect'

interface BetweenSelfSelectorState {
  betweenSelf: ReturnType<typeof useBetweenSelfQuery>
}
export const betweenSelfSelector = createSelector(
  (state: BetweenSelfSelectorState) => state.betweenSelf.data,
  (state: BetweenSelfSelectorState) => state.betweenSelf.status,
  (state: BetweenSelfSelectorState) => state.betweenSelf.isRefetching,
  (data, status, isRefetching) => ({
    data: data,
    status: statusToApiStatusEnum(status),
    isRefetching,
    refetchKey: 'betweenSelf',
  })
)
