import React, { forwardRef, useRef, useState } from 'react'
import { PaymentRecipient } from '../../components/PaymentRecipient'
import { useForkRef, useResizeObserver } from '@platform-ui/utils'
import { useController, UseControllerProps, useFormContext } from 'react-hook-form'
import {
  FormValues,
  useFormState,
} from '@/features/transfers-by-phone-number/containers/TransfersByPhoneNumberForm/useFormState'
import { ApiStatus } from '@/core'
import { TransferProviderItem } from '../../components/TransferProviderItem'
import { CSSObject } from '@emotion/react'
import { SearchIcon } from '@platform-ui/icons/SearchIcon'
import {
  FieldControlCustom,
  InputWrapper,
  InputStyled,
  InputSearchLeftSlot,
  PopoverStyled,
  TransferProviderWrapper,
  PaymentRecipientWrapper,
} from './styled'
import { useProvidersSelector } from '@/features/transfers-by-phone-number/hooks/useProvidersSelector'
import { useProductsSelector } from '@/features/transfers-by-phone-number/hooks/useProductsSelector'

export const useProvidersData = (props: {
  providersSelector: ReturnType<typeof useFormState>['providersSelector']
}) => {
  const { providersSelector } = props

  if (providersSelector.status === ApiStatus.Idle) {
    return {
      status: ApiStatus.Idle,
    }
  }
  if (providersSelector.status === ApiStatus.Pending) {
    return {
      status: ApiStatus.Pending,
    }
  }
  if (providersSelector.status === ApiStatus.Rejected) {
    return {
      status: ApiStatus.Rejected,
      error: {
        message: providersSelector.error.message,
        type: providersSelector.error.type,
      },
    }
  }

  const sbpArr = providersSelector.screenSelectableProviderList.filter((i) => i.type === 'sbp')
  const providerArr = providersSelector.screenSelectableProviderList.filter(
    (i) => i.type === 'provider'
  )
  const [sbpFee] = sbpArr.flatMap((i) => i.fee)

  return {
    status: ApiStatus.Fulfilled,
    selectableProviderList: providersSelector.screenSelectableProviderList,
    actionProviderList: providersSelector.screenActionProviderList,
    providerArr,
    sbpBanks: sbpArr.flatMap((i) => i.banks),
    sbpFee,
  }
}

const usePaymentRecipientData = (props: {
  providersSelector: ReturnType<typeof useFormState>['providersSelector']
  providerId: string
  bankId: string
}) => {
  const { providerId, providersSelector, bankId } = props
  if (providersSelector.status !== ApiStatus.Fulfilled || !providerId) {
    return {
      bankName: '',
    }
  }

  const provider = providersSelector.providers[providerId]
  const selectedBank = provider.banks.find((item) => item.id === bankId)

  return {
    bankName: selectedBank ? selectedBank.name : provider.bankName,
  }
}

export type ProviderFieldProps = UseControllerProps<FormValues, 'providerId'> & {
  providersSelector: ReturnType<typeof useProvidersSelector>
  productsSelector: ReturnType<typeof useProductsSelector>
  isPrepare: boolean
  phoneGetSbpLogoMutation: ReturnType<typeof useFormState>['phoneGetSbpLogoMutation']
  recipientName: string
}

/**
 * Поле выбора банка
 */
export const ProviderField = forwardRef<HTMLDivElement, ProviderFieldProps>((props, ref) => {
  const {
    providersSelector,
    productsSelector,
    isPrepare,
    phoneGetSbpLogoMutation,
    recipientName,
    ...otherProps
  } = props

  const elemRef = useRef<HTMLDivElement>()
  const rootRef = useForkRef(elemRef, ref)

  const [isOpenList, setIsOpenList] = useState(null)
  const [searchValue, setSearchValue] = useState('')

  const { setValue, getValues } = useFormContext<FormValues>()
  const { field } = useController(otherProps)

  let [bankId, providerId, sum] = getValues(['bankId', 'providerId', 'sum'])

  const [width, setWidth] = useState<CSSObject['width']>('100%')
  useResizeObserver(elemRef, (state) => {
    /**
     * Дизайн выпадающего списка имеет чуть большую ширину, чем у кнопки
     */
    setWidth(`${state.width}px`)
  })

  const handleClosePopover = () => setIsOpenList(false)

  const handleSelect = (isSbpAccountExist, bankId, providerId = 'sbp') => {
    /**
     * При смене банка отчистить введённую ранее сумму, bankId и ошибку
     */
    if (sum) setValue('sum', '')
    if (!bankId) {
      setValue('bankId', '')
    }

    if (bankId) {
      setValue('bankId', bankId)
    }

    setValue('providerId', providerId)

    handleClosePopover()
  }

  const paymentRecipientData = usePaymentRecipientData({
    providerId: field.value,
    providersSelector,
    bankId,
  })

  const providers = useProvidersData({ providersSelector })

  if (productsSelector.status !== ApiStatus.Fulfilled) {
    return null
  }

  const isDisabled =
    providers.status !== ApiStatus.Fulfilled && providers.status !== ApiStatus.Pending
  const isLoading = providers.status === ApiStatus.Pending

  const providerBank = providerId && providers.providerArr.find((i) => i.id === providerId)

  return (
    <>
      <FieldControlCustom isBlock>
        <PaymentRecipientWrapper ref={rootRef}>
          <PaymentRecipient
            name={field.name}
            providerBankIcon={providerBank && providerBank.bankIcon}
            bankId={bankId}
            providerId={providerId}
            isOpen={!!isOpenList}
            isPrepare={isPrepare}
            isDisabled={isDisabled}
            isLoading={isLoading}
            status="default"
            bankName={paymentRecipientData.bankName}
            phoneGetSbpLogoMutation={phoneGetSbpLogoMutation}
            recipientName={recipientName}
            onClick={(event) => {
              if (!isLoading && !isPrepare) {
                setIsOpenList(isOpenList ? null : event.currentTarget)
              }
            }}
          />
        </PaymentRecipientWrapper>
      </FieldControlCustom>

      <PopoverStyled
        isOpen={!!isOpenList}
        anchorEl={elemRef}
        css={{ width: width, height: 200 }}
        placement={'bottom'}
        onClose={handleClosePopover}
        isArrow={false}
      >
        <InputWrapper>
          <InputStyled
            name="search"
            type="text"
            placeholder="Название банка"
            value={searchValue}
            onChange={(event) => setSearchValue(event.target.value)}
          />
          <InputSearchLeftSlot>
            <SearchIcon size="s12" />
          </InputSearchLeftSlot>
        </InputWrapper>

        {providers.status === ApiStatus.Fulfilled && (
          <TransferProviderWrapper>
            {providers.providerArr
              .filter((item) => item.bankName.startsWith(searchValue))
              .map(({ id, bankName, bankIcon, fee }, i) => {
                return (
                  <TransferProviderItem
                    key={i}
                    providerBankIcon={bankIcon}
                    isSelected={field.value === id}
                    fee={fee}
                    bankId=""
                    phoneGetSbpLogoMutation={phoneGetSbpLogoMutation}
                    bankName={bankName}
                    onClick={() => handleSelect(null, '', id)}
                  />
                )
              })}
            {providers.sbpBanks
              .filter((item) => item.name.startsWith(searchValue))
              .map(({ id, isSbpAccountExist, name }, i) => {
                return (
                  <TransferProviderItem
                    key={i}
                    isSelected={bankId === id}
                    bankId={id}
                    isSbp
                    fee={providers.sbpFee}
                    isSbpAccountExist={isSbpAccountExist}
                    bankName={name}
                    phoneGetSbpLogoMutation={phoneGetSbpLogoMutation}
                    onClick={() => handleSelect(isSbpAccountExist, id)}
                  />
                )
              })}
          </TransferProviderWrapper>
        )}
      </PopoverStyled>
    </>
  )
})
