import { Response } from '../selectors/productsSelector'
import { selectProductFromItemsSelector } from '../selectors/selectProductFromItemsSelector'

export function useSelectProductFromItemsSelector(
  productsSelector: Response,
  ignoreProductId?: string
) {
  return selectProductFromItemsSelector({
    ignoreProductId,
    productsSelector,
  })
}
