import React, { ChangeEventHandler, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useController, UseControllerProps, useFormContext, useWatch } from 'react-hook-form'
import { messageRulesReplace } from '../../utils/messageRulesReplace'
import { FormValues } from '../TransfersByPhoneNumberForm/useFormState'
import { FieldControlCustom, InputStyled } from './styled'
import { SuccessIcon } from '@platform-ui/icons/SuccessIcon'

/**
 * Поле ввода сообщения получателю
 */
export const MessageField = observer<
  UseControllerProps<FormValues, 'message'> & {
    isPrepare: boolean
  }
>((props) => {
  const { isPrepare } = props
  const { setValue } = useFormContext<FormValues>()
  const { field, fieldState } = useController(props)
  const sum = useWatch({ name: 'sum', control: props.control })

  const placeholder = `Сообщение получателю`

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    event.currentTarget.value = messageRulesReplace(event.currentTarget.value)
    field.onChange(event)
  }

  useEffect(() => {
    if (!sum) {
      setValue(field.name, '')
    }
  }, [field.name, setValue, sum])

  return (
    <FieldControlCustom isBlock error={fieldState.error?.message}>
      <InputStyled
        value={field.value}
        isDisabled={!sum}
        name={field.name}
        rightSlot={isPrepare && <SuccessIcon color="icons/colors/success" />}
        isBlock
        type="text"
        placeholder={placeholder}
        status={fieldState.error?.message ? 'error' : 'default'}
        autoComplete="off"
        onChange={handleChange}
        onClear={() => {
          setValue(field.name, '')
        }}
        isReadOnly={isPrepare}
      />
    </FieldControlCustom>
  )
})
