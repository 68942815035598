import styled from '@emotion/styled'
import { CSSObject } from '@emotion/react'
import React from 'react'
import { Body14 } from '@platform-ui/typography'
import { CommissionFieldProps } from '.'
import { useKeepExistingData } from '@platform-ui/utils'

export const Root = styled.div<{
  hasEmitent: boolean
}>(({ hasEmitent }) => {
  const css: CSSObject = hasEmitent
    ? {
        padding: '0 8px 0px 8px',
        marginLeft: '-8px',
        marginRight: '-8px',
        borderRadius: '16px',
      }
    : {}

  return {
    position: 'relative',
    borderRadius: '8px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    padding: 0,
    marginLeft: 0,
    marginRight: 0,
    transition: '200ms padding, 200ms margin, 200ms background-color, 200ms border-radius',
    ...css,
  }
})

export const LoaderBlock = styled.div(() => {
  return {
    padding: '16px 16px 16px',
    zIndex: 1,
  }
})

const RootInnerBlock = styled.div(() => {
  return {
    padding: '8px 16px',
    zIndex: 1,
  }
})

const Title = styled.div(() => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
  }
})

const Additional = styled(Body14)(() => {
  return {
    marginTop: '8px',
  }
})

const Term = styled.span(({ theme }) => {
  return {
    color: theme.color['text/secondary'],
  }
})

export const InnerBlock = (props: {
  comission: CommissionFieldProps['commission']
  isLoading: CommissionFieldProps['isLoadingCommission']
}) => {
  const comissionData = useKeepExistingData(props.comission)

  return (
    <RootInnerBlock>
      <Title>
        {comissionData.title && <Body14>{comissionData.title}</Body14>}
        {props.isLoading && <Body14>Комиссия рассчитывается</Body14>}
      </Title>
      {comissionData.description && (
        <Additional>
          <Term>{comissionData.description}</Term>
        </Additional>
      )}
    </RootInnerBlock>
  )
}
