import React, { useEffect, useMemo } from 'react'
import styled from '@emotion/styled'
import { Button } from '@platform-ui/components/Button'
import { ApiStatus } from '@/core'
import { IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'

const ButtonReSendSms = styled(Button)(({ theme }) => ({
  marginTop: '24px',

  [`${theme.media.md}`]: {
    marginTop: '32px',
  },
}))

const Message = styled.div(({ theme }) => ({
  background: theme.color['surface/tertiary'],
  borderRadius: '12px',
  width: '100%',
  padding: '16px',
  textAlign: 'center',
}))

class SmsCodeStore {
  private timer: NodeJS.Timeout
  private _timeWaitSec = 0
  private readonly _reaction: IReactionDisposer
  attempt = 0
  timeWaitSec = 0

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })

    this._reaction = reaction(
      () => this.attempt,
      (attempt) => {
        if (attempt > 0) {
          this.startTimerDecrement()
        }
      }
    )
  }

  init(props: { attempt: number; timeWaitSec: number }) {
    this.attempt = props.attempt
    this._timeWaitSec = props.timeWaitSec

    this.startTimerDecrement()
  }

  destroy() {
    this.stopTimerDecrement()
    this._reaction && this._reaction()
  }

  startTimerDecrement() {
    this.timeWaitSec = this._timeWaitSec
    this.stopTimerDecrement()
    this.timer = setInterval(() => {
      runInAction(() => {
        if (this.timeWaitSec === 1) {
          this.stopTimerDecrement()
        }
        this.timeWaitSec--
      })
    }, 1000)
  }

  stopTimerDecrement() {
    clearInterval(this.timer)
  }

  decrementAttempt() {
    this.attempt = this.attempt - 1
  }

  /**
   * @return 00:56 | 01:20:00
   */
  get timeWaitString() {
    const pad = (n: number) => (n < 10 ? `0${n}` : n)
    let minutes = Math.floor(this.timeWaitSec / 60)
    const secs = this.timeWaitSec % 60
    const hours = Math.floor(minutes / 60)
    minutes = minutes % 60
    const lastFragment = `${pad(minutes)}:${pad(secs)}`
    return hours !== 0 ? `${pad(hours)}:${lastFragment}` : lastFragment
  }

  get timerWaitIsOver() {
    return this.timeWaitSec <= 0
  }

  get isOverAttempt() {
    return this.attempt <= 0
  }
}

export interface ReSendCodeProps {
  className?: string
  timeWaitSec: number
  attempt: number
  reSendCodeStatus: ApiStatus
  onReSendCode: () => void
}
export const ReSendCode = observer<ReSendCodeProps>((props) => {
  const { attempt, timeWaitSec, onReSendCode, reSendCodeStatus, ...otherProps } = props
  const smsCodeStore = useMemo(() => new SmsCodeStore(), [])

  useEffect(() => {
    smsCodeStore.init({
      attempt: attempt,
      timeWaitSec: timeWaitSec,
    })
  }, [attempt, timeWaitSec, smsCodeStore])

  useEffect(() => {
    return () => {
      smsCodeStore.destroy()
    }
  }, [smsCodeStore])

  const handleOnClick = () => {
    smsCodeStore.decrementAttempt()
    onReSendCode()
  }

  if (smsCodeStore.isOverAttempt) {
    // TODO: Подумать на текстом ошибки при отсутствии кол-ва попыток
    return (
      <Message {...otherProps}>
        Попытки отправки кода закончились. Попробуйте вернуться в начало и повторить запрос кода.
      </Message>
    )
  }

  return (
    <ButtonReSendSms
      {...otherProps}
      isFullWidth
      isDisabled={!smsCodeStore.timerWaitIsOver}
      isLoading={reSendCodeStatus === ApiStatus.Pending}
      view="secondary"
      onClick={handleOnClick}
    >
      {smsCodeStore.timerWaitIsOver
        ? 'Запросить код повторно'
        : `Повторно запросить код через ${smsCodeStore.timeWaitString}`}
    </ButtonReSendSms>
  )
})
