import styled from '@emotion/styled'
import { Input } from '@platform-ui/components/Input'

export const BeneficiaryFieldWrapper = styled.div(() => ({
  position: 'relative',
}))

export const BankLogo = styled.img(() => ({
  position: 'absolute',
  zIndex: 2,
  left: 16,
  bottom: 12,
  borderRadius: '50%',
}))

export const InputStyled = styled(Input)<{
  isOpen: boolean
  isDisabled: boolean
  isPrepare: boolean
}>(({ isOpen, isDisabled, isPrepare, theme }) => ({
  input: {
    background: isDisabled && theme.color['surface/tertiary'],
    color: isDisabled && theme.color['text/secondary'],
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    borderRadius: isOpen && '12px 12px 0 0',
    paddingLeft: isPrepare ? 66 : 49,

    ':not(:disabled)': {
      ':focus, :active': {
        paddingLeft: 48,
        paddingRight: 47,
      },
    },
  },
  svg: {
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    transition: 'transform 200ms linear',
    transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
  },
  span: {
    paddingLeft: isPrepare && 52,
  },
}))
