import React, { useEffect } from 'react'
import { ApiStatus, useAppContext } from '@/core'
import { FormProvider, useController, UseControllerProps, useFormContext } from 'react-hook-form'
import {
  FormContainer,
  ErrorContainer,
  LoadingButtonShimmer,
  LoadingProductsShimmer,
  LoadingTitleShimmer,
  SelectProductsShimmer,
} from './styled'
import { GroupFieldTitle } from '../../components/GroupFieldTitle'
import { observer } from 'mobx-react-lite'
import { SelectProducts } from '@/components/SelectProducts'
import { FormValues, useFormState } from './useFormState'
import { AppContext } from '@/models/AppContext'
import { FieldControl } from '@platform-ui/components/FieldControl'
import { PhoneNumberField } from '../PhoneNumberField'
import { SumField } from '../SumField'
import { MessageField } from '../MessageField'
import { ButtonSum } from '../ButtonSum'
import { ProviderField } from '../ProviderField'
import { ResponseType } from '@/models/ResponseType'
import { PhoneTransferModel } from '@/api/transfers/phoneTransfer'
import { SMSCode } from '@/features/transfers-by-phone-number/containers/SMSCode'
import { ErrorDataLoadingModal } from '@/components/ErrorDataLoadingModal'
import { PhoneCheckModel } from '@/api/transfers/phoneCheck'
import { statusToApiStatusEnum } from '@/utils/statusToApiStatusEnum'
import { useErrorReFetch } from './useErrorReFetch'

/**
 * Выпадающий список продуктов доступных для списания
 */
const SelectFromProductsField = observer<
  UseControllerProps<FormValues, 'fromProductId'> & {
    productsSelector: ReturnType<typeof useFormState>['productsSelector']
    phoneTransferMutation: ReturnType<typeof useFormState>['phoneTransferMutation']
    phoneCheckMutation: ReturnType<typeof useFormState>['phoneCheckMutation']
    isPrepare: boolean
  }
>((props) => {
  const { productsSelector, phoneTransferMutation, phoneCheckMutation, isPrepare, ...otherProps } =
    props
  const { config } = useAppContext<AppContext>()
  const isMobile = config.device.isMobile
  const { setValue } = useFormContext()
  const { field, fieldState } = useController(otherProps)

  const isDisabled = phoneTransferMutation.isLoading || phoneCheckMutation.isLoading || isPrepare

  return productsSelector.status === ApiStatus.Fulfilled && field.value ? (
    <FieldControl isBlock error={fieldState.error?.message}>
      <SelectProducts
        onSelect={(selectProduct) => {
          if (selectProduct.id === field.value) {
            return
          }
          setValue(field.name, selectProduct.id, { shouldValidate: true })
          setValue('phoneNumber', '')
          setValue('providerId', '')
          setValue('bankId', '')
          setValue('message', '')
        }}
        selectedId={field.value}
        items={productsSelector.fromSelectProductsItems}
        isMobile={isMobile}
        idDisabled={isDisabled}
        componentType={isPrepare ? 'card' : 'button'}
      />
    </FieldControl>
  ) : productsSelector.status === ApiStatus.Pending ||
    productsSelector.status === ApiStatus.Idle ? (
    <SelectProductsShimmer />
  ) : (
    <div>Нет доступных для списания продуктов</div>
  )
})

export interface TransfersByPhoneNumberFormProps {
  /**
   * Идентификатор продукта списания,
   * который требуется сделать выбранным по умолчанию
   */
  fromProductId?: string
  isPrepareState: boolean
  setIsPrepareState: (boolean) => void
}
export const TransfersByPhoneNumberForm = observer((props: TransfersByPhoneNumberFormProps) => {
  const { fromProductId, isPrepareState, setIsPrepareState } = props
  const {
    form,
    handleInvalidSubmit,
    handleValidSubmit,
    handlePhoneNumberComplete,
    providersSelector,
    phoneCommissionMutation,
    phoneTransferMutation,
    phoneCheckMutation,
    phoneReSendMutation,
    productsSelector,
    businessError,
    handlePhoneCheckEvent,
    handleReSendCodeEvent,
    phoneGetSbpLogoMutation,
    phonePrepareMutation,
    phoneMutation,
    handlePhoneMutateEvent,
    handlePhoneCommissionEvent,
  } = useFormState({ isPrepareState, setIsPrepareState, initFromProductId: fromProductId })

  const isLoading = productsSelector.status !== ApiStatus.Fulfilled
  const isPrepare = isPrepareState

  useEffect(() => {
    return () => {
      setIsPrepareState(false)
    }
  }, [setIsPrepareState])

  useEffect(() => {
    if (phoneTransferMutation.isSuccess && !isPrepareState) {
      phoneTransferMutation.reset()
    }
  }, [phoneTransferMutation.isSuccess, isPrepareState])

  const { isError, reFetch } = useErrorReFetch(
    productsSelector,
    phoneMutation,
    handlePhoneMutateEvent,
    phoneCommissionMutation,
    handlePhoneCommissionEvent,
    phonePrepareMutation,
    form.handleSubmit(handleValidSubmit, handleInvalidSubmit),
    phoneTransferMutation
  )

  if (isError) {
    const description =
      phonePrepareMutation.isError || phoneTransferMutation.isError
        ? 'Не удалось совершить перевод'
        : 'Не удалось загрузить данные'
    return (
      <ErrorContainer>
        <ErrorDataLoadingModal
          description={description}
          buttonText="Попробовать ещё раз"
          buttonClick={reFetch}
        />
      </ErrorContainer>
    )
  }

  const { getValues } = form
  let [providerId] = getValues(['providerId'])
  const provider =
    providersSelector.status === ApiStatus.Fulfilled && providersSelector.providers[providerId]

  if (
    phoneTransferMutation.isSuccess &&
    phoneTransferMutation.data.response.type === ResponseType.ResolveWithData &&
    phoneTransferMutation.data.response.result.resultCode === PhoneTransferModel.ResultCode.Success
  ) {
    const sendErrorMessage =
      phoneCheckMutation.isSuccess &&
      phoneCheckMutation.data.response.type === ResponseType.ResolveWithData &&
      phoneCheckMutation.data.response.result.resultCode !== PhoneCheckModel.ResultCode.Match &&
      phoneCheckMutation.data.response.result.resultText

    return (
      <SMSCode
        maskedPhoneNumber={phoneTransferMutation.data.response.result.phoneNumber}
        sendCodeStatus={statusToApiStatusEnum(phoneCheckMutation.status)}
        reSendCodeStatus={statusToApiStatusEnum(phoneReSendMutation.status)}
        onSuccess={handlePhoneCheckEvent}
        onReSendCode={handleReSendCodeEvent}
        sendCodeErrorMessage={sendErrorMessage}
      />
    )
  }

  return (
    <FormProvider {...form}>
      <FormContainer as="form" onSubmit={form.handleSubmit(handleValidSubmit, handleInvalidSubmit)}>
        {isLoading ? (
          <LoadingTitleShimmer isFrom />
        ) : (
          <GroupFieldTitle isFrom>Откуда</GroupFieldTitle>
        )}
        <SelectFromProductsField
          name="fromProductId"
          control={form.control}
          phoneCheckMutation={phoneCheckMutation}
          phoneTransferMutation={phoneTransferMutation}
          productsSelector={productsSelector}
          isPrepare={isPrepare}
        />
        {isLoading ? <LoadingTitleShimmer /> : <GroupFieldTitle>Куда</GroupFieldTitle>}
        <PhoneNumberField
          name="phoneNumber"
          control={form.control}
          handlePhoneNumberComplete={handlePhoneNumberComplete}
          productsSelector={productsSelector}
          isPrepare={isPrepare}
        />
        <ProviderField
          name="providerId"
          control={form.control}
          providersSelector={providersSelector}
          productsSelector={productsSelector}
          isPrepare={isPrepare}
          phoneGetSbpLogoMutation={phoneGetSbpLogoMutation}
          recipientName={
            isPrepare &&
            (provider?.recipientName ||
              phonePrepareMutation.data?.response?.provider?.recipientName)
          }
        />
        <SumField
          name="sum"
          control={form.control}
          providersSelector={providersSelector}
          phoneCommissionMutation={phoneCommissionMutation}
          productsSelector={productsSelector}
          isPrepare={isPrepare}
        />

        {productsSelector.status === ApiStatus.Fulfilled && (
          <MessageField name="message" control={form.control} isPrepare={isPrepare} />
        )}
        <ButtonSum
          control={form.control}
          productsSelector={productsSelector}
          phoneCommissionMutation={phoneCommissionMutation}
          phoneTransferMutation={phoneTransferMutation}
          phoneCheckMutation={phoneCheckMutation}
          phonePrepareMutation={phonePrepareMutation}
          isPrepare={isPrepare}
          isDisabled={!!businessError || !!Object.keys(form.formState.errors).length}
        />
        {isLoading && (
          <>
            <LoadingProductsShimmer />
            <LoadingProductsShimmer />
            <LoadingProductsShimmer />

            <LoadingButtonShimmer />
          </>
        )}
      </FormContainer>
    </FormProvider>
  )
})
