import { Response } from '../selectors/productsByBetweenSelfSelector'
import { selectProductToItemsSelector } from '../selectors/selectProductToItemsSelector'
export function useSelectProductToItemsSelector(
  productsByBetweenSelfSelector: Response,
  ignoreProductId?: string
) {
  return selectProductToItemsSelector({
    ignoreProductId,
    productsByBetweenSelfSelector,
  })
}
