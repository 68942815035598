import { MutateOptions, useMutation } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { PhoneCheckModel } from './PhoneCheckModel'
import { adapter } from './adapters/adapter'
import { stringTemplate } from '@/utils/string-template'

export const phoneCheck: PhoneCheckModel.Func = async (props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.transfers.phoneCheck,
    {} as ApiManagerModel.ApiOption
  )
  const response = await HttpClient.post<
    PhoneCheckModel.ResponseData,
    void,
    PhoneCheckModel.Requestdata,
    PhoneCheckModel.RequestHeaders,
    PhoneCheckModel.ErrorResponse
  >({
    url: stringTemplate<{ requestId: string }>(path, { requestId: props.requestId }),
    headers: null,
    responseAdapter: adapter,
    isValidStatus(status) {
      /**
       * 403 ответ со status blocked
       */
      return (status >= 200 && status < 300) || status === 403
    },
    data: {
      otp: props.otp,
    },
    control,
  })

  return response
}

export function usePhoneCheckMutation(
  options?: MutateOptions<
    PhoneCheckModel.Response,
    PhoneCheckModel.ErrorResponse,
    PhoneCheckModel.Props
  >
) {
  return useMutation(phoneCheck, options)
}
