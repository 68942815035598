import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { FormProvider } from 'react-hook-form'
import { ResponseType } from '@/models/ResponseType'
import { statusToApiStatusEnum } from '@/utils'
import { useFormState } from './useFormState'
import { GroupFieldTitle } from '../../components/GroupFieldTitle'
import { SelectFromProductsField } from '../SelectFromProductsField'
import { BeneficiaryField } from '../BeneficiaryField'
import { BeneficiaryDetails } from '../BeneficiaryDetails'
import { ButtonSum } from '../ButtonSum'
import { SMSCode } from '../SMSCode'
import { FormContainer } from './styled'
import DefaultIcon from '@/assets/default-icon.svg'
import { ApiStatus } from '@/core'
import { useBeneficiariesQuery } from '@/api/pms/beneficiaries'

export type PaymentsFormProps = Readonly<{
  beneficiariesQuery: ReturnType<typeof useBeneficiariesQuery>
  setTitle: (categoryName: string) => void
  setBeneficiaryId: (beneficiaryId?: number) => void
  beneficiaryId: number
  categoryId?: number
  beneficiaryName?: string
  beneficiaryIcon?: string
}>

const useSelectedBeneficiary = (beneficiaries, beneficiaryId, beneficiaryName, beneficiaryIcon) => {
  /**
   * При открытии модального окна в поиске передаём имя и иконку пропсами.
   * При открытии через категорию и при смене бенефициара берём из ответа beneficiariesQuery
   */
  if (
    beneficiaryId &&
    beneficiaries.isSuccess &&
    beneficiaries.data.type === ResponseType.ResolveWithData
  ) {
    const selectedBeneficiary = beneficiaries.data.result.find(
      (i) => i.beneficiaryId === beneficiaryId
    )

    return {
      name: !!selectedBeneficiary ? selectedBeneficiary.beneficiaryName : '',
      icon: !!selectedBeneficiary ? selectedBeneficiary.beneficiaryIcon : DefaultIcon,
    }
  }

  return {
    name: beneficiaryName,
    icon: beneficiaryIcon,
  }
}

export const PaymentsForm = observer((props: PaymentsFormProps) => {
  const {
    beneficiariesQuery,
    setTitle,
    setBeneficiaryId,
    beneficiaryId,
    categoryId,
    beneficiaryName,
    beneficiaryIcon,
  } = props

  useEffect(() => {
    return () => {
      setBeneficiaryId(null)
    }
  }, [setBeneficiaryId])

  const selectedBeneficiary = useSelectedBeneficiary(
    beneficiariesQuery,
    beneficiaryId,
    beneficiaryName,
    beneficiaryIcon
  )

  const {
    form,
    handleValidSubmit,
    handleInvalidSubmit,
    productsSelector,
    beneficiaryDetails,
    handleBeneficiaryDetailsEvent,
    checkPayment,
    createPayment,
    handlePhoneCheckEvent,
    verification,
    handleReSendCodeEvent,
    resending,
  } = useFormState(beneficiaryId, selectedBeneficiary)

  useEffect(() => {
    /**
     * Добавляем beneficiaryId в FormValues
     */
    if (beneficiaryId) {
      form.setValue('beneficiaryId', beneficiaryId)
    }
  }, [beneficiaryId])

  const isPrepare = checkPayment.data?.response?.type === ResponseType.ResolveWithData

  const isDisabled =
    productsSelector.status !== ApiStatus.Fulfilled ||
    checkPayment.isLoading ||
    createPayment.isLoading

  if (createPayment.isSuccess) {
    const sendErrorMessage =
      verification.isSuccess &&
      verification.data.response.type === ResponseType.ResolveWithData &&
      !verification.data.response.result.isOtpValid &&
      verification.data.response.result.text

    return (
      <SMSCode
        maskedPhoneNumber={createPayment.data.data.phone}
        sendCodeStatus={statusToApiStatusEnum(verification.status)}
        reSendCodeStatus={statusToApiStatusEnum(resending.status)}
        onSuccess={handlePhoneCheckEvent}
        onReSendCode={handleReSendCodeEvent}
        sendCodeErrorMessage={sendErrorMessage}
      />
    )
  }

  return (
    <FormProvider {...form}>
      <FormContainer as="form" onSubmit={form.handleSubmit(handleValidSubmit, handleInvalidSubmit)}>
        <GroupFieldTitle>Откуда</GroupFieldTitle>
        <SelectFromProductsField
          productsSelector={productsSelector}
          name="fromProductId"
          control={form.control}
          isPrepare={isPrepare}
          isLoading={checkPayment.isLoading || createPayment.isLoading}
        />

        <GroupFieldTitle>Куда</GroupFieldTitle>
        <BeneficiaryField
          productsSelector={productsSelector}
          name="beneficiaryId"
          setTitle={setTitle}
          beneficiaryId={beneficiaryId}
          setBeneficiaryId={setBeneficiaryId}
          control={form.control}
          isPrepare={isPrepare}
          selectedBeneficiaryName={selectedBeneficiary.name}
          selectedBeneficiaryIcon={selectedBeneficiary.icon}
          isDisabled={isDisabled || isPrepare}
          categoryId={categoryId}
        />

        <BeneficiaryDetails
          beneficiaryDetails={beneficiaryDetails}
          handleBeneficiaryDetailsEvent={handleBeneficiaryDetailsEvent}
          productsSelector={productsSelector}
          control={form.control}
          isPrepare={isPrepare}
          isDisabled={isDisabled}
          checkPayment={checkPayment}
        />
        <ButtonSum
          productsSelector={productsSelector}
          checkPayment={checkPayment}
          createPayment={createPayment}
          control={form.control}
          isDisabled={
            beneficiaryDetails.data?.data?.type !== ResponseType.ResolveWithData ||
            productsSelector.status !== ApiStatus.Fulfilled
          }
          isPrepare={isPrepare}
          isLoading={checkPayment.isLoading || createPayment.isLoading}
        />
      </FormContainer>
    </FormProvider>
  )
})
