import { useBetweenSelfProductToListMutation } from '@/api/transfers/betweenSelfProductToList'
import { statusToApiStatusEnum } from '@/utils/statusToApiStatusEnum'
import { createSelector } from 'reselect'

interface BetweenSelfProductToListSelectorState {
  betweenSelfProductToList: ReturnType<typeof useBetweenSelfProductToListMutation>
}
export const betweenSelfProductToListSelector = createSelector(
  (state: BetweenSelfProductToListSelectorState) => state.betweenSelfProductToList.data,
  (state: BetweenSelfProductToListSelectorState) => state.betweenSelfProductToList.status,
  (data, status) => ({
    data: data,
    status: statusToApiStatusEnum(status),
    refetchKey: 'betweenSelfProductToList',
  })
)
