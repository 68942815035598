import { useEffect, useState } from 'react'
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form'
import { ApiStatus, useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { useProductsSelector } from '../../hooks/useProductsSelector'
import { useEnhancedEffect, useEvent } from '@platform-ui/utils'
import { useBetweenSelfQuery } from '@/api/transfers/betweenSelf'
import { useBetweenSelfProductToListMutation } from '@/api/transfers/betweenSelfProductToList'
import { useBetweenSelfCommissionMutation } from '@/api/transfers/betweenSelfCommission'
import { useBetweenSelfTypeMutation } from '@/api/transfers/betweenSelfType'
import { sumToNumber } from './utils'
import { debounce } from 'debounce'
import { FromProduct } from '../../selectors/productsByBetweenSelfSelector'
import {
  BetweenSelfConfirmModel,
  useBetweenSelfConfirmMutation,
} from '@/api/transfers/betweenSelfConfirm'
import {
  BetweenSelfCheckModel,
  useBetweenSelfCheckMutation,
} from '@/api/transfers/betweenSelfCheck'
import { ResponseType } from '@/models/ResponseType'
import { EventPaymentSuccessModalModel } from '@/event-bus/events/PaymentSuccessModal'
import HomeBankIcon from '@/assets/banks/HomeBank.png'
import { useValidationResolver } from '@/utils/validation-resolver'
import { validationSchema } from './validationSchema'
import { useProductsQuery, ProductsModel } from '@/api/products/products'
import { ClientProductsModel, useClientProductsQuery } from '@/api/products/clientProducts'
import { useGetClientChecksQuery } from '@/api/transfers/getClientChecks'
import { useSettingsQuery } from '@/api/settings/settings'

export interface FormValues {
  fromProductId: string | null
  toProductId: string | null
  sum: string
}

/**
 *
 * @param initFromProductId Идентификатор продукта списания,
 * который требуется сделать выбранным по умолчанию
 */

export function useFormState(initFromProductId?: string, initToProductId?: string) {
  const context = useAppContext<AppContext>()
  const [isPrepareState, setIsPrepareState] = useState(false)

  const getClientChecksQuery = useGetClientChecksQuery()

  const clientCheck = Boolean(getClientChecksQuery.isSuccess && !getClientChecksQuery.data.result)

  const betweenSelfQuery = useBetweenSelfQuery(
    {},
    {
      enabled: clientCheck,
    }
  )

  const betweenSelfProductToListMutation = useBetweenSelfProductToListMutation()

  const betweenSelfCommissionMutation = useBetweenSelfCommissionMutation()

  const betweenSelfTypeMutation = useBetweenSelfTypeMutation()

  const betweenSelfConfirmMutation = useBetweenSelfConfirmMutation()

  const betweenSelfCheckMutation = useBetweenSelfCheckMutation()

  const products = useProductsQuery({ enabled: clientCheck })

  const clientProducts = useClientProductsQuery(
    {
      apiVersion: context.config.apiVersion,
    },
    { enabled: clientCheck }
  )

  const productsSelector = useProductsSelector(
    betweenSelfQuery,
    betweenSelfProductToListMutation,
    products,
    clientProducts
  )

  const resolver = useValidationResolver<FormValues>(
    validationSchema({ betweenSelfTypeMutation, productsSelector })
  )

  const form = useForm<FormValues>({
    resolver: resolver,
    mode: 'onBlur',
  })

  const [isDisabledReverse, setIsDisabledReverse] = useState(false)
  const { reset, watch, getValues, setValue } = form
  const { data: settingsData } = useSettingsQuery()

  const soldOutCreditPaymentDescription: string =
    settingsData?.type === ResponseType.ResolveWithData &&
    settingsData.result.soldOutCreditPaymentDescription

  /**
   * Самые важные запросы, без которых не выполнить перевод
   */
  const checkBusinessErrors = [betweenSelfTypeMutation, betweenSelfCommissionMutation]
  const hasBusinessError = checkBusinessErrors.some((item) => item.status === 'error')

  const businessError = hasBusinessError
    ? {
        message: 'При получении данных произошла ошибка',
        refetch: async () => {
          for (const query of checkBusinessErrors) {
            await query.mutateAsync(query.variables as any)
          }
        },
      }
    : null

  /**
   * Обновление комиссии за перевод
   */
  const handleCommissionEvent = useEvent(() => {
    let [fromProductId, sum] = getValues(['fromProductId', 'sum'])

    if (!betweenSelfTypeMutation.data?.response) {
      return
    }

    if (betweenSelfTypeMutation.data.response.type !== ResponseType.ResolveWithData) {
      handleTypeEvent()
      return
    }

    if (
      productsSelector.status !== ApiStatus.Fulfilled ||
      productsSelector.betweenSelfIsRefetching
    ) {
      return
    }

    const { products, requestId } = productsSelector

    const fromProduct = products[fromProductId]

    if (!fromProduct) {
      return
    }

    const newSum = sum ? sumToNumber(sum) : 0

    if (newSum === 0) {
      return
    }

    betweenSelfCommissionMutation.mutate({
      requestId: requestId,
      currencyType: fromProduct.currencyType,
      sum: newSum,
    })
  })

  /**
   * Обновление информации о лимите за перевод
   */
  const handleTypeEvent = useEvent(() => {
    let [fromProductId, toProductId] = getValues(['fromProductId', 'toProductId'])
    if (
      productsSelector.status !== ApiStatus.Fulfilled ||
      productsSelector.betweenSelfIsRefetching
    ) {
      return
    }

    const { products, requestId } = productsSelector

    const fromProduct = products[fromProductId]
    const toProduct = products[toProductId]

    if ((!fromProduct && !toProduct) || fromProductId === toProductId) {
      return
    }

    betweenSelfTypeMutation
      .mutateAsync({
        requestId: requestId,
        productFrom:
          fromProduct.categoryType === ProductsModel.CategoryType.Account
            ? {
                productType: 'account',
                accountNumber: fromProduct.accountNumber,
              }
            : {
                productType: 'card',
                accountNumber: fromProduct.accountNumber,
                maskCardNumber: fromProduct.maskCardNumber,
              },
        productTo:
          toProduct.categoryType === ProductsModel.CategoryType.Account ||
          toProduct.categoryType === ClientProductsModel.CategoryType.CreditLoan
            ? {
                productType: 'account',
                accountNumber: toProduct.accountNumber,
              }
            : {
                productType: 'card',
                accountNumber: toProduct.accountNumber,
                maskCardNumber: toProduct.maskCardNumber,
              },
      })
      .then(() => {
        /**
         * Запрос получения комисси должен выполняться после выбора продуктов списания и пополнения
         */
        handleCommissionEvent()
      })
  })

  /**
   * Обновление комиссии за перевод
   */
  const handleChangeSelectProductsEvent = useEvent(() => {
    let [fromProductId] = getValues(['fromProductId'])
    if (
      productsSelector.status !== ApiStatus.Fulfilled ||
      productsSelector.betweenSelfIsRefetching
    ) {
      return
    }

    const { fromProductIds } = productsSelector
    const currentIndex = fromProductIds.findIndex((id) => id === fromProductId)
    const next = fromProductIds[currentIndex + 1] || fromProductIds[0]

    setValue('fromProductId', next || null, { shouldValidate: true })
  })

  useEnhancedEffect(() => {
    const commissionEventDebounce = debounce(handleCommissionEvent, 400)

    const typeEventDebounce = debounce(handleTypeEvent, 400)

    const subscription = watch((formValues, state) => {
      /**
       * Меняет местами при выборе одинаковых продукты
       */

      if (state.name === 'toProductId' && formValues.toProductId === formValues.fromProductId) {
        handleChangeSelectProductsEvent()
      }

      if (state.name === 'sum' && formValues.sum && formValues.fromProductId) {
        /**
         * Запрос будет отправляться автоматически
         */
        commissionEventDebounce()
      }

      if (
        (state.name === 'fromProductId' || state.name === 'toProductId') &&
        formValues.toProductId &&
        formValues.fromProductId
      ) {
        /**
         * проверка возможности поменять местами 'куда' и 'откуда'
         */
        const { fromProductIds, toProductIds } = productsSelector
        const checkReverseFrom = fromProductIds.some((id) => id === formValues.toProductId)
        const checkReverseTo = toProductIds.some((id) => id === formValues.fromProductId)

        if (!checkReverseFrom || !checkReverseTo) {
          setIsDisabledReverse(true)
        } else {
          setIsDisabledReverse(false)
        }
        /**
         * Запрос получения комисси должен выполняться после выбора продуктов списания и пополнения
         */
        commissionEventDebounce.clear()
        /**
         * Запрос будет отправляться автоматически
         */
        typeEventDebounce()
      }
    })
    return () => {
      commissionEventDebounce.clear()
      typeEventDebounce.clear()
      subscription.unsubscribe()
    }
  }, [
    watch,
    handleTypeEvent,
    handleCommissionEvent,
    handleChangeSelectProductsEvent,
    productsSelector,
  ])

  useEffect(() => {
    if (
      productsSelector.status !== ApiStatus.Fulfilled ||
      productsSelector.betweenSelfIsRefetching
    ) {
      return
    }

    const { fromProductIds, toProductIds } = productsSelector
    /**
     * Инициализация продуктов для откуда и куда
     */
    let [fromProductId, toProductId] = getValues(['fromProductId', 'toProductId'])

    /**
     * При первичной инициализации необходимо проставить
     * идентификатор продукта пришедший в аргументе
     */

    if (!fromProductId && initFromProductId && !initToProductId) {
      fromProductId = initFromProductId
    }
    if (!toProductId && initToProductId) {
      toProductId = initToProductId
    }
    const from = fromProductIds.some((id) => id === fromProductId)
    const to = toProductIds.some((id) => id === toProductId)
    if (!fromProductId || !toProductId || !from || !to) {
      fromProductId = from ? fromProductId : fromProductIds[0]
      toProductId = to ? toProductId : toProductIds[0]

      if (!fromProductId) return

      if (fromProductId === toProductId) {
        initToProductId
          ? (fromProductId = fromProductIds[1] || null)
          : (toProductId = toProductIds[1] || null)
      }

      setValue('fromProductId', fromProductId, { shouldValidate: true })
      setValue('toProductId', toProductId, { shouldValidate: true })
    }
  }, [getValues, setValue, productsSelector, initFromProductId, initToProductId])

  const handleReverseValues = () => {
    let [fromProductId, toProductId] = getValues(['fromProductId', 'toProductId'])

    setValue('fromProductId', toProductId, { shouldValidate: true })
    setValue('toProductId', fromProductId, { shouldValidate: true })
  }

  const resetFormEvent = useEvent(() => {
    getClientChecksQuery.remove()
    betweenSelfQuery.remove()
    betweenSelfProductToListMutation.reset()
    betweenSelfTypeMutation.reset()
    betweenSelfCommissionMutation.reset()
    betweenSelfConfirmMutation.reset()
    betweenSelfCheckMutation.reset()
    reset({
      fromProductId: null,
      toProductId: null,
      sum: '',
    })
  })

  const resetSessionFormEvent = useEvent(() => {
    let [fromProductId, toProductId] = getValues(['fromProductId', 'toProductId'])

    betweenSelfProductToListMutation.reset()
    betweenSelfTypeMutation.reset()
    betweenSelfCommissionMutation.reset()
    betweenSelfConfirmMutation.reset()
    betweenSelfCheckMutation.reset()

    products.refetch()
    clientProducts.refetch()
    betweenSelfQuery.refetch()

    reset({
      sum: '',
      fromProductId,
      toProductId,
    })
  })

  useEffect(() => {
    return () => {
      /**
       * Очистка формы после ухода со страницы
       */
      resetFormEvent()
    }
  }, [resetFormEvent])

  const handleValidSubmit: SubmitHandler<FormValues> = async (formValues) => {
    if (!isPrepareState) {
      setIsPrepareState(true)
      return
    }
    const { fromProductId, toProductId } = formValues

    if (productsSelector.status !== ApiStatus.Fulfilled) {
      return null
    }
    if (betweenSelfCommissionMutation.data?.response?.type !== ResponseType.ResolveWithData) {
      return null
    }

    const { currencyType, fee, fullSum, sum } = betweenSelfCommissionMutation.data.response
    const fromProduct = productsSelector.products[fromProductId]
    const toProduct = productsSelector.products[toProductId]

    let confirmResponse: BetweenSelfConfirmModel.Response

    try {
      confirmResponse = await betweenSelfConfirmMutation.mutateAsync({
        requestId: productsSelector.requestId,
        productFrom:
          fromProduct.categoryType === ProductsModel.CategoryType.Account
            ? {
                productType: 'account',
                accountNumber: fromProduct.accountNumber,
              }
            : {
                productType: 'card',
                accountNumber: fromProduct.accountNumber,
                maskCardNumber: fromProduct.maskCardNumber,
              },
        productTo:
          toProduct.categoryType === ProductsModel.CategoryType.Account ||
          toProduct.categoryType === ClientProductsModel.CategoryType.CreditLoan
            ? {
                productType: 'account',
                accountNumber: toProduct.accountNumber,
              }
            : {
                productType: 'card',
                accountNumber: toProduct.accountNumber,
                maskCardNumber: toProduct.maskCardNumber,
              },
        currencyType: currencyType,
        fee: fee,
        fullSum: fullSum,
        sum: sum,
        transferPurpose: '',
      })
    } catch (error) {
      /**
       * Обработка сетевой ошибки
       */
      const err: BetweenSelfConfirmModel.ErrorResponse = error
      if (err.response) {
        context.toastify.error(err.response.systemErrors.map((item) => item.message).join(', '))
      } else {
        // TODO: придумать что делать с ошибкой при отсутствии доступа к интернету или ошибкой доступа к ресурсу
        console.log(err.type)
      }

      return
    }

    /**
     * Для внутренних переводов подтверждение не требуется
     */
    if (confirmResponse.response.smsCheck) {
      /**
       * Открыть окно ввода смс
       */

      return
    }

    handleCheckSuccess(null)
  }

  const handleInvalidSubmit: SubmitErrorHandler<FormValues> = async () => void 0

  const handleCheckSuccess = async (pinCode: string) => {
    if (productsSelector.status !== ApiStatus.Fulfilled) {
      return null
    }

    let betweenSelfCheckResponse: BetweenSelfCheckModel.Response
    try {
      betweenSelfCheckResponse = await betweenSelfCheckMutation.mutateAsync({
        otp: pinCode,
        requestId: productsSelector.requestId,
      })
    } catch (error) {
      /**
       * Обработка сетевой ошибки
       */
      const err: BetweenSelfCheckModel.ErrorResponse = error
      if (err.response) {
        context.toastify.error(err.response.systemErrors.map((item) => item.message).join(', '))
      } else {
        // TODO: придумать что делать с ошибкой при отсутствии доступа к интернету или ошибкой доступа к ресурсу
        console.log(err.type)
      }

      return
    }

    if (
      betweenSelfCheckResponse.response.type !== ResponseType.ResolveWithData ||
      betweenSelfCheckResponse.response.result.resultCode !== BetweenSelfCheckModel.ResultCode.Match
    ) {
      context.toastify.error('При переводе средств что то пошло не так')
      return
    }

    const { currencyType, fullSum } = betweenSelfCommissionMutation.data.response

    const [fromProductId, toProductId] = getValues(['fromProductId', 'toProductId'])

    const fromProduct = productsSelector.products[fromProductId] as FromProduct
    const toProduct = productsSelector.products[toProductId]
    context.eventBus.transfersModal.close.emit()

    context.eventBus.paymentSuccessModal.open.emit({
      amount: fullSum,
      currency: currencyType,
      from:
        fromProduct.categoryType === ProductsModel.CategoryType.Account
          ? {
              transferType: EventPaymentSuccessModalModel.FromTransferType.Account,
              accountNumber: fromProduct.accountNumber,
              id: fromProduct.id,
              name: fromProduct.accountName,
            }
          : {
              transferType: EventPaymentSuccessModalModel.FromTransferType.Card,
              accountNumber: fromProduct.accountNumber,
              cardNumber: fromProduct.maskCardNumber,
              id: fromProduct.id,
              name: fromProduct.productName,
            },
      to:
        toProduct.categoryType === ProductsModel.CategoryType.Account ||
        toProduct.categoryType === ClientProductsModel.CategoryType.CreditLoan
          ? {
              transferType: EventPaymentSuccessModalModel.ToTransferType.Account,
              accountNumber: toProduct.accountNumber,
              name: toProduct.productName,
              icon: HomeBankIcon,
            }
          : {
              transferType: EventPaymentSuccessModalModel.ToTransferType.Card,
              accountNumber: toProduct.accountNumber,
              cardNumber: toProduct.maskCardNumber,
              name: toProduct.productName,
              icon: HomeBankIcon,
            },
      id: `${betweenSelfCheckResponse.response.result.transactionId}`,
    })
  }

  useEffect(() => {
    const paymentSuccessModalUsub = context.eventBus.paymentSuccessModal.closed.on(() => {
      resetSessionFormEvent()
    })

    return () => {
      paymentSuccessModalUsub()
    }
  }, [context, resetSessionFormEvent])

  return {
    context,
    form,
    handleValidSubmit,
    handleInvalidSubmit,
    productsSelector,
    resetForm: resetFormEvent,
    resetTransactionSessionForm: resetSessionFormEvent,
    getClientChecksQuery,
    betweenSelfProductToListMutation,
    betweenSelfCommissionMutation,
    betweenSelfTypeMutation,
    betweenSelfConfirmMutation,
    betweenSelfCheckMutation,
    businessError,
    isPrepareState,
    setIsPrepareState,
    soldOutCreditPaymentDescription,
    isDisabledReverse,
    handleReverseValues,
  }
}
