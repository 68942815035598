import { ApiStatus, useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { useCallback, useEffect } from 'react'
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form'
import { validationSchema } from './validationSchema'
import { SelectProductsModel } from '@/components/SelectProducts'
import { useProductsSelector } from '../../hooks/useProductsSelector'
import { usePhoneMutation } from '@/api/transfers/phone'
import { usePhoneGetSbpLogoMutation } from '@/api/transfers/phoneGetSbpLogo'
import { InputPhoneNumberProps } from '@platform-ui/components/InputPhoneNumber'
import isMobilePhone from 'validator/lib/isMobilePhone'
import { useEnhancedEffect, useEvent } from '@platform-ui/utils'
import { phoneNumberToUnmask, sumToNumber } from './utils'
import { ResponseType } from '@/models/ResponseType'
import { usePhoneCommissionMutation } from '@/api/transfers/phoneCommission'
import { debounce } from 'debounce'
import { PhoneTransferModel, usePhoneTransferMutation } from '@/api/transfers/phoneTransfer'
import { PhoneCheckModel, usePhoneCheckMutation } from '@/api/transfers/phoneCheck'
import { usePhoneResendMutation } from '@/api/transfers/phoneResend'
import { useProvidersSelector } from '../../hooks/useProvidersSelector'
import { useValidationResolver } from '@/utils/validation-resolver'
import { PhonePrepareModel, usePhonePrepareMutation } from '@/api/transfers/phonePrepare'
import { useClientProductsQuery } from '@/api/products/clientProducts'
import { useProductsQuery } from '@/api/products/products'
import { EventPaymentSuccessModalModel } from '@/event-bus/events/PaymentSuccessModal'

export interface FormValues {
  fromProductId: SelectProductsModel.Product['id']
  phoneNumber: string
  isPhoneNumberComplete: boolean
  providerId: string
  message: string
  sum: string
  bankId: string
}

export function useFormState({
  isPrepareState,
  setIsPrepareState,
  initFromProductId,
}: {
  initFromProductId?: string
  isPrepareState: boolean
  setIsPrepareState: (boolean) => void
}) {
  const context = useAppContext<AppContext>()

  const products = useProductsQuery()

  const clientProducts = useClientProductsQuery({
    apiVersion: context.config.apiVersion,
  })

  const productsSelector = useProductsSelector(products, clientProducts)

  /**
   * Инициализирует процесс перевода
   */
  const phoneMutation = usePhoneMutation()

  const phoneGetSbpLogoMutation = usePhoneGetSbpLogoMutation()

  /**
   * Меняется при смене суммы, зависит от phoneMutation
   */
  const phoneCommissionMutation = usePhoneCommissionMutation()

  /**
   * Оповещает об переводе, зависит от phoneMutation
   */
  const phoneTransferMutation = usePhoneTransferMutation()

  const phonePrepareMutation = usePhonePrepareMutation()

  /**
   * Повторный запрос отправки кода
   */
  const phoneReSendMutation = usePhoneResendMutation()

  /**
   * Завершает перевод, зависит от phoneTransferMutation
   */
  const phoneCheckMutation = usePhoneCheckMutation()

  const providersSelector = useProvidersSelector(phoneMutation)

  const resolver = useValidationResolver(
    validationSchema({ productsSelector, providersSelector, context })
  )

  const form = useForm<FormValues>({
    defaultValues: {
      isPhoneNumberComplete: false,
    },
    resolver,
    mode: 'onBlur',
  })

  const { setValue, reset, watch, getValues, clearErrors } = form

  const handlePhoneCommissionEvent = useEvent(() => {
    let [providerId, sum, fromProductId, bankId, phoneNumber] = getValues([
      'providerId',
      'sum',
      'fromProductId',
      'bankId',
      'phoneNumber',
    ])

    if (
      providersSelector.status !== ApiStatus.Fulfilled ||
      !providerId ||
      !providersSelector.providers[providerId]
    ) {
      return
    }

    const newSum = sum ? sumToNumber(sum) : 0
    if (newSum === 0) {
      return
    }

    const provider = providersSelector.providers[providerId]
    const fromProduct = productsSelector.products[fromProductId]

    if (
      newSum < provider.limit.minTransferLimit ||
      newSum > provider.limit.maxTransferLimit ||
      fromProduct.availableBalance < newSum
    ) {
      return
    }

    const phoneCommissionParams = {
      currencyType: provider.currencyType,
      requestId: providersSelector.requestId,
      sum: newSum,
      transferType: provider.transferType,
    }

    const isTransferBySpb = providerId === 'sbp'

    /**
     * Если перевод по сбп, то сначала дёргаем /prepare,
     * и если всё ок дёргаем /commission
     */
    if (isTransferBySpb) {
      const unmaskPhoneNumber = phoneNumberToUnmask(phoneNumber)

      try {
        phonePrepareMutation
          .mutateAsync({
            requestId: providersSelector.requestId,
            phoneNumber: unmaskPhoneNumber,
            bankId: bankId,
            currencyType: 'RUB',
            sum: newSum,
            transferType: provider.transferType,
            provider: 'sbp',
            authId: context.auth.authId,
            transactionProductFrom: {
              productType: 'card',
              ...provider.transactionProductFrom,
            },
          })
          .then(({ data }) => {
            if (data?.type === ResponseType.ResolveWithData) {
              phoneCommissionMutation.mutate(phoneCommissionParams)
            }
          })
      } catch (error) {
        /**
         * Обработка сетевой ошибки
         */
        const err: PhonePrepareModel.ErrorResponse = error
        if (err.response) {
          context.toastify.error(err.response.systemErrors.map((item) => item.message).join(', '))
        } else {
          // TODO: придумать что делать с ошибкой при отсутствии доступа к интернету или ошибкой доступа к ресурсу
          console.log(err.type)
        }

        return
      }
    }

    if (!isTransferBySpb) {
      phoneCommissionMutation.mutate(phoneCommissionParams)
    }
  })

  const handlePhoneMutateEvent = useEvent(() => {
    let [fromProductId, phoneNumber, sum] = getValues(['fromProductId', 'phoneNumber', 'sum'])

    if (
      productsSelector.status !== ApiStatus.Fulfilled ||
      !phoneNumber ||
      phoneNumber.length < 18
    ) {
      return
    }
    /**
     * Новые параметры перевода - требуется удалить устаревшие данные комиссии
     */
    phoneCommissionMutation.reset()

    const newSum = sum ? sumToNumber(sum) : 0
    const unmaskPhoneNumber = phoneNumberToUnmask(phoneNumber)

    const { products } = productsSelector

    const product = products[fromProductId]

    phoneMutation
      .mutateAsync({
        apiVersion: context.config.apiVersion,
        currencyType: product.currencyType,
        phoneNumber: unmaskPhoneNumber,
        sum: newSum,
        transactionProductFrom: {
          productType: 'card',
          accountNumber: product.accountNumber,
          maskCardNumber: product.maskCardNumber,
        },
      })
      .then(({ response }) => {
        if (response.type !== ResponseType.ResolveWithData) {
          return
        }
        const providerSbp = response.providers.find((item) => item.id === 'sbp')

        const banks = Boolean(providerSbp)
          ? providerSbp.banks.map((bank) => ({
              SbpBankId: bank.id,
              BankName: bank.name,
            }))
          : []

        /**
         * Запрос для получения иконок дёргаем после получения ответа от phoneMutation
         */
        try {
          phoneGetSbpLogoMutation.mutateAsync({
            banks,
          })
        } catch (err) {
          console.log(err)
        }
      })
  })

  /**
   * Самые важные запросы, без которых не выполнить перевод
   */
  const checkBusinessErrors = [phoneCommissionMutation]
  const hasBusinessError = checkBusinessErrors.some((item) => item.status === 'error')

  const businessError = hasBusinessError
    ? {
        message: 'При получении данных произошла ошибка',
        refetch: async () => {
          for (const query of checkBusinessErrors) {
            await query.mutateAsync(query.variables as any)
          }
        },
      }
    : null

  const phoneMutationReset = useEvent(() => {
    phoneMutation.reset()
  })

  useEnhancedEffect(() => {
    const phoneCommissionDebounce = debounce(handlePhoneCommissionEvent, 400)
    const phoneDebounce = debounce(handlePhoneMutateEvent, 400)

    const subscription = watch((formValues, event) => {
      if (event.name === 'isPhoneNumberComplete' && !formValues.isPhoneNumberComplete) {
        phoneMutationReset()
      }

      if (
        (event.name === 'isPhoneNumberComplete' || event.name === 'fromProductId') &&
        formValues.isPhoneNumberComplete &&
        formValues.fromProductId
      ) {
        /**
         * Необходимо завершить возникший debounce, так как
         * handlePhoneMutate начинает процесс с начала
         */
        phoneCommissionDebounce.clear()
        /**
         * Делать новый запрос на любое изменение выбранного продукта или номера телефона
         */
        phoneDebounce()
      }

      if (
        event.name === 'sum' &&
        formValues.isPhoneNumberComplete &&
        formValues.fromProductId &&
        formValues.providerId
      ) {
        phoneCommissionDebounce()
      }

      if (event.name === 'sum' && !formValues.providerId) {
        clearErrors('sum')
      }
    })
    return () => {
      phoneDebounce.clear()
      phoneCommissionDebounce.clear()
      subscription.unsubscribe()
    }
  }, [watch, setValue, handlePhoneCommissionEvent, handlePhoneMutateEvent, clearErrors])

  useEffect(() => {
    if (productsSelector.status !== ApiStatus.Fulfilled) {
      return
    }

    const { productIds } = productsSelector

    let [fromProductId] = getValues(['fromProductId'])

    if (fromProductId && productIds.some((id) => id === fromProductId)) {
      return
    }
    /**
     * При первичной инициализации необходимо проставить
     * идентификатор продукта пришедший в аргументе
     */
    if (initFromProductId) {
      fromProductId = initFromProductId
    }

    fromProductId = productIds.some((id) => id === fromProductId) ? fromProductId : productIds[0]

    if (!fromProductId) {
      return
    }

    setValue('fromProductId', fromProductId, { shouldValidate: true })
  }, [productsSelector, setValue, getValues, initFromProductId])

  const resetFormEvent = useEvent(() => {
    phoneMutation.reset()
    phoneCommissionMutation.reset()
    phoneTransferMutation.reset()
    phoneReSendMutation.reset()
    phoneCheckMutation.reset()
    phonePrepareMutation.reset()

    reset({
      fromProductId: null,
      isPhoneNumberComplete: false,
      message: '',
      phoneNumber: '',
      providerId: '',
      sum: '',
      bankId: '',
    })
  })

  useEffect(() => {
    return () => {
      /**
       * Очистка формы при уходе со страницы
       */
      resetFormEvent()
    }
  }, [resetFormEvent])

  const handlePhoneNumberComplete: InputPhoneNumberProps['onValueChange'] = useCallback(
    (value) => {
      if (!context.config.features.phoneValidation_OB_14737) {
        setValue('isPhoneNumberComplete', true)
      }

      if (value.value.length !== 12 || !isMobilePhone.default(value.value, 'ru-RU')) {
        if (providersSelector.status === ApiStatus.Fulfilled) {
          providersSelector.reset()
          setValue('providerId', null)
          setValue('sum', '')
        }
        return
      }
      setValue('isPhoneNumberComplete', true, { shouldValidate: true })
    },
    [providersSelector, setValue]
  )

  const handleValidSubmit: SubmitHandler<FormValues> = async (formValues) => {
    const { fromProductId, message, phoneNumber, providerId, bankId } = formValues

    if (productsSelector.status !== ApiStatus.Fulfilled) {
      return null
    }

    if (providersSelector.status !== ApiStatus.Fulfilled) {
      return null
    }

    if (phoneCommissionMutation.data?.response?.type !== ResponseType.ResolveWithData) {
      return null
    }

    const { fee, currencyType, sum } = phoneCommissionMutation.data.response

    const unmaskPhoneNumber = phoneNumberToUnmask(phoneNumber)
    const product = productsSelector.products[fromProductId]
    const provider = providersSelector.providers[providerId]

    /**
     * Перейти на второй шаг без запросов
     */
    if (!isPrepareState) {
      setIsPrepareState(true)
      return
    }

    let phoneTransfer: PhoneTransferModel.Response

    try {
      phoneTransfer = await phoneTransferMutation.mutateAsync({
        currencyType: currencyType,
        fee: fee,
        phoneNumber: unmaskPhoneNumber,
        providerId: providerId,
        sum: sum,
        transactionProductFrom: {
          productType: 'card',
          accountNumber: product.accountNumber,
          maskCardNumber: product.maskCardNumber,
        },
        requestId: providersSelector.requestId,
        transferType: provider.transferType,
        paymentPurpose: message,
        authId: context.auth.authId,
      })
    } catch (error) {
      /**
       * Обработка сетевой ошибки
       */
      const err: PhoneTransferModel.ErrorResponse = error
      if (err.response) {
        context.toastify.error(err.response.systemErrors.map((item) => item.message).join(', '))
      } else {
        // TODO: придумать что делать с ошибкой при отсутствии доступа к интернету или ошибкой доступа к ресурсу
        console.log(err.type)
      }

      return
    }

    const phoneTransferResponse = phoneTransfer.response

    if (
      phoneTransferResponse.type !== ResponseType.ResolveWithData ||
      phoneTransferResponse.result.resultCode !== PhoneTransferModel.ResultCode.Success
    ) {
      return
    }
  }

  const handlePhoneCheckEvent = async (pinCode: string) => {
    let [fromProductId, providerId, phoneNumber] = getValues([
      'fromProductId',
      'providerId',
      'phoneNumber',
    ])
    if (
      productsSelector.status !== ApiStatus.Fulfilled ||
      providersSelector.status !== ApiStatus.Fulfilled
    ) {
      return null
    }

    let phoneCheckResponse

    try {
      phoneCheckResponse = await phoneCheckMutation.mutateAsync({
        otp: pinCode,
        requestId: providersSelector.requestId,
      })
    } catch (err) {
      if (err.status >= 400) {
        context.toastify.error(err.data.systemErrors.map((item) => item.message).join(', '))
      }
      return
    }

    if (
      phoneCheckResponse.data.type !== ResponseType.ResolveWithData ||
      phoneCheckResponse.data.result.resultCode !== PhoneCheckModel.ResultCode.Match
    ) {
      context.toastify.error('При переводе средств что то пошло не так')
      return
    }

    const fromProduct = productsSelector.products[fromProductId]
    const provider = providersSelector.providers[providerId]

    const { currencyType, fullSum } = phoneCommissionMutation.data.response

    context.eventBus.transfersModal.close.emit()
    context.eventBus.paymentSuccessModal.open.emit({
      amount: fullSum,
      currency: currencyType,
      from: {
        transferType: fromProduct.categoryType,
        id: fromProduct.id,
        cardNumber: fromProduct.maskCardNumber,
        accountNumber: fromProduct.accountNumber,
        name: fromProduct.productName,
      },
      to: {
        transferType: EventPaymentSuccessModalModel.ToTransferType.PhoneNumber,
        phoneNumber: phoneNumber,
        userName:
          phonePrepareMutation.data?.response?.type === ResponseType.ResolveWithData
            ? phonePrepareMutation.data.response.provider.recipientName
            : provider.recipientName,
      },
      id: `${phoneCheckResponse.data.result.transactionId}`,
    })
  }

  const handleInvalidSubmit: SubmitErrorHandler<FormValues> = async () => void 0

  const handleReSendCodeEvent = useEvent(() => {
    if (providersSelector.status !== ApiStatus.Fulfilled) {
      return
    }

    phoneReSendMutation.mutate({
      requestId: providersSelector.requestId,
    })
  })

  return {
    handleValidSubmit,
    handleInvalidSubmit,
    context,
    form,
    handlePhoneNumberComplete,
    phoneMutation,
    phoneCommissionMutation,
    phoneTransferMutation,
    phoneCheckMutation,
    phoneReSendMutation,
    providersSelector,
    productsSelector,
    businessError,
    handlePhoneCheckEvent,
    handleReSendCodeEvent,
    phoneGetSbpLogoMutation,
    phonePrepareMutation,
    handlePhoneMutateEvent,
    handlePhoneCommissionEvent,
  }
}
