import { useMutation, UseMutationOptions } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { BetweenSelfCheckModel } from './BetweenSelfCheckModel'
import { adapter } from './adapters/adapter'
import { stringTemplate } from '@/utils/string-template'

/**
 * Docs
 * @see https://confluence.homecredit.ru/confluence/pages/viewpage.action?pageId=90283941
 */
export const betweenSelfCheck: BetweenSelfCheckModel.Func = async (props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.transfers.betweenSelfCheck,
    {} as ApiManagerModel.ApiOption
  )

  const response = await HttpClient.post<
    BetweenSelfCheckModel.ResponseData,
    void,
    BetweenSelfCheckModel.Requestdata,
    BetweenSelfCheckModel.RequestHeaders,
    BetweenSelfCheckModel.ErrorResponse
  >({
    url: stringTemplate<{ requestId: string }>(path, { requestId: props.requestId }),
    headers: null,
    responseAdapter: adapter,
    isValidStatus(status) {
      /**
       * 403 ответ со status blocked
       */
      return (status >= 200 && status < 300) || status === 403
    },
    data: {
      otp: props.otp,
    },

    control,
  })

  return response
}

export function useBetweenSelfCheckMutation(
  options?: Omit<
    UseMutationOptions<
      BetweenSelfCheckModel.Response,
      BetweenSelfCheckModel.ErrorResponse,
      BetweenSelfCheckModel.Props,
      unknown
    >,
    'mutationFn'
  >
) {
  return useMutation(betweenSelfCheck, {
    retry: 3,
    ...options,
  })
}
