import styled from '@emotion/styled'
import { FieldControl } from '@platform-ui/components/FieldControl'
import { PureInput } from '@platform-ui/components/PureInput'
import { Popover } from '@platform-ui/components/Popover'

export const FieldControlCustom = styled(FieldControl)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  marginBottom: 16,
}))

export const InputWrapper = styled.div(({ theme }) => ({
  background: theme.color['surface/tertiary'],
  padding: 16,
  position: 'relative',
}))

export const InputStyled = styled(PureInput)(({ theme }) => ({
  height: 40,
  width: '100%',
  fontSize: 16,
  paddingLeft: 36,

  '::placeholder': {
    fontSize: 16,
  },
  ':not(:disabled)': {
    ':focus, :active': {
      fontSize: 16,
      paddingLeft: 36,
    },
  },

  [`${theme.media.md}`]: {
    fontSize: 14,

    '::placeholder': {
      fontSize: 14,
    },
    ':not(:disabled)': {
      ':focus, :active': {
        fontSize: 14,
      },
    },
  },
}))

export const InputSearchLeftSlot = styled.div(() => ({
  position: 'absolute',
  left: 24,
  bottom: 0,
  top: 0,
  margin: 'auto',
  height: 24,
  width: 24,
}))

export const PopoverStyled = styled(Popover)(() => ({
  '& > div > div > div': {
    borderRadius: '0px 0px 12px 12px',
  },
  '& > div': {
    marginTop: -4,
  },
}))

export const TransferProviderWrapper = styled.div(({ theme }) => ({
  overflowY: 'scroll',
  maxHeight: '220px',
  overflowX: 'hidden',
  overscrollBehavior: 'none',
  WebkitOverflowScrolling: 'touch',

  [`${theme.media.md}`]: {
    maxHeight: '100vh',
  },
}))

export const PaymentRecipientWrapper = styled.div(() => ({
  position: 'relative',
}))
