import React from 'react'
import {
  TransfersBetweenProductsForm,
  TransfersBetweenProductsFormProps,
} from './containers/TransfersBetweenProductsForm'

export interface TransfersBetweenProductsProps extends TransfersBetweenProductsFormProps {}

export const TransfersBetweenProducts = (props: TransfersBetweenProductsProps) => {
  return <TransfersBetweenProductsForm {...props} />
}
