import { ResponseType } from '@/models/ResponseType'
import { createSelector } from 'reselect'
import { UseQueryResult } from 'react-query'
import { SystemError } from '@/models/SystemError'
import { ApiStatus } from '@/core'
import _keyBy from 'lodash/keyBy'
import { ClientCardsModel } from '@/api/pms/clientCards'

type State = {
  clientCards: UseQueryResult<ClientCardsModel.ResponseData, ClientCardsModel.ErrorResponse>
}
const selector = createSelector(
  (state: State) => state.clientCards.data,
  (state: State) => state.clientCards.status,
  (state: State) => state.clientCards.refetch,
  (
    clientCardsResponse,
    clientCardsStatus,
    clientCardsRefetch
  ): {
    error: SystemError<'NETWORK' | 'PARTIAL_RESPONSE' | 'IS_EMPTY_PRODUCTS'> | null
    products: any
    fromProductIds: string[]
    status: ApiStatus
    retry: () => void
    hasAnyResult: boolean
  } => {
    const retryFns: (() => void)[] = []

    let products = []

    let hasNetworkError = false
    let hasPartialResponse = false
    let status = ApiStatus.Idle

    if (clientCardsStatus === 'success' || clientCardsStatus === 'error') {
      status = ApiStatus.Fulfilled
    }

    if (clientCardsStatus === 'error') {
      status = ApiStatus.Rejected
      retryFns.push(clientCardsRefetch)
      hasNetworkError = true
    }

    if (clientCardsStatus === 'loading') {
      status = ApiStatus.Pending
    }

    if (clientCardsResponse) {
      if (clientCardsResponse.type === ResponseType.ResolveWithData) {
        products = products.concat(...clientCardsResponse.products)
      } else {
        hasPartialResponse = true
        retryFns.push(clientCardsRefetch)
      }
    }

    const error: SystemError<'NETWORK' | 'PARTIAL_RESPONSE' | 'IS_EMPTY_PRODUCTS'> | null =
      hasNetworkError
        ? {
            type: 'NETWORK',
            message: 'При получении данных произошла ошибка',
          }
        : hasPartialResponse
        ? {
            type: 'PARTIAL_RESPONSE',
            message: 'Ведутся сервисные работы',
          }
        : status === ApiStatus.Fulfilled && !products.length
        ? {
            type: 'IS_EMPTY_PRODUCTS',
            message: 'Список продуктов пуст',
          }
        : null

    const hasProducts = products.length > 0
    const productsMap = _keyBy(products, 'id')

    return {
      error: error,
      products: productsMap,
      fromProductIds: products.map((item) => item.id),
      status,
      retry: () => {
        retryFns.forEach((item) => {
          item()
        })
      },
      hasAnyResult: hasProducts || !!error,
    }
  }
)

export function useProductsSelector(clientCards) {
  return selector({ clientCards: clientCards })
}
