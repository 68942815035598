import styled from '@emotion/styled'

export const ButtonContainer = styled.div(({ theme }) => ({
  height: 'auto',
  width: '100%',
  padding: '0px 16px 50px',
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  boxShadow: `0px -32px 16px -10px ${theme.color['background/main']}`,
  background: theme.color['background/main'],

  [`${theme.media.md}`]: {
    padding: '0px 32px 32px',
    borderBottomLeftRadius: theme.borderRadius['r12'],
    borderBottomRightRadius: theme.borderRadius['r12'],
  },
}))
