import { ApiStatus } from '@/core'
import { ResponseType } from '@/models/ResponseType'
import { currency } from '@/utils/currency'
import { observer } from 'mobx-react-lite'
import { Control, useWatch } from 'react-hook-form'
import { FormValues, useFormState } from '../TransfersByPhoneNumberForm/useFormState'
import { ButtonContainer, ButtonStyled } from './styled'

/**
 * @see https://confluence.homecredit.ru/confluence/pages/viewpage.action?pageId=127075462#id-%D0%A1%D1%82%D0%B0%D1%80%D1%82%D0%BF%D0%B5%D1%80%D0%B5%D0%B2%D0%BE%D0%B4%D0%B0-%D0%97%D0%B0%D0%BF%D0%BE%D0%BB%D0%BD%D0%B5%D0%BD%D0%BD%D0%BE%D0%B5%D0%B2%D0%B0%D0%BB%D0%B8%D0%B4%D0%BD%D0%BE%D0%B5%D0%BF%D0%BE%D0%BB%D0%B5
 */
export const ButtonSum = observer<{
  control: Control<FormValues>
  productsSelector: ReturnType<typeof useFormState>['productsSelector']
  phoneCommissionMutation: ReturnType<typeof useFormState>['phoneCommissionMutation']
  phoneTransferMutation: ReturnType<typeof useFormState>['phoneTransferMutation']
  phoneCheckMutation: ReturnType<typeof useFormState>['phoneCheckMutation']
  phonePrepareMutation: ReturnType<typeof useFormState>['phonePrepareMutation']
  isPrepare: boolean
  isDisabled: boolean
}>(
  ({
    control,
    productsSelector,
    phoneCommissionMutation,
    phoneTransferMutation,
    phoneCheckMutation,
    phonePrepareMutation,
    isPrepare,
    isDisabled,
  }) => {
    const sum = useWatch({ name: 'sum', control: control })

    if (productsSelector.status !== ApiStatus.Fulfilled) {
      return null
    }

    const text = 'Продолжить'

    const isLoading =
      phoneCommissionMutation.isLoading ||
      phoneTransferMutation.isLoading ||
      phoneCheckMutation.isLoading ||
      phonePrepareMutation.isLoading

    if (
      !sum ||
      isDisabled ||
      phoneCommissionMutation.data?.response?.type !== ResponseType.ResolveWithData ||
      !isPrepare
    ) {
      return (
        <ButtonContainer>
          <ButtonStyled
            type="submit"
            isFullWidth
            isLoading={isLoading}
            isDisabled={isDisabled || !sum}
          >
            {text}
          </ButtonStyled>
        </ButtonContainer>
      )
    }

    const { currencyType, fullSum, fee } = phoneCommissionMutation.data.response

    return (
      <ButtonContainer>
        <ButtonStyled
          type="submit"
          isFullWidth
          isLoading={isLoading}
          subtitle={
            fee === 0 ? 'Без комиссии' : `Включая комиссию ${currency(fee, currencyType).format()}`
          }
          isDisabled={isDisabled}
        >
          {`${text} ${currency(fullSum, currencyType).format()}`}
        </ButtonStyled>
      </ButtonContainer>
    )
  }
)
