import styled from '@emotion/styled'
import { FieldControl } from '@platform-ui/components/FieldControl'
import { Input } from '@platform-ui/components/Input'
import { Body14 } from '@platform-ui/typography'
import { Popover } from '@platform-ui/components/Popover'
import { Shimmer } from '@platform-ui/components/Shimmer'
import { InputMask } from '@platform-ui/components/InputMask'
import { InputCurrency } from '@platform-ui/components/InputCurrency'

export const FieldControlCustom = styled(FieldControl)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  margin: '16px 0',
}))

export const FieldWrapper = styled.div(() => ({
  [`:nth-last-child(2)`]: {
    paddingBottom: 110,
  },
}))

export const Hint = styled(Body14)(({ theme }) => ({
  marginTop: 8,
  color: theme.color['text/secondary'],
  marginLeft: 16,
}))

export const InputCurrencyStyled = styled(InputCurrency)(({ theme, isDisabled }) => ({
  input: {
    background: isDisabled && theme.color['surface/tertiary'],
    color: isDisabled && theme.color['text/secondary'],
  },
}))

export const InputMaskStyled = styled(InputMask)(({ theme, isDisabled }) => ({
  input: {
    background: isDisabled && theme.color['surface/tertiary'],
    color: isDisabled && theme.color['text/secondary'],
  },
}))

export const InputField = styled(Input)(({ theme, isDisabled, isList, isOpen, isLoading }) => ({
  input: {
    background: isDisabled && theme.color['surface/tertiary'],
    color: isDisabled && theme.color['text/secondary'],
    cursor: isLoading ? 'not-allowed' : isList && 'pointer',
    borderRadius: isOpen && '12px 12px 0 0',
  },
  svg: {
    cursor: isDisabled ? 'not-allowed' : isList && 'pointer',
    transition: 'transform 200ms linear',
    transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
  },
}))

export const Text = styled.p(({ theme }) => ({
  ...theme.typography['additional/16 button'],
  color: theme.color['text/main'],
  margin: 0,
  minWidth: 0,
  flexGrow: 1,
}))

export const PopoverStyled = styled(Popover)<{ isError: boolean }>(() => ({
  '& > div': {
    marginTop: -4,
  },
  '& > div > div > div': {
    borderRadius: '0px 0px 12px 12px',
    maxHeight: 222,
    overflowY: 'auto',
  },
}))

export const Item = styled.div(({ theme }) => ({
  position: 'relative',
  padding: '20px 16px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',

  ':after': {
    content: "''",
    display: 'block',
    position: 'absolute',
    bottom: 0,
    background: theme.color['surface/line'],
    height: 1,
    width: '100%',
  },
}))

export const BankDetailsShimmer = styled(Shimmer)(() => ({
  height: '64px',
  width: '100%',
  borderRadius: '12px',
  margin: '16px 0',
}))

export const ErrorWrapper = styled.div(() => ({
  margin: '16px 0',
  padding: '16px 0',
  textAlign: 'center',
}))

export const ErrorDesc = styled.p(({ theme }) => ({
  ...theme.typography['headline/16'],
  color: theme.color['text/secondary'],
  marginBottom: 24,
}))
