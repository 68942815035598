import { HttpClientModel } from '@/core'
import { ResolveWithSystemErrorResponse } from '@/models/ResolveWithSystemErrorResponse'
import { ResponseType } from '@/models/ResponseType'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'
import { CurrencyType } from '@/models/CurrencyType'
import { SystemError } from '@/models/SystemError'

export namespace PhoneModel {
  export type Func = (props: Props) => Promise<Response>
  export interface Props {
    apiVersion: string
    transferType?: PhoneModel.TransferType
    phoneNumber: string
    transactionProductFrom:
      | {
          productType: 'card'
          /**
           * @example  '446915XXXXXX9498'
           */
          maskCardNumber: string
          /**
           * @example  '40817810790012545944'
           */
          accountNumber: string
        }
      | {
          productType: 'account'

          /**
           * @example  '40817810790012545944'
           */
          accountNumber: string
        }
    /**
     * @example  '300'
     */
    sum: number
    /**
     * @example  'RUB'
     */
    currencyType: CurrencyType
  }
  export type Response = HttpClientModel.Response<ResponseData>
  export type ResponseData = ResolveWithDataResponse | ResponseDataResolveWithSystemErrorResponse
  export type ResponseDataResolveWithSystemErrorResponse = ResolveWithSystemErrorResponse<
    SystemError<'ERROR_NO_RECIPIENT_CARDS' | 'INTERNAL_SERVER_ERROR'>
  >
  export type ErrorResponse = HttpClientModel.ErrorResponse<ErrorResponseData>
  export type ErrorResponseData = RejectedWithSystemErrorResponse
  export interface Requestdata {
    /**
     * @example  '9409211911'
     */
    alias: string
    transactionProductFrom: {
      /**
       * @example  '446915XXXXXX9498'
       */
      maskCardNumber?: string
      /**
       * @example  '40817810790012545944'
       */
      accountNumber: string
    }
    /**
     * @example  '300'
     */
    sum: number
    /**
     * @example  'RUR'
     */
    currency: string
    provider?: string
  }

  /**
   * На стороне бекенда преобразование
   */
  export enum TransferType {
    // Внешний.
    External = 1,

    // Внутренний.
    Internal = 2,

    // Внутренний между своими счетами.
    InternalSelf = 3,

    // Внутренний между своими счетами с разными валютами.
    InternalSelfExchange = 4,

    // СБП перевод.
    Sbp = 5,

    // Прямые переводы в сбербанк.
    Sberbank = 6,
  }

  export interface ResolveWithDataResponse {
    type: ResponseType.ResolveWithData
    providers: Provider[]
    /**
     *  Передавать в запросе на получение комиссии
     */
    commissionRequestId: string
    /**
     * Передавать в запросе на оплату
     */
    transferRequestId: string
    prepareRequestId: string
    /**
     * Передавать в запросе где требуется requestId
     */
    requestId: string
  }

  export interface Provider {
    /**
     * @example "sbp"
     */
    id: string
    phoneNumber: string
    /**
     * @example Владимир Ш. | Vladimir S
     */
    recipientName: null | string
    bankName: null | string
    cardType: null | string
    recipientAddress: null | string
    transactionProductFrom: TransactionProductFrom
    transactionProductTo?: TransactionProductFrom
    fee: number
    sum: number
    fullSum: number
    currencyType: CurrencyType
    transferType: string
    limit: Limit
    defaultBankId?: string | null
    banks: Bank[]
    /**
     * Custom props
     */
    displayOrder: number
    bgColor: string
    bgColorTheme: 'dark' | 'light'
    bankLogo: string
    isAvailableSendMessage: boolean
  }

  export interface Bank {
    id: string
    name: string
    isSbpAccountExist: boolean
    isDuplicate: boolean
  }

  interface Limit {
    minTransferLimit: number
    maxTransferLimit: number
  }

  interface TransactionProductFrom {
    maskCardNumber: string
    accountNumber: string
  }

  export interface RequestHeaders {
    _ver_: string
    _os_: string
  }
}
