import React from 'react'
import { ResponseType } from '@/models/ResponseType'
import { Button } from '@platform-ui/components/Button'
import { BeneficiaryDetailsField } from './BeneficiaryDetailsField'
import { BankDetailsShimmer, ErrorWrapper, ErrorDesc } from './styled'

export const BeneficiaryDetails = (props) => {
  const {
    beneficiaryDetails,
    handleBeneficiaryDetailsEvent,
    productsSelector,
    isPrepare,
    control,
    isDisabled,
    checkPayment,
  } = props

  if (beneficiaryDetails.isLoading) {
    return (
      <>
        <BankDetailsShimmer />
        <BankDetailsShimmer />
      </>
    )
  }

  if (beneficiaryDetails.isError) {
    return (
      <ErrorWrapper>
        <ErrorDesc>Не удалось загрузить список полей</ErrorDesc>
        <Button view={'ghost'} onClick={handleBeneficiaryDetailsEvent}>
          Повторить запрос
        </Button>
      </ErrorWrapper>
    )
  }

  if (
    beneficiaryDetails.isSuccess &&
    beneficiaryDetails.data?.data?.type === ResponseType.ResolveWithData
  ) {
    return (
      <>
        {beneficiaryDetails.data.data.fields.map(
          ({
            fieldKey,
            name,
            minSumValue,
            maxSumValue,
            hint,
            isReadOnly,
            type,
            allowedValue,
            mask,
            regexp,
          }) => {
            return (
              <BeneficiaryDetailsField
                name={fieldKey}
                control={control}
                placeholder={name}
                minSumValue={minSumValue}
                maxSumValue={maxSumValue}
                hint={hint}
                productsSelector={productsSelector}
                isReadOnly={isReadOnly}
                type={type}
                allowedValue={allowedValue}
                isPrepare={isPrepare}
                isDisabled={isDisabled || isReadOnly || isPrepare}
                mask={mask}
                checkPayment={checkPayment}
                regexp={regexp}
              />
            )
          }
        )}
      </>
    )
  }

  return null
}
