import { useQuery, UseQueryOptions } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { adapter } from './adapters/adapter'
import { PhoneProductListModel } from './PhoneProductListModel'
import { stringTemplate } from '@/utils/string-template'

export const phoneProductList: PhoneProductListModel.Func = async () => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.transfers.phoneProductList,
    {} as ApiManagerModel.ApiOption
  )

  return await HttpClient.get<
    PhoneProductListModel.ResponseData,
    void,
    PhoneProductListModel.RequestHeaders,
    PhoneProductListModel.ErrorResponse
  >({
    url: stringTemplate<{ provider: string }>(path, {
      provider: 'sbp',
    }),
    headers: null,
    responseAdapter: adapter,
    control,
  })
}

export function phoneProductListQueryKey() {
  return '/api/proxy/mic/card2phone/v1/product-list/'
}

export function usePhoneProductListQuery<
  T extends PhoneProductListModel.ResponseData = PhoneProductListModel.ResponseData
>(
  options?: Omit<
    UseQueryOptions<PhoneProductListModel.ResponseData, PhoneProductListModel.ErrorResponse, T>,
    'queryKey' | 'queryFn'
  >
) {
  const key = phoneProductListQueryKey()

  return useQuery(key, async () => phoneProductList().then((res) => res.data), options)
}
