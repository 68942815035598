import { HttpClientModel } from '@/core'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'
import { ResponseType } from '@/models/ResponseType'

/**
 * Docs
 * @see https://confluence.homecredit.ru/confluence/pages/viewpage.action?pageId=96567461
 */
export namespace BetweenSelfCheckModel {
  export type Func = (props: Props) => Promise<Response>
  export interface Props {
    requestId: string
    otp: string | null
  }
  export type Response = HttpClientModel.Response<ResponseData>
  export type ErrorResponse = HttpClientModel.ErrorResponse<ErrorResponseData>
  export type ErrorResponseData = RejectedWithSystemErrorResponse
  export type ResponseData = ResolveWithDataResponse
  export type RequestHeaders = null
  export interface Requestdata {
    otp: string
  }

  export enum ResultCode {
    Match = 'Match',
    Mismatch = 'Mismatch',
    Blocked = 'Blocked',
    Error = 'Error',
    Limit = 'Limit',
    Funds = 'Funds',
    NotFound = 'NotFound',
  }

  export enum Status {
    Success = 'Success',
    InProgress = 'InProgress',
    Canceled = 'Canceled',
    Queue = 'Queue',
    None = 'None',
  }

  export interface ResolveWithDataResponse {
    type: ResponseType.ResolveWithData
    result: Result
  }

  export type Result =
    | ResultMatchSuccess
    | ResultMatchInProgress
    | ResultMismatch
    | ResultBlocked
    | ResultError
    | ResultFundsCanceled
    | ResultLimit
    | ResultLimitCanceled
    | ResultNotFound

  export interface ResultMatchSuccess {
    resultCode: ResultCode.Match
    status: Status.Success
    /**
     * @example 313745373
     */
    transactionId: number
  }

  export interface ResultMatchInProgress {
    resultCode: ResultCode.Match
    status: Status.InProgress
    /**
     * @example 313745373
     */
    transactionId: number
  }

  export interface ResultMismatch {
    resultCode: ResultCode.Mismatch
    /**
     * @example 'СМС-код введен неверно. Попробуйте еще раз'
     */
    resultText: string
    resendNum: number
    verifyNum: number
    resend:
      | {
          isEnable: true
          requestId: string
        }
      | {
          isEnable: false
        }
    checkRequestId: string
    /**
     * Передавать в запросе где требуется requestId
     */
    requestId: string
  }

  /**
   * @see https://confluence.homecredit.ru/confluence/pages/viewpage.action?pageId=90283941#VPTAPIv1/{uid}/check-Response.1
   */
  export interface ResultBlocked {
    resultCode: ResultCode.Blocked
    /**
     * @example  'Превышено количество попыток ввода СМС-кода. В целях вашей безопасности совершение платежей и переводов будет доступно через 60 минут'
     */
    resultText: string
  }

  /**
   * @see https://confluence.homecredit.ru/confluence/pages/viewpage.action?pageId=90283941#VPTAPIv1/{uid}/check-Асинхронныйответ
   */
  export interface ResultError {
    resultCode: ResultCode.Error
    /**
     * @example  "Что-то пошло не так… Операция недоступна"
     */
    resultText: string
  }

  /**
   * @see https://confluence.homecredit.ru/confluence/pages/viewpage.action?pageId=90283941#VPTAPIv1/{uid}/check-ОбработкаresultCode=Limit
   */
  export interface ResultLimit {
    resultCode: ResultCode.Limit
    status: Status.None
    /**
     * @example  "Что-то пошло не так… Операция недоступна"
     */
    resultText: string
  }

  /**
   * @see https://confluence.homecredit.ru/confluence/pages/viewpage.action?pageId=90283941#VPTAPIv1/{uid}/check-Response.6
   */
  export interface ResultLimitCanceled {
    resultCode: ResultCode.Limit
    status: Status.Canceled
    /**
     * @example "Превышен суточный лимит на максимальную сумму переводов"
     */
    resultText: string
  }

  /**
   * @see https://confluence.homecredit.ru/confluence/pages/viewpage.action?pageId=90283941#VPTAPIv1/{uid}/check-VPT.Response.3
   */
  export interface ResultFundsCanceled {
    resultCode: ResultCode.Funds
    status: Status.Canceled
    /**
     * @example "Превышен суточный лимит на максимальную сумму переводов"
     */
    resultText: string
  }

  /**
   * Самодельный интерфейс
   */
  export interface ResultNotFound {
    resultCode: ResultCode.NotFound
    /**
     * @example "От сервера пришел не известный код ответа"
     */
    resultText: string
  }
}
