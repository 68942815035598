import { AdapterModel } from './AdapterModel'
import { ResponseType } from '@/models/ResponseType'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'
import { HttpClient } from '@/core'
import { VerificationModel } from '../VerificationModel'
import { currencyType } from '@/utils/adapter-helpers/currencyType'

const createResult = (successData: AdapterModel.ResultSuccess | AdapterModel.ResultNoValid) => {
  if (successData.IsOtpValid) {
    const data = successData as AdapterModel.ResultSuccess
    return {
      isOtpValid: data.IsOtpValid,
      amount: data.PaymentResult.Amount,
      paymentDate: data.PaymentResult.PaymentDate,
      currencyCode: currencyType(data.PaymentResult.CurrencyCode).toClient(),
      status: data.PaymentResult.Status,
      beneficiaryCode: data.PaymentResult.BeneficiaryCode,
      transactionId: data.PaymentResult.TransactionId,
      descriptionStatus: data.PaymentResult.DescriptionStatus,
      fromAccount: data.PaymentResult.FromAccount,
      categoryId: data.PaymentResult.CategoryId,
    } as VerificationModel.ResultSuccess
  }

  const data = successData as AdapterModel.ResultNoValid
  return {
    isOtpValid: false,
    isSmsResendAvailable: data?.IsSmsResendAvailable,
    text: 'СМС-код введен неверно. Попробуйте ещё раз',
  } as VerificationModel.ResultNoValid
}

export const adapter: AdapterModel.Func = (data) => {
  const errorData = data as AdapterModel.ResponseDataError
  /**
   * Сервер всегда возвращает status=200 в заголовке
   * Реальный статус приходит в теле ответа
   */
  if (errorData?.StatusCode >= 400) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: errorData.ErrorDetails
        ? errorData.ErrorDetails.map(({ Error, Title }) => ({
            type: Error,
            message: Title,
          }))
        : [],
    }
    throw HttpClient.createError(errorData?.StatusCode, exception)
  }
  try {
    const successData = data as AdapterModel.ResponseDataSuccess

    return {
      type: ResponseType.ResolveWithData,
      result: createResult(successData.Result),
    }
  } catch (error) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: [{ message: 'Ошибка при обработке полученных данных', type: 'ADAPTER_ERROR' }],
    }

    throw HttpClient.createError(500, exception)
  }
}
