import { ApiStatus } from '@/core'
import { ResponseType } from '@/models/ResponseType'
import { currency } from '@/utils/currency'
import { observer } from 'mobx-react-lite'
import { Control, useWatch } from 'react-hook-form'
import { FormValues, useFormState } from '../TransfersBetweenProductsForm/useFormState'
import { ButtonContainer } from './styled'
import { Button } from '@platform-ui/components/Button'

export const ButtonSum = observer<{
  control: Control<FormValues>
  productsSelector: ReturnType<typeof useFormState>['productsSelector']
  betweenSelfCommissionMutation: ReturnType<typeof useFormState>['betweenSelfCommissionMutation']
  betweenSelfTypeMutation: ReturnType<typeof useFormState>['betweenSelfTypeMutation']
  betweenSelfConfirmMutation: ReturnType<typeof useFormState>['betweenSelfConfirmMutation']
  betweenSelfCheckMutation: ReturnType<typeof useFormState>['betweenSelfCheckMutation']
  isDisabled?: boolean
}>(
  ({
    productsSelector,
    betweenSelfCommissionMutation,
    betweenSelfTypeMutation,
    betweenSelfCheckMutation,
    betweenSelfConfirmMutation,
    isDisabled,
    control,
    isPrepare,
  }) => {
    const sum = useWatch({ name: 'sum', control: control })

    if (productsSelector.status !== ApiStatus.Fulfilled) {
      return null
    }

    const text = isPrepare ? 'Перевести' : 'Продолжить'

    const isLoading =
      betweenSelfCommissionMutation.isLoading ||
      betweenSelfTypeMutation.isLoading ||
      betweenSelfConfirmMutation.isLoading ||
      betweenSelfCheckMutation.isLoading

    if (
      !sum ||
      betweenSelfCommissionMutation.data?.response?.type !== ResponseType.ResolveWithData ||
      !isPrepare
    ) {
      return (
        <ButtonContainer>
          <Button type="submit" isFullWidth isLoading={isLoading} isDisabled={isDisabled || !sum}>
            {text}
          </Button>
        </ButtonContainer>
      )
    }

    const { currencyType, fullSum, fee } = betweenSelfCommissionMutation.data.response

    return (
      <ButtonContainer>
        <Button
          type="submit"
          isFullWidth
          isLoading={isLoading}
          subtitle={
            fee === 0 ? 'Без комиссии' : `Включая комиссию ${currency(fee, currencyType).format()}`
          }
          isDisabled={isDisabled}
        >
          {`${text} ${currency(fullSum, currencyType).format()}`}
        </Button>
      </ButtonContainer>
    )
  }
)
