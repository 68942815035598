import { AdapterModel } from './AdapterModel'
import { ResponseType } from '@/models/ResponseType'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'
import { HttpClient } from '@/core'
import { PhoneModel } from '../PhoneModel'
import { currencyType } from '@/utils/adapter-helpers/currencyType'
import { colord } from 'colord'
import SberBankImage from '@/assets/banks/SberBank.png'
import HomeBankImage from '@/assets/banks/HomeBank.png'
import { color } from '@platform-ui/core'

export const adapter: AdapterModel.Func = (data, banksInfoData) => {
  const errorData = data as AdapterModel.PhoneResponseDataError
  /**
   * Сервер всегда возвращает status=200 в заголовке
   * Реальный статус приходит в теле ответа
   * 401 или 500+ считаем rejected ответами с наличием критической ошибки
   */
  if (errorData?.statusCode === 401 || errorData?.statusCode > 500) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: errorData.errorDetails
        ? errorData.errorDetails.map(({ error }) => ({
            type: error,
            message: error,
          }))
        : [],
    }
    throw HttpClient.createError(errorData?.statusCode, exception)
  }

  try {
    /**
     * Есть ошибки от сервера
     */
    if (
      errorData?.statusCode === 403 ||
      errorData?.statusCode === 500 ||
      (errorData.errorDetails && errorData.errorDetails.length)
    ) {
      return {
        type: ResponseType.ResolveWithSystemError,
        systemErrors: [
          {
            message: 'Неизвестная ошибка',
            type: 'INTERNAL_SERVER_ERROR',
          },
        ],
      }
    }

    const phoneSuccessData = data as AdapterModel.PhoneResponseDataSuccess
    const banksInfoSuccessData = banksInfoData as AdapterModel.BanksInfoResponseDataSuccess

    if (!phoneSuccessData.provider) {
      return {
        type: ResponseType.ResolveWithSystemError,
        systemErrors: [
          {
            message:
              'Мы не нашли ни одной карты, привязанной к этому номеру телефона. Получателю необходимо обратиться в свой банк для привязки карты.',
            type: 'ERROR_NO_RECIPIENT_CARDS',
          },
        ],
      }
    }

    const providerByCardNumber =
      banksInfoSuccessData && banksInfoSuccessData.Result
        ? banksInfoSuccessData.Result.reduce<Record<string, AdapterModel.BanksInfoBank>>(
            (acc, curr) => {
              acc[curr.MaskCardNumber] = curr
              return acc
            },
            {}
          )
        : {}

    const providers = filterByProviderCards(
      phoneSuccessData.provider.map((provider) => {
        let bgColor: PhoneModel.Provider['bgColor'] = `linear-gradient(360deg, #787C9C 0%, #8C93AB 100%)`
        let bgColorTheme: PhoneModel.Provider['bgColorTheme'] = 'dark'
        let bankLogo = ''

        // toLowerCase взято со старого бека
        const id = provider.providerId.toLowerCase()

        const transactionProductTo: PhoneModel.Provider['transactionProductTo'] =
          provider.transactionProductTo
            ? {
                ...provider.transactionProductTo,
                maskCardNumber: provider.transactionProductTo.maskCardNumber.replaceAll(' ', ''),
              }
            : null

        const transactionProductFrom: PhoneModel.Provider['transactionProductFrom'] = {
          ...provider.transactionProductFrom,
          maskCardNumber: provider.transactionProductFrom.maskCardNumber.replaceAll(' ', ''),
        }

        if (id === 'sberbank') {
          bgColorTheme = 'dark'
          bgColor = color['banks/sberbank']
          bankLogo = SberBankImage
        } else if (
          transactionProductTo &&
          providerByCardNumber[transactionProductTo.maskCardNumber]
        ) {
          const bankInfo = providerByCardNumber[transactionProductTo.maskCardNumber]

          if (bankInfo.CardColor) {
            const backgroundColor = colord(`#${bankInfo.CardColor}`)
            const isDarkenBgColor = backgroundColor.brightness() <= 0.5
            const gradientColor0 = isDarkenBgColor
              ? backgroundColor.lighten(0.08).toRgbString()
              : backgroundColor.lighten(0.03).toRgbString()
            const gradientColor1 = backgroundColor.toHex()
            bgColor = `linear-gradient(180deg, ${gradientColor0} 0%, ${gradientColor1} 90px, ${gradientColor1} 100%)`
            bgColorTheme = isDarkenBgColor ? 'dark' : 'light'
            bankLogo = bankInfo.BankLogo
          }
        }

        if (id === 'hcfb' && !bankLogo) {
          bgColorTheme = 'light'
          bgColor = '#F53D5B'
          bankLogo = HomeBankImage
        }

        return {
          bankName: provider.bankName,
          banks: (provider.banks || []).map<PhoneModel.Bank>((bank) => ({
            id: bank.bankId,
            isDuplicate: bank.duplicate,
            isSbpAccountExist: bank.sbpAccountExist,
            name: bank.bankName,
          })),
          cardType: provider.cardType,
          currencyType: currencyType(provider.currency).toClient(),
          fee: provider.fee,
          fullSum: provider.fullSum,
          limit: provider.limit,
          phoneNumber: provider.alias,
          id: id,
          recipientAddress: provider.recipientAddress,
          recipientName: provider.recipientName,
          sum: provider.sum,
          transactionProductFrom: transactionProductFrom,
          transferType: provider.transferType,
          defaultBankId: provider.defaultBankId,
          transactionProductTo: transactionProductTo,
          displayOrder: 1,
          bgColor: bgColor,
          bgColorTheme: bgColorTheme,
          bankLogo: bankLogo,
          isAvailableSendMessage: id === 'hcfb',
        }
      })
    )

    /**
     * Ссылка имеет вид
     * https://balancer-gateway-rp.omni.homecredit.ru:8112/transfer/v1/self/transfer/0a787836-1e51-4670-833b-3152df7f0081/commission
     *
     * нужно вытащить guid - это наш session (request) id
     */
    const href = phoneSuccessData.links.find((item) => item.rel === 'commission').href
    const hrefArr = href.split('/')
    const requestId = hrefArr[hrefArr.length - 2]

    /**
     * Бекенд отдает ссылки, которые требуется вставлять в запрос для проксирования внутри банка
     */
    const { commissionRequestId, transferRequestId, prepareRequestId } =
      phoneSuccessData.links.reduce<{
        commissionRequestId: string
        transferRequestId: string
        prepareRequestId: string
      }>(
        (acc, curr) => {
          if (curr.rel === 'commission') {
            acc.commissionRequestId = curr.href
            return acc
          }
          if (curr.rel === 'transfer') {
            acc.transferRequestId = curr.href
            return acc
          }
          if (curr.rel === 'prepare') {
            acc.prepareRequestId = curr.href
            return acc
          }
          return acc
        },
        {
          commissionRequestId: '',
          transferRequestId: '',
          prepareRequestId: '',
        }
      )

    return {
      type: ResponseType.ResolveWithData,
      providers: providers,
      commissionRequestId: commissionRequestId,
      transferRequestId: transferRequestId,
      prepareRequestId: prepareRequestId,
      requestId: requestId,
    }
  } catch (error) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: [{ message: 'Ошибка при обработке полученных данных', type: 'ADAPTER_ERROR' }],
    }

    throw HttpClient.createError(500, exception, error)
  }
}

/**
 * ВАЖНО
 * Код скопирован из старого проекта с небольшими доработками
 *
 * Файл:
 * /Web/Web/HCFB.Server.Web.MyCreditWeb/Client/public/app/main/payments/scenarios/transfers/byPhoneNumber/byPhoneNumber.js
 */
function filterByProviderCards(cards: PhoneModel.Provider[]) {
  return cards
    .map((card) => {
      card.displayOrder = card.id == 'hcfb' ? 1 : card.id == 'visa' ? 2 : 3

      return card
    })
    .sort((a, b) => a.displayOrder - b.displayOrder)
}
