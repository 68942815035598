import { ClientProductsModel } from '@/api/products/clientProducts'
import { statusToApiStatusEnum } from '@/utils/statusToApiStatusEnum'
import { UseQueryResult } from 'react-query'
import { createSelector } from 'reselect'

export interface ClientProductsAllSelectorState {
  clientProducts: UseQueryResult<
    ClientProductsModel.ResponseData,
    ClientProductsModel.ErrorResponse
  >
}
export const clientProductsAllSelector = createSelector(
  (state: ClientProductsAllSelectorState) => state.clientProducts.data,
  (state: ClientProductsAllSelectorState) => state.clientProducts.status,
  (data, status) => ({
    data: data,
    status: statusToApiStatusEnum(status),
    refetchKey: 'clientProducts',
  })
)
