import React from 'react'
import { ChevronDownIcon } from '@platform-ui/icons/ChevronDownIcon'
import { CircularProgress } from '@platform-ui/components/CircularProgress'
import { SuccessIcon } from '@platform-ui/icons/SuccessIcon'
import { useBankIcon } from '@/features/transfers-by-phone-number/hooks/useBankIcon'
import {
  InputStyled,
  PrepareSuccessInput,
  BankLogo,
  IconShimmer,
  Icon,
  SbpIconWrapper,
} from './styled'
import { useFormState } from '@/features/transfers-by-phone-number/containers/TransfersByPhoneNumberForm/useFormState'
import { SbpIcon } from '../../assets/SbpIcon'

export interface PaymentRecipientProps {
  name: string
  providerBankIcon: string
  bankId: string
  providerId: string
  isOpen: boolean
  isPrepare: boolean
  isDisabled: boolean
  isLoading: boolean
  status: 'error' | 'default'
  bankName: string
  phoneGetSbpLogoMutation: ReturnType<typeof useFormState>['phoneGetSbpLogoMutation']
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  recipientName: string
}

export const PaymentRecipient = (props: PaymentRecipientProps) => {
  const {
    name,
    providerBankIcon,
    bankId,
    providerId,
    isOpen,
    isPrepare,
    isDisabled,
    isLoading,
    status,
    bankName = '',
    phoneGetSbpLogoMutation,
    recipientName,
    onClick,
  } = props

  const rightSlot =
    (isLoading && <CircularProgress size={24} />) || (!isDisabled && <ChevronDownIcon />)

  const { bankIcon } = useBankIcon({ bankId, phoneGetSbpLogoMutation })

  if (isPrepare) {
    return (
      <>
        <Icon>
          {(providerBankIcon || bankIcon) && (
            <BankLogo src={providerBankIcon || bankIcon} height={40} width={40} />
          )}
          {providerId === 'sbp' && (
            <SbpIconWrapper>
              <SbpIcon />
            </SbpIconWrapper>
          )}
        </Icon>

        <PrepareSuccessInput
          name=""
          isReadOnly
          isBlock
          placeholder={recipientName}
          value={bankName}
          rightSlot={<SuccessIcon color="icons/colors/success" />}
        />
      </>
    )
  }

  return (
    <>
      {bankName &&
        (providerBankIcon || bankIcon ? (
          <BankLogo src={providerBankIcon || bankIcon} height={24} width={24} />
        ) : (
          <IconShimmer />
        ))}

      <InputStyled
        name={name}
        isOpen={isOpen}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isReadOnly
        isBlock
        placeholder="Банк получателя"
        value={bankName}
        onClick={onClick}
        status={status}
        rightSlot={rightSlot}
      />
    </>
  )
}
