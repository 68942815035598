import { MutateOptions, useMutation } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { PhoneModel } from './PhoneModel'
import { adapter } from './adapters/adapter'
import { currencyType } from '@/utils/adapter-helpers/currencyType'
import { AdapterModel } from './adapters/AdapterModel'

export const phone: PhoneModel.Func = async (props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.transfers.phone,
    {} as ApiManagerModel.ApiOption
  )

  /**
   * + нужно обрезать, иначе не работает
   */
  if (props.phoneNumber.substring(0, 1) === '+') {
    props.phoneNumber = props.phoneNumber.substring(1)
  }

  const provider = props.transferType
    ? {
        provider: PhoneModel.TransferType[props.transferType].toLowerCase(),
      }
    : {}

  const phoneResponse = await HttpClient.post<
    AdapterModel.PhoneResponseData,
    void,
    PhoneModel.Requestdata,
    PhoneModel.RequestHeaders,
    AdapterModel.PhoneResponseDataError
  >({
    url: path,
    headers: {
      _ver_: props.apiVersion,
      _os_: '4',
    },
    data: {
      alias: props.phoneNumber,
      currency: currencyType(props.currencyType).toServer(),
      ...provider,
      sum: 0,
      transactionProductFrom:
        props.transactionProductFrom.productType === 'card'
          ? {
              accountNumber: props.transactionProductFrom.accountNumber,
              maskCardNumber: props.transactionProductFrom.maskCardNumber,
            }
          : {
              accountNumber: props.transactionProductFrom.accountNumber,
            },
    },
    control,
  })

  return {
    config: phoneResponse.config,
    response: adapter(phoneResponse.data),
    headers: phoneResponse.headers,
    status: phoneResponse.status,
  }
}

export function usePhoneMutation(
  options?: MutateOptions<PhoneModel.Response, PhoneModel.ErrorResponse, PhoneModel.Props>
) {
  return useMutation(phone, options)
}
