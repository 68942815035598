import { AdapterModel } from './AdapterModel'
import { ResponseType } from '@/models/ResponseType'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'
import { HttpClient } from '@/core'

export const adapter: AdapterModel.Func = (data, _, status) => {
  /**
   * В частных случаях сервер возвращает статус в заголовке
   * заголовок приходит только если status не валиден
   * @see isValidStatus в HttpClient
   */
  if (status) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: [
        {
          message: 'Произошла ошибка на стороне сервера',
          type: 'UNHANDLED',
        },
      ],
    }
    throw HttpClient.createError(status, exception)
  }

  const errorData = data as AdapterModel.ResponseDataError
  /**
   * Сервер всегда возвращает status=200 в заголовке
   * Реальный статус приходит в теле ответа
   */
  if (errorData?.StatusCode >= 400) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors:
        errorData.ErrorDetails?.map(({ Error, Title }) => ({
          type: Error,
          message: Title,
        })) || [],
    }
    throw HttpClient.createError(errorData?.StatusCode, exception)
  }

  const resultCodeError = data as AdapterModel.ResultCodeError

  if (resultCodeError?.resultCode && resultCodeError?.resultText) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: [
        {
          message: resultCodeError.resultText,
          type: resultCodeError.resultCode,
        },
      ],
    }
    throw HttpClient.createError(500, exception)
  }

  try {
    const successData = data as AdapterModel.ResponseDataSuccess

    /**
     * Бекенд отдает ссылки, которые требуется вставлять в запрос для проксирования внутри банка
     */
    const { checkRequestId, transferRequestId, resendRequestId } = successData.links.reduce<{
      checkRequestId: string
      transferRequestId: string
      resendRequestId: string
    }>(
      (acc, curr) => {
        if (curr.rel === 'check') {
          acc.checkRequestId = curr.href
          return acc
        }
        if (curr.rel === 'transfer') {
          acc.transferRequestId = curr.href
          return acc
        }
        if (curr.rel === 'resend') {
          acc.resendRequestId = curr.href
          return acc
        }
        return acc
      },
      {
        checkRequestId: '',
        transferRequestId: '',
        resendRequestId: '',
      }
    )

    return {
      type: ResponseType.ResolveWithData,
      checkRequestId,
      phoneNumber: successData.phone,
      resendRequestId,
      smsCheck: successData.smsCheck,
      transferRequestId,
    }
  } catch (error) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: [{ message: 'Ошибка при обработке полученных данных', type: 'ADAPTER_ERROR' }],
    }

    throw HttpClient.createError(500, exception, error)
  }
}
