import React from 'react'

export const SbpIconDisabled = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.25" y="0.25" width="23.5" height="23.5" rx="11.75" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.24023 7.93555L7.99013 11.0528L7.99022 12.9535L6.24312 16.0646L6.24023 7.93555Z"
      fill="#BCC0CC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.957 9.91809L14.5961 8.91658L17.95 8.91406L12.957 11.9636V9.91809Z"
      fill="#BCC0CC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9485 7.91733L12.9576 12.0437L11.2041 10.97V4.80078L12.9485 7.91733Z"
      fill="#BCC0CC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.9504 8.91407L14.5965 8.91659L12.9484 7.91733L11.2041 4.80078L17.9504 8.91407Z"
      fill="#BCC0CC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9572 16.0812V14.0788L11.2041 13.0254L11.2044 19.2001L12.9572 16.0812Z"
      fill="#BCC0CC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.592 15.0897L7.99013 11.0528L6.24023 7.93555L17.9434 15.0858L14.592 15.0897Z"
      fill="#BCC0CC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2041 19.201L12.957 16.0821L14.592 15.0898L17.9433 15.0859L11.2041 19.201Z"
      fill="#BCC0CC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.24316 16.0651L11.2177 13.0268L9.54485 12.0039L7.99018 12.9538L6.24316 16.0651Z"
      fill="#BCC0CC"
    />
    <rect
      x="0.25"
      y="0.25"
      width="23.5"
      height="23.5"
      rx="11.75"
      stroke="#BCC0CC"
      strokeWidth="0.5"
    />
  </svg>
)
