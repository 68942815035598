import styled from '@emotion/styled'
import { CommissionField } from '@/components/CommissionField'
import { FieldControl } from '@platform-ui/components/FieldControl'
import { Body14 } from '@platform-ui/typography'

export const Root = styled.div(({ theme }) => ({
  margin: '24px 0',

  [`${theme.media.md}`]: {
    margin: '32px 0',
  },
}))

export const FieldControlCustom = styled(FieldControl)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
}))

export const ButtonGroupMain = styled.div(({ theme }) => ({
  margin: '16px 0',

  [`${theme.media.md}`]: {
    margin: '16px -32px 8px 0',
    overflowX: 'auto',
    WebkitOverflowScrolling: 'touch',
    maxWidth: 600,
    whiteSpace: 'nowrap',

    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
}))

export const ButtonGroupItem = styled.div(({ theme }) => ({
  display: 'inline-block',
  marginRight: 8,
  marginBottom: 12,

  '& > button': {
    borderRadius: 32,
  },

  [`${theme.media.md}`]: {
    marginBottom: 0,
  },
}))

export const LoaderBlock = styled.div(() => ({ padding: '16px 16px 16px', zIndex: 1 }))

export const CommissionFieldStyled = styled(CommissionField)<{ isReadOnly: boolean }>(
  ({ theme, isDisabled, isReadOnly }) => {
    return {
      input: {
        background: isDisabled && theme.color['surface/tertiary'],
        color: isReadOnly && theme.color['text/secondary'],
      },
      span: {
        color: isDisabled && '#BCC0CC',
      },
    }
  }
)

export const RootInnerBlock = styled.div(() => ({
  padding: '8px 16px',
  zIndex: 1,
}))

export const Title = styled.div(() => ({ display: 'flex', justifyContent: 'space-between' }))

export const Additional = styled(Body14)(() => ({
  marginTop: '8px',
}))

export const Term = styled.span(({ theme }) => ({
  color: theme.color['text/secondary'],
}))
