import { MutateOptions, useMutation } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { PhoneCommissionModel } from './PhoneCommissionModel'
import { adapter } from './adapters/adapter'
import { currencyType } from '@/utils/adapter-helpers/currencyType'
import { stringTemplate } from '@/utils/string-template'

export const phoneCommission: PhoneCommissionModel.Func = async (props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.transfers.phoneCommission,
    {} as ApiManagerModel.ApiOption
  )

  const response = await HttpClient.post<
    PhoneCommissionModel.ResponseData,
    void,
    PhoneCommissionModel.Requestdata,
    PhoneCommissionModel.RequestHeaders,
    PhoneCommissionModel.ErrorResponse
  >({
    url: stringTemplate<{ requestId: string }>(path, { requestId: props.requestId }),
    headers: null,
    responseAdapter: adapter,
    data: {
      currency: currencyType(props.currencyType).toServer(),
      sum: `${props.sum}`,
      transferType: props.transferType,
    },
    control,
  })

  return response
}

export function usePhoneCommissionMutation(
  options?: MutateOptions<
    PhoneCommissionModel.Response,
    PhoneCommissionModel.ErrorResponse,
    PhoneCommissionModel.Props
  >
) {
  return useMutation(phoneCommission, options)
}
