import React from 'react'
import {
  TransfersByPhoneNumberForm,
  TransfersByPhoneNumberFormProps,
} from './containers/TransfersByPhoneNumberForm'

export interface TransfersByPhoneNumberProps extends TransfersByPhoneNumberFormProps {}

export const TransfersByPhoneNumber = (props: TransfersByPhoneNumberProps) => {
  return <TransfersByPhoneNumberForm {...props} />
}
