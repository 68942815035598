import styled from '@emotion/styled'
import { Headline16 } from '@platform-ui/typography'

export const GroupFieldTitle = styled(Headline16)(({ theme }) => ({
  marginBottom: 16,

  ':not(:first-of-type)': {
    marginTop: 24,
    [`${theme.media.md}`]: {
      marginTop: 32,
    },
  },
}))
