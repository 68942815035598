import { useEffect } from 'react'
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form'
import { ApiStatus, useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { useEnhancedEffect, useEvent } from '@platform-ui/utils'
import { debounce } from 'debounce'
import { ResponseType } from '@/models/ResponseType'
import { EventPaymentSuccessModalModel } from '@/event-bus/events/PaymentSuccessModal'

import { useValidationResolver } from '@/utils/validation-resolver'
import { validationSchema } from './validationSchema'
import { useClientCardsQuery } from '@/api/pms/clientCards'
import { useProductsSelector } from '../../hooks/useProductsSelector'
import { useBeneficiaryDetailsMutation } from '@/api/pms/beneficiaryDetails'
import { useCheckPaymentMutation } from '@/api/pms/checkPayment'
import { useCreatePaymentMutation } from '@/api/pms/createPayment'
import { useVerificationMutation, VerificationModel } from '@/api/pms/verification'
import { useResendingMutation } from '@/api/pms/resending'

export interface FormValues {
  fromProductId: string
  sum: string
}

export function useFormState(
  beneficiaryId: number,
  selectedBeneficiary: { icon: string; name: string }
) {
  const context = useAppContext<AppContext>()

  const clientCards = useClientCardsQuery(
    {
      apiVersion: context.config.apiVersion,
      beneficiaryId: beneficiaryId,
    },
    {
      enabled: !!beneficiaryId,
    }
  )

  const productsSelector = useProductsSelector(clientCards)
  const beneficiaryDetails = useBeneficiaryDetailsMutation()
  const checkPayment = useCheckPaymentMutation()
  const createPayment = useCreatePaymentMutation()
  const verification = useVerificationMutation()
  const resending = useResendingMutation()

  const resolver = useValidationResolver<FormValues>(
    validationSchema({ productsSelector, beneficiaryDetails })
  )

  const form = useForm<FormValues>({
    resolver: resolver,
    mode: 'onBlur',
  })

  const { reset, watch, getValues, setValue } = form

  /**
   * Обновление бенефициара
   *
   */
  const handleBeneficiaryDetailsEvent = useEvent((formValues) => {
    /**
     * С бэка приходят разные поля в зависимости от платежа
     * При изменении бенефициара нужно сбросить все поля, кроме бенефициара и fromProduct
     */
    Object.keys(formValues)
      .filter((fieldId) => fieldId !== 'beneficiaryId' && fieldId !== 'fromProductId')
      .map((fieldId) => {
        setValue(fieldId, '')
        return
      })

    try {
      beneficiaryDetails
        .mutateAsync({
          beneficiaryId,
          action: 'start',
          apiVersion: context.config.apiVersion,
        })
        .then(({ data }) => {
          if (data.type === ResponseType.ResolveWithData) {
            data.fields.map((i) => {
              if (i.defaultValue) {
                setValue(i.fieldKey, i.defaultValue)
              }
              return
            })
          }
        })
    } catch (error) {
      console.log('error', error)
    }
  })

  useEnhancedEffect(() => {
    const handleBeneficiaryDetailsEventDebounce = debounce(handleBeneficiaryDetailsEvent, 400)

    const subscription = watch((formValues, state) => {
      if (state.name === 'beneficiaryId') {
        handleBeneficiaryDetailsEventDebounce(formValues)
      }
    })
    return () => {
      handleBeneficiaryDetailsEventDebounce.clear()
      subscription.unsubscribe()
    }
  }, [watch, handleBeneficiaryDetailsEvent])

  useEffect(() => {
    if (productsSelector.status !== ApiStatus.Fulfilled) {
      return
    }

    const { fromProductIds } = productsSelector

    let [fromProductId] = getValues(['fromProductId'])

    if (!fromProductId) {
      fromProductId = fromProductIds.some((id) => id === fromProductId)
        ? fromProductId
        : fromProductIds[0]

      if (!fromProductId) {
        return
      }

      setValue('fromProductId', fromProductId, { shouldValidate: true })
    }
  }, [getValues, setValue, productsSelector])

  const resetFormEvent = useEvent(() => {
    clientCards.refetch()
    beneficiaryDetails.reset()
    checkPayment.reset()
    createPayment.reset()
    verification.reset()
    resending.reset()

    reset({
      fromProductId: null,
      sum: '',
    })
  })

  useEffect(() => {
    return () => {
      /**
       * Очистка формы после ухода со страницы
       */
      resetFormEvent()
    }
  }, [resetFormEvent])

  const handleValidSubmit: SubmitHandler<FormValues> = async (formValues) => {
    if (productsSelector.status !== ApiStatus.Fulfilled) {
      return null
    }

    /**
     * В новом объекте удаляем пустые ключи (могут остаться при смене бенефициара), beneficiaryId и fromProductId
     * Оставляем заполненные поля из beneficiaryDetails
     */
    let newFields = Object.fromEntries(
      Object.entries(formValues).filter(
        (n) => !!n[1] && n[0] !== 'beneficiaryId' && n[0] !== 'fromProductId'
      )
    )

    const fromProduct = productsSelector.products[formValues.fromProductId]

    const fieldValues = Object.keys(newFields).length !== 0 ? newFields : {}

    /**
     * Шаг 2, создаём платёж
     */
    if (
      checkPayment.data?.response?.type === ResponseType.ResolveWithData &&
      checkPayment.data.response.paymentId
    ) {
      try {
        await createPayment.mutateAsync({
          beneficiaryId,
          paymentId: checkPayment.data.response.paymentId,
          amount: formValues.amount,
          accountNumber: fromProduct.accountNumber,
          currencyType: fromProduct.currencyType,
          processPaymentType: beneficiaryDetails.data.data.processPaymentType,
          fields: {
            ...fieldValues,
          },
        })
      } catch (error) {
        context.toastify.error({
          title: error.data.systemErrors[0].message,
          description: error.data.systemErrors[0].type,
        })
      }

      return null
    }

    /**
     * Шаг 1, заполнены все поля
     * Если isFinalStep = false, то нужно вызвать beneficiaryDetails ещё раз и подгрузить новые поля
     */
    if (beneficiaryDetails.data.data.isFinalStep) {
      try {
        await checkPayment
          .mutateAsync({
            beneficiaryId,
            amount: formValues.amount,
            maskedCardNumber: fromProduct.maskedCardNumber,
            accountNumber: fromProduct.accountNumber,
            productSubType: fromProduct.productSubType,
          })
          .then(({ data }) => {
            if (data.type === ResponseType.ResolveWithData && data?.description) {
              context.toastify.error({ title: data.description })
            }
          })
      } catch (error) {
        context.toastify.error({
          title: error.data.systemErrors[0].message,
          description: error.data.systemErrors[0].type,
        })
      }
      return null
    }

    if (!beneficiaryDetails.data.data.paymentHubSession) {
      return null
    }

    try {
      beneficiaryDetails
        .mutateAsync({
          beneficiaryId,
          action: 'next',
          fields: {
            ...fieldValues,
          },
          paymentHubSession: beneficiaryDetails.data.data.paymentHubSession,
        })
        .then(({ data }) => {
          if (data.type === ResponseType.ResolveWithData) {
            /**
             * Очень важная строка, без неё в /create-payment отправим лишнии поля и далее не пройдёт /verification
             * После каждого запроса удаляем предыдущие поля (кроме amount).
             * Таким образом в formValues оставим поля amount и поля из beneficiaryDetails с isFinalStep = true
             */
            Object.keys(fieldValues).forEach((n) => n !== 'amount' && delete formValues[n])

            data.fields.map((i) => {
              if (i.defaultValue) {
                setValue(i.fieldKey, i.defaultValue)
              }
              if (i?.errorText) {
                context.toastify.error({ title: i.errorText })
              }
              return
            })
          }
        })
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleInvalidSubmit: SubmitErrorHandler<FormValues> = async (errors) => {
    if (errors) {
      const errorsValues = Object.keys(errors)
      if (errorsValues.length > 0) {
        let firstErrorElement = document.getElementsByName(errorsValues[0])[0]
        if (firstErrorElement)
          firstErrorElement.scrollIntoView({ behavior: `smooth`, block: 'center' })
      }
    }
  }

  const handlePhoneCheckEvent = useEvent((smsCode: string) => {
    let [fromProductId] = getValues(['fromProductId'])
    const fromProduct = productsSelector.products[fromProductId]

    verification
      .mutateAsync({
        smsCode,
        paymentId: checkPayment.data.response.paymentId,
        paymentHubSession: beneficiaryDetails.data.data.paymentHubSession,
        apiVersion: context.config.apiVersion,
      })
      .then(({ data }) => {
        const { type, result } = data as VerificationModel.ResolveWithDataResponse
        if (type === ResponseType.ResolveWithData && result.isOtpValid) {
          const successResult = result as VerificationModel.ResultSuccess

          context.eventBus.transfersModal.close.emit()
          context.eventBus.paymentSuccessModal.open.emit({
            amount: successResult.amount,
            currency: successResult.currencyCode,
            from: {
              transferType: EventPaymentSuccessModalModel.FromTransferType.Card,
              id: fromProduct.id,
              cardNumber: fromProduct.maskedCardNumber,
              accountNumber: fromProduct.accountNumber,
              name: fromProduct.productName,
            },
            to: {
              transferType: EventPaymentSuccessModalModel.ToTransferType.Payments,
              beneficiaryName: selectedBeneficiary.name,
              icon: selectedBeneficiary.icon,
            },
            id: `${successResult.transactionId}`,
          })
        }
      })
      .catch((error) => {
        if (error.status >= 400) {
          context.toastify.error({
            title: error.data.systemErrors[0].message,
            description: error.data.systemErrors[0].type,
          })
        }
      })
    return
  })

  const handleReSendCodeEvent = useEvent(() => {
    resending.mutateAsync().catch((error) => {
      if (error.status >= 400) {
        context.toastify.error({
          title: error.data.systemErrors[0].message,
          description: error.data.systemErrors[0].type,
        })
      }
    })
    return
  })

  return {
    form,
    handleValidSubmit,
    handleInvalidSubmit,
    productsSelector,
    beneficiaryDetails,
    handleBeneficiaryDetailsEvent,
    checkPayment,
    createPayment,
    handlePhoneCheckEvent,
    verification,
    handleReSendCodeEvent,
    resending,
  }
}
