import styled from '@emotion/styled'

export const GroupFieldTitle = styled.h2<{ isFrom?: boolean }>(({ theme, isFrom }) => ({
  ...theme.typography['headline/16'],
  color: theme.color['text/main'],
  margin: `24px 0 16px 0`,
  padding: '0 12px',

  [`${theme.media.md}`]: {
    marginTop: isFrom ? 16 : 32,
    marginBottom: 16,
  },
}))
