import styled from '@emotion/styled'
import { Button } from '@platform-ui/components/Button'
import { FieldControl } from '@platform-ui/components/FieldControl'
import { Shimmer } from '@platform-ui/components/Shimmer'

export const ButtonCustom = styled(Button)(() => ({
  marginTop: '32px',
}))

export const FormContainer = styled.form(({ theme }) => ({
  height: '85vh',

  [`${theme.media.md}`]: {
    height: 'auto',
  },
}))

export const ErrorContainer = styled.div(({ theme }) => {
  return {
    maxHeight: 624,

    [theme.media.md]: {
      height: '100vh',
    },
  }
})

export const FieldControlCustom = styled(FieldControl)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  marginBottom: '16px',
}))

export const SelectProductsShimmer = styled(Shimmer)(() => ({
  height: '88px',
  width: '100%',
  borderRadius: '20px',
}))

export const LoadingProductsShimmer = styled(Shimmer)(() => ({
  height: '64px',
  width: '100%',
  borderRadius: '12px',
  marginBottom: '16px',
}))

export const LoadingTitleShimmer = styled(Shimmer)<{ isFrom?: boolean }>(({ isFrom, theme }) => ({
  height: '24px',
  width: isFrom ? '50px' : '70px',
  borderRadius: '12px',
  margin: `24px 0 16px 0`,

  [`${theme.media.md}`]: {
    marginTop: isFrom ? 16 : 32,
    marginBottom: 16,
  },
}))

export const LoadingButtonShimmer = styled(Shimmer)(({ theme }) => ({
  height: '56px',
  width: '100%',
  borderRadius: '12px',
  marginTop: 'auto',

  [theme.media.md]: {
    marginTop: '68px',
  },
}))
