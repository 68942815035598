import React, { MouseEventHandler } from 'react'
import { Root, Content, RetryButton } from './styled'

export interface CardErrorProps {
  className?: string
  retryClick?: MouseEventHandler<HTMLButtonElement>
  message: string | React.ReactNode | React.ReactNode[]
}

export const CardError = (props: CardErrorProps) => {
  const { className, retryClick, message = 'Не удалось загрузить список счетов' } = props

  return (
    <Root className={className}>
      <Content>{message}</Content>
      {retryClick && (
        <RetryButton view="ghost" onClick={retryClick} sizeSm={'m'} sizeMd={'l'} isFullWidth>
          Повторить
        </RetryButton>
      )}
    </Root>
  )
}
