import styled from '@emotion/styled'
import { StepperSum } from '@/components/StepperSum'

export const Root = styled(StepperSum)<{ isSoldOutCredit: boolean }>(
  ({ theme, isSoldOutCredit }) => ({
    [`${theme.media.md}`]: {
      marginBottom: isSoldOutCredit ? 32 : 100,
    },
  })
)
