import { ProductsModel } from '@/api/products/products'
import { statusToApiStatusEnum } from '@/utils/statusToApiStatusEnum'
import { UseQueryResult } from 'react-query'
import { createSelector } from 'reselect'

export interface ProductsAllSelectorState {
  products: UseQueryResult<ProductsModel.ResponseData, ProductsModel.ErrorResponse>
}
export const productsAllSelector = createSelector(
  (state: ProductsAllSelectorState) => state.products.data,
  (state: ProductsAllSelectorState) => state.products.status,
  (data, status) => ({
    data,
    status: statusToApiStatusEnum(status),
    refetchKey: 'products',
  })
)
