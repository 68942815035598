import { AdapterModel } from './AdapterModel'
import { ResponseType } from '@/models/ResponseType'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'
import { HttpClient } from '@/core'
import { BetweenSelfProductToListModel } from '../BetweenSelfProductToListModel'
import { currencyType } from '@/utils/adapter-helpers/currencyType'
import { createAccountId } from '@/utils/adapter-helpers/createAccountId'
import { createCreditCardId } from '@/utils/adapter-helpers/createCreditCardId'
import { createDebitCardId } from '@/utils/adapter-helpers/createDebitCardId'
import { createCreditLoanId } from '@/utils/adapter-helpers/createCreditLoanId'

const AVAILABLE_TYPES = new Set([
  BetweenSelfProductToListModel.ProductType.Account,
  BetweenSelfProductToListModel.ProductType.Credit,
  BetweenSelfProductToListModel.ProductType.Debit,
  BetweenSelfProductToListModel.ProductType.Loan,
])

export const adapter: AdapterModel.Func = (data) => {
  const errorData = data as AdapterModel.ResponseDataError

  /**
   * Сервер всегда возвращает status=200 в заголовке
   * Реальный статус приходит в теле ответа
   */
  if (errorData?.StatusCode >= 400) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors:
        errorData.ErrorDetails?.map(({ Error, Title }) => ({
          type: Error,
          message: Title,
        })) || [],
    }
    throw HttpClient.createError(errorData?.StatusCode, exception)
  }

  try {
    const successData = data as AdapterModel.ResponseDataSuccess

    return {
      type: ResponseType.ResolveWithData,
      productTo: prepareProducts(successData.productTo),
    }
  } catch (error) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: [{ message: 'Ошибка при обработке полученных данных', type: 'ADAPTER_ERROR' }],
    }

    throw HttpClient.createError(500, exception, error)
  }
}

function prepareProducts(products: AdapterModel.ResponseDataSuccess['productTo']) {
  return products
    .filter((item) => AVAILABLE_TYPES.has(item.type))
    .map<BetweenSelfProductToListModel.ProductTo>((product) => {
      if (product.type === BetweenSelfProductToListModel.ProductType.Account) {
        return {
          type: BetweenSelfProductToListModel.ProductType.Account,
          accountNumber: product.accountNumber,
          availableBalance: product.availableBalance,
          currencyType: currencyType(product.currency).toClient(),
          id: createAccountId(product.accountNumber),
        }
      }
      if (product.type === BetweenSelfProductToListModel.ProductType.Credit) {
        return {
          type: BetweenSelfProductToListModel.ProductType.Credit,
          accountNumber: product.accountNumber,
          availableBalance: product.availableBalance,
          currencyType: currencyType(product.currency).toClient(),
          maskCardNumber: product.maskCardNumber.replaceAll(' ', ''),
          id: createCreditCardId(product.maskCardNumber),
        }
      }
      if (product.type === BetweenSelfProductToListModel.ProductType.Debit) {
        return {
          type: BetweenSelfProductToListModel.ProductType.Debit,
          accountNumber: product.accountNumber,
          availableBalance: product.availableBalance,
          currencyType: currencyType(product.currency).toClient(),
          maskCardNumber: product.maskCardNumber.replaceAll(' ', ''),
          id: createDebitCardId(product.maskCardNumber),
        }
      }
      if (product.type === BetweenSelfProductToListModel.ProductType.Loan) {
        return {
          type: BetweenSelfProductToListModel.ProductType.Loan,
          accountNumber: product.accountNumber,
          availableBalance: product.availableBalance,
          currencyType: currencyType(product.currency).toClient(),
          id: createCreditLoanId(product.accountNumber),
        }
      }
    })
}
