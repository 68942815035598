import { ApiStatus } from '@/core'
import { checkValid, requireRule, ValidationSchema } from '@/utils/validation-resolver'
import { useProductsSelector } from '../../hooks/useProductsSelector'
import { FormValues, useFormState } from './useFormState'
import { sumToNumber } from '@/features/transfers-by-phone-number/containers/TransfersByPhoneNumberForm/utils'
import { currency } from '@/utils/currency'
import { ResponseType } from '@/models/ResponseType'

export const validationSchema =
  (props: {
    productsSelector: ReturnType<typeof useProductsSelector>
    beneficiaryDetails: ReturnType<typeof useFormState>['beneficiaryDetails']
  }): ValidationSchema<FormValues> =>
  (data, errors) => {
    errors.fromProductId = checkValid(data.fromProductId, [
      (productId) => {
        if (
          !productId ||
          props.productsSelector.status !== ApiStatus.Fulfilled ||
          !props.productsSelector.products[productId]
        ) {
          return
        }
        const product = props.productsSelector.products[productId]
        if (product.availableBalance > 0) {
          return
        }

        return 'Недостаточно средств для списания'
      },
    ])

    if (props.beneficiaryDetails.data?.data?.type === ResponseType.ResolveWithData) {
      props.beneficiaryDetails.data.data.fields.map((field) => {
        const { fieldKey, isRequired, type, hint, errorText, regexp, isReadOnly } = field

        const setRequired = isRequired ? [requireRule('Обязательное поле')] : []
        errors[fieldKey] = checkValid(data[fieldKey], [
          ...setRequired,
          (value) => {
            if (isReadOnly) {
              return
            }

            if (type === 'amount' || type === 'AMOUNT') {
              const { minSumValue, maxSumValue } = field
              const newValue = sumToNumber(value) || 0

              if (newValue < minSumValue) {
                return `Минимальная сумма ${currency(minSumValue, 'RUB').format()}`
              }
              if (newValue > maxSumValue) {
                return `Максимальная сумма ${currency(maxSumValue, 'RUB').format()}`
              }

              const product = props.productsSelector.products[data.fromProductId]
              if (product.availableBalance < newValue) {
                return 'Недостаточно средств для списания'
              }

              return
            }

            if (type === 'String' || type === 'phone_number') {
              if (!regexp) {
                return
              }

              const isValidValue = new RegExp(regexp).test(value)
              if (!isValidValue) {
                return hint || errorText
              }

              return
            }

            return
          },
        ])
      })
    }

    return errors
  }
