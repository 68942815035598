import { Shimmer } from '@platform-ui/components/Shimmer'
import styled from '@emotion/styled'
import { IconButton } from '@platform-ui/components/IconButton'

export const FormContainer = styled.form(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const SelectProductsShimmer = styled(Shimmer)(() => ({
  height: '92px',
  width: '100%',
  borderRadius: '20px',
}))

export const LoadingProductsShimmer = styled(Shimmer)(({ theme }) => ({
  height: '64px',
  width: '100%',
  borderRadius: '12px',
  marginTop: 24,

  [`${theme.media.md}`]: {
    marginTop: 32,
  },
}))

export const TitleShimmer = styled(Shimmer)(() => ({
  height: '24px',
  width: '70px',
  borderRadius: '12px',
  marginBottom: 16,

  ':not(:first-of-type)': {
    marginTop: 60,
  },
}))

export const CircleShimmer = styled(Shimmer)(() => ({
  height: '44px',
  width: '44px',
  borderRadius: '100px',
  alignSelf: 'center',
  position: 'absolute',
  top: '175px',
}))

export const ButtonShimmer = styled(Shimmer)(({ theme }) => ({
  height: '56px',
  width: 'calc(100% - 32px)',
  borderRadius: '12px',
  position: 'fixed',
  left: 16,
  right: 16,
  bottom: 50,

  [`${theme.media.md}`]: {
    width: 'calc(100% - 64px)',
    left: 32,
    right: 32,
    bottom: 32,
  },
}))

export const IconButtonCustom = styled(IconButton)(({ theme }) => ({
  padding: 0,
  color: theme.color['icons/main'],
  position: 'fixed',
  zIndex: '10',
  top: 32,
  left: 24,
}))

export const StyledWrapper = styled.div(({ theme }) => ({
  padding: 16,
  background: '#FFF0E5',
  borderRadius: '20px',

  '& > div > p > div > a': {
    textDecoration: 'none',
    color: theme.color['text/primary'],
  },
}))

export const TextContainer = styled.div(() => ({
  height: 298,
}))

export const IconButtonWrapper = styled(IconButton)(({ theme }) => ({
  border: `2px solid ${theme.color['icons/primary']}`,
  borderRadius: '100px',
  transform: 'rotate(90deg)',
  marginTop: '40px',
  alignSelf: 'center',
  position: 'absolute',
  top: '135px',
  'svg path': {
    fill: theme.color['icons/primary'],
  },
}))
