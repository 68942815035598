import { AdapterModel } from './AdapterModel'
import { ResponseType } from '@/models/ResponseType'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'
import { HttpClient } from '@/core'
import { PhoneTransferModel } from '../PhoneTransferModel'

export const adapter: AdapterModel.Func = (data) => {
  const errorData = data as AdapterModel.ResponseDataError

  /**
   * Сервер всегда возвращает status=200 в заголовке
   * Реальный статус приходит в теле ответа
   */
  if (errorData?.statusCode >= 400) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors:
        errorData.errorDetails?.map(({ error, title }) => ({
          type: error,
          message: title,
        })) || [],
    }
    throw HttpClient.createError(errorData?.statusCode, exception)
  }

  try {
    const successData = data as AdapterModel.ResponseDataSuccess

    return {
      type: ResponseType.ResolveWithData,
      result: createResult(successData),
    }
  } catch (error) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: [{ message: 'Ошибка при обработке полученных данных', type: 'ADAPTER_ERROR' }],
    }

    throw HttpClient.createError(500, exception, error)
  }
}

function createResult(data: AdapterModel.ResponseDataSuccess): PhoneTransferModel.Result {
  /**
   * Бекенд отдает ссылки, которые требуется вставлять в запрос для проксирования внутри банка
   */
  const { checkRequestId, resendRequestId } = data.links.reduce<{
    checkRequestId: string
    resendRequestId: string
  }>(
    (acc, curr) => {
      if (curr.rel === 'check') {
        acc.checkRequestId = curr.href
        return acc
      }
      if (curr.rel === 'resend') {
        acc.resendRequestId = curr.href
        return acc
      }
      return acc
    },
    {
      checkRequestId: null,
      resendRequestId: null,
    }
  )

  const result: PhoneTransferModel.ResultSuccess = {
    checkRequestId: checkRequestId,
    phoneNumber: data.phone,
    resendRequestId: resendRequestId,
    resultCode: PhoneTransferModel.ResultCode.Success,
  }
  return result
}
