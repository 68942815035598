import { MutateOptions, useMutation } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { CheckPaymentModel } from './CheckPaymentModel'
import { adapter } from './adapters/adapter'

export const checkPayment = async (props: CheckPaymentModel.Props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.pms.checkPayment,
    {} as ApiManagerModel.ApiOption
  )

  const response = await HttpClient.post<
    CheckPaymentModel.ResponseData,
    void,
    CheckPaymentModel.RequestData,
    CheckPaymentModel.RequestHeaders,
    CheckPaymentModel.ErrorResponse
  >({
    url: path,
    data: {
      BeneficiaryId: props.beneficiaryId,
      Amount: props.amount,
      Card: {
        MaskedCardNumber: props.maskedCardNumber,
        AccountNumber: props.accountNumber,
        ProductSubType: props.productSubType,
      },
    },
    headers: {
      _ver_: props.apiVersion,
      _os_: '4',
    },
    responseAdapter: adapter,
    control,
  })

  return response
}

export function useCheckPaymentMutation(
  options?: MutateOptions<
    CheckPaymentModel.Response,
    CheckPaymentModel.ErrorResponse,
    CheckPaymentModel.Props
  >
) {
  return useMutation(checkPayment, options)
}
