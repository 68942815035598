import { ApiStatus } from '@/core'
import { ResponseType } from '@/models/ResponseType'

export const useErrorReFetch = (
  productsSelector,
  phoneMutation,
  handlePhoneMutateEvent,
  phoneCommissionMutation,
  handlePhoneCommissionEvent,
  phonePrepareMutation,
  formSubmit,
  phoneTransferMutation
) => {
  if (productsSelector.status === ApiStatus.Rejected) {
    return {
      isError: true,
      reFetch: productsSelector.refetch,
    }
  }
  if (
    phoneMutation.isError ||
    (phoneMutation.isSuccess && phoneMutation.data.response.type !== ResponseType.ResolveWithData)
  ) {
    return {
      isError: true,
      reFetch: handlePhoneMutateEvent,
    }
  }
  if (phoneCommissionMutation.isError) {
    return {
      isError: true,
      reFetch: handlePhoneCommissionEvent,
    }
  }
  if (phonePrepareMutation.isError) {
    return {
      isError: true,
      reFetch: formSubmit,
    }
  }
  if (phoneTransferMutation.isError) {
    return {
      isError: true,
      reFetch: formSubmit,
    }
  }
  return {
    isError: false,
  }
}
