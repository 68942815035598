import { useMutation, UseMutationOptions } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { BetweenSelfConfirmModel } from './BetweenSelfConfirmModel'
import { adapter } from './adapters/adapter'
import { stringTemplate } from '@/utils/string-template'
import { currencyType } from '@/utils/adapter-helpers/currencyType'

export const betweenSelfConfirm: BetweenSelfConfirmModel.Func = async (props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.transfers.betweenSelfConfirm,
    {} as ApiManagerModel.ApiOption
  )

  const response = await HttpClient.post<
    BetweenSelfConfirmModel.ResponseData,
    void,
    BetweenSelfConfirmModel.Requestdata,
    BetweenSelfConfirmModel.RequestHeaders,
    BetweenSelfConfirmModel.ErrorResponse
  >({
    url: stringTemplate<{ requestId: string }>(path, { requestId: props.requestId }),
    headers: null,
    responseAdapter: adapter,
    data: {
      productFrom:
        props.productFrom.productType === 'account'
          ? {
              accountNumber: props.productFrom.accountNumber,
            }
          : {
              accountNumber: props.productFrom.accountNumber,
              maskCardNumber: props.productFrom.maskCardNumber,
            },
      productTo:
        props.productTo.productType === 'account'
          ? {
              accountNumber: props.productTo.accountNumber,
            }
          : {
              accountNumber: props.productTo.accountNumber,
              maskCardNumber: props.productTo.maskCardNumber,
            },
      currency: currencyType(props.currencyType).toServer(),
      fee: props.fee,
      fullSum: props.fullSum,
      sum: props.sum,
      transferPurpose: props.transferPurpose || null,
    },

    control,
  })

  return response
}

export function useBetweenSelfConfirmMutation(
  options?: Omit<
    UseMutationOptions<
      BetweenSelfConfirmModel.Response,
      BetweenSelfConfirmModel.ErrorResponse,
      BetweenSelfConfirmModel.Props,
      unknown
    >,
    'mutationFn'
  >
) {
  return useMutation(betweenSelfConfirm, {
    retry: 3,
    ...options,
  })
}
