import { ApiStatus } from '@/core'
import { observer } from 'mobx-react-lite'
import { Control, useWatch } from 'react-hook-form'
import { FormValues, useFormState } from '../PaymentsForm/useFormState'
import { ButtonContainer, ButtonStyled } from './styled'
import { currency } from '@/utils/currency'

export const ButtonSum = observer<{
  control: Control<FormValues>
  productsSelector: ReturnType<typeof useFormState>['productsSelector']
  isDisabled: boolean
}>((props) => {
  const { productsSelector, control, checkPayment, isPrepare } = props
  const amount = useWatch({ name: 'amount', control: control })

  const text = isPrepare ? 'Оплатить' : 'Продолжить'

  const isDisabled = props.isDisabled || productsSelector.status !== ApiStatus.Fulfilled

  if (!amount || !isPrepare) {
    return (
      <ButtonContainer>
        <ButtonStyled type="submit" isFullWidth isLoading={props.isLoading} isDisabled={isDisabled}>
          {text}
        </ButtonStyled>
      </ButtonContainer>
    )
  }

  const { fee } = checkPayment.data.response

  return (
    <ButtonContainer>
      <ButtonStyled
        type="submit"
        isFullWidth
        isLoading={props.isLoading}
        isDisabled={isDisabled}
        subtitle={fee === 0 ? 'Без комиссии' : `Включая комиссию ${currency(fee, 'RUB').format()}`}
      >
        {`${text} ${currency(Number(amount), 'RUB').format()}`}
      </ButtonStyled>
    </ButtonContainer>
  )
})
