import { ApiStatus } from '@/core'
import { currency } from '@/utils/currency'
import { checkValid, requireRule, ValidationSchema } from '@/utils/validation-resolver'
import isMobilePhone from 'validator/lib/isMobilePhone'
import { useProductsSelector } from '../../hooks/useProductsSelector'
import { useProvidersSelector } from '../../hooks/useProvidersSelector'
import { FormValues } from './useFormState'
import { phoneNumberToUnmask, sumToNumber } from './utils'
import { AppContext } from '@/models/AppContext'

export const validationSchema =
  (props: {
    providersSelector: ReturnType<typeof useProvidersSelector>
    productsSelector: ReturnType<typeof useProductsSelector>
    context: AppContext
  }): ValidationSchema<FormValues> =>
  (data, errors) => {
    errors.phoneNumber = checkValid(data.phoneNumber, [
      requireRule(),
      (phoneNumber) => {
        if (!props.context.config.features.phoneValidation_OB_14737) return

        if (!isMobilePhone.default(phoneNumberToUnmask(phoneNumber), 'ru-RU')) {
          return 'Введите номер телефона'
        }

        return
      },
    ])

    errors.fromProductId = checkValid(data.fromProductId, [
      (productId) => {
        if (
          !productId ||
          props.productsSelector.status !== ApiStatus.Fulfilled ||
          !props.productsSelector.products[productId]
        ) {
          return
        }
        const product = props.productsSelector.products[productId]
        if (product.availableBalance > 0) {
          return
        }

        return 'Недостаточно средств для списания'
      },
    ])

    errors.sum = checkValid(data.sum, [
      requireRule('Обязательное поле'),
      (value) => {
        if (
          props.providersSelector.status !== ApiStatus.Fulfilled ||
          !data.providerId ||
          !props.providersSelector.providers[data.providerId]
        ) {
          return
        }

        const provider = props.providersSelector.providers[data.providerId]

        const { minTransferLimit, maxTransferLimit } = provider.limit

        const newValue = sumToNumber(value) || 0
        if (newValue < minTransferLimit) {
          return `Минимальная сумма ${currency(minTransferLimit, provider.currencyType).format()}`
        }

        if (newValue > maxTransferLimit) {
          return `Максимальная сумма ${currency(maxTransferLimit, 'RUB').format()}`
        }

        const product = props.productsSelector.products[data.fromProductId]

        if (product.availableBalance < newValue) {
          return 'Недостаточно средств для списания'
        }

        return
      },
    ])

    return errors
  }
