import { useMutation, UseMutationOptions } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { BetweenSelfProductToListModel } from './BetweenSelfProductToListModel'
import { adapter } from './adapters/adapter'
import { stringTemplate } from '@/utils/string-template'

/**
 * @see https://confluence.homecredit.ru/confluence/pages/viewpage.action?pageId=117226248
 */
export const betweenSelfProductToList: BetweenSelfProductToListModel.Func = async (props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.transfers.betweenSelfProductToList,
    {} as ApiManagerModel.ApiOption
  )

  const response = await HttpClient.post<
    BetweenSelfProductToListModel.ResponseData,
    void,
    BetweenSelfProductToListModel.RequestData,
    BetweenSelfProductToListModel.RequestHeaders,
    BetweenSelfProductToListModel.ErrorResponse
  >({
    url: stringTemplate<{ requestId: string }>(path, { requestId: props.requestId }),
    headers: null,
    responseAdapter: adapter,
    data: {
      productFrom:
        props.productFrom.productType === 'account'
          ? {
              accountNumber: props.productFrom.accountNumber,
            }
          : {
              accountNumber: props.productFrom.accountNumber,
              maskCardNumber: props.productFrom.maskCardNumber,
            },
    },
    control,
  })

  return response
}

export function useBetweenSelfProductToListMutation(
  options?: Omit<
    UseMutationOptions<
      BetweenSelfProductToListModel.Response,
      BetweenSelfProductToListModel.ErrorResponse,
      BetweenSelfProductToListModel.Props,
      unknown
    >,
    'mutationFn'
  >
) {
  return useMutation(betweenSelfProductToList, {
    retry: 3,
    ...options,
  })
}
