import React, { useState, useRef, ChangeEventHandler, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useController, useFormContext, useWatch } from 'react-hook-form'
import { CSSObject } from '@emotion/react'
import { FormValues } from '../PaymentsForm/useFormState'
import { ChevronDownIcon } from '@platform-ui/icons/ChevronDownIcon'
import { SuccessIcon } from '@platform-ui/icons/SuccessIcon'
import { useForkRef, useResizeObserver } from '@platform-ui/utils'
import { ButtonsGroup } from '@/components/StepperSum'
import {
  FieldWrapper,
  FieldControlCustom,
  Hint,
  InputCurrencyStyled,
  InputMaskStyled,
  InputField,
  Text,
  PopoverStyled,
  Item,
} from './styled'
import { useObserver } from '@/hooks/useObserver'

const FieldSwitcher = (props) => {
  if (props.name === 'amount') {
    return <InputCurrencyStyled {...props} />
  }
  if (props.mask) {
    return <InputMaskStyled {...props} format={props.mask} allowEmptyFormatting mask="_" />
  }

  return <InputField {...props} />
}

export const BeneficiaryDetailsField = observer((props, ref) => {
  const { hint, productsSelector, isReadOnly, allowedValue, isPrepare, isDisabled, mask, regexp } =
    props

  const [inputValue, setInputValue] = useState('')
  const [isOpenList, setIsOpenList] = useState(false)
  const [isScrolling, setIsScrolling] = useState(false)
  const handleClosePopover = () => setIsOpenList(false)

  const observerElem = useRef(null)
  const { isVisible } = useObserver(observerElem, { root: null, rootMargin: '0px 0px -200px 0px' })

  const elemRef = useRef<HTMLDivElement>()
  const rootRef = useForkRef(elemRef, ref)

  const { setValue } = useFormContext<FormValues>()
  const { field, fieldState } = useController(props)

  const fromProductId = useWatch({ name: 'fromProductId', control: props.control })
  const fromProduct = productsSelector.products[fromProductId]

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    let text = event.target.value
    setInputValue(text)

    if (mask) {
      /**
       * Текст с маской храним в localStorage, текст без маски отправляем в форму
       */
      const textWithoutMask = text.replace(/[^0-9А-Яа-яЁёA-Za-z]/g, '')
      const maskHasFirstLetter = mask.startsWith('8') || mask.startsWith('7')
      const regFirstLetter = regexp.startsWith('^(7\\') || regexp.startsWith('^(8\\')
      const formattedText = maskHasFirstLetter
        ? textWithoutMask.slice(regFirstLetter ? 0 : 1)
        : textWithoutMask

      field.onChange(formattedText)
      return
    }

    if (field.name === 'amount' && !!text) {
      /**
       * Убираем символ рубля
       */
      text = event.target.value.replace(/[^0-9,.]/g, '')
      field.onChange(text)
      return
    }
    field.onChange(text)
  }

  const handleClear: React.MouseEventHandler<HTMLButtonElement> = () => {
    setValue(field.name, '')
    setInputValue('')
  }

  /**
   * Для всех полей, кроме поля ввода суммы, показываем подсказку
   * Если поле не прошло валидацию, то скрываем обычную подсказку и выводим текст через error
   */
  const showHint =
    !!hint && field.name !== 'amount' && !fieldState.error?.message && !isReadOnly && !isPrepare

  const isList = !!allowedValue.length
  const [width, setWidth] = useState<CSSObject['width']>('100%')

  useResizeObserver(elemRef, (state) => {
    /**
     * Дизайн выпадающего списка имеет чуть большую ширину, чем у кнопки
     */
    setWidth(`${state.width}px`)
  })

  const handleClick = () => {
    if (!isDisabled && isList) {
      setIsOpenList(!isOpenList)

      if (!isVisible) {
        setIsScrolling(true)
        observerElem.current.scrollIntoView({ behavior: `smooth`, block: 'start' })
      }
    }

    return
  }

  useEffect(() => {
    if (isOpenList && !isVisible && !isScrolling) {
      setIsOpenList(false)
    }
    if (isScrolling && isOpenList && isVisible) {
      setIsScrolling(false)
    }
  }, [isOpenList, isVisible, isScrolling])

  const getValue = field.name === 'amount' || isReadOnly ? field.value : inputValue

  return (
    <FieldWrapper>
      <FieldControlCustom isBlock error={fieldState.error?.message}>
        <FieldSwitcher
          rootRef={rootRef}
          placeholder={props.placeholder}
          value={isList ? allowedValue.find((i) => i.value === field.value)?.displayName : getValue}
          name={field.name}
          isOpen={isOpenList}
          isList={isList}
          onChange={!isDisabled && !isList && handleChange}
          onClear={!isDisabled && !isList && field.value && handleClear}
          rightSlot={
            isPrepare ? <SuccessIcon color="icons/colors/success" /> : isList && <ChevronDownIcon />
          }
          onClick={handleClick}
          isDisabled={isDisabled}
          isReadOnly={isList}
          mask={mask}
        />
        <div ref={observerElem} />
        {showHint && <Hint>{hint}</Hint>}

        {isList && (
          <PopoverStyled
            isOpen={isOpenList}
            anchorEl={elemRef}
            css={{ width: width, height: 100 }}
            placement={'bottom'}
            isArrow={false}
            onClose={handleClosePopover}
          >
            {allowedValue.map((i) => {
              const isSelected = i.value === field.value
              return (
                <Item
                  key={i.value}
                  onClick={() => {
                    setValue(field.name, i.value)
                    handleClosePopover()
                  }}
                >
                  <Text>{i.displayName}</Text>
                  {isSelected && <SuccessIcon color="icons/colors/success" />}
                </Item>
              )
            })}
          </PopoverStyled>
        )}
      </FieldControlCustom>

      {!isDisabled && field.name === 'amount' && fromProduct && (
        <ButtonsGroup
          name={field.name}
          availableBalance={fromProduct.availableBalance}
          limits={{
            minTransferLimit: props.minSumValue,
          }}
          currencyType="RUB"
          setValue={setValue}
          control={props.control}
        />
      )}
    </FieldWrapper>
  )
})
