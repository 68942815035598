import { usePhoneMutation } from '@/api/transfers/phone'
import { statusToApiStatusEnum } from '@/utils/statusToApiStatusEnum'
import { createSelector } from 'reselect'

interface TransferPhoneSelectorState {
  phone: ReturnType<typeof usePhoneMutation>
}
export const transferPhoneSelector = createSelector(
  (state: TransferPhoneSelectorState) => state.phone.data,
  (state: TransferPhoneSelectorState) => state.phone.status,
  (state: TransferPhoneSelectorState) => state.phone.reset,
  (data, status, reset) => ({
    data: data,
    status: statusToApiStatusEnum(status),
    reset,
    refetchKey: 'phone',
  })
)
