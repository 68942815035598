import { AdapterModel } from './AdapterModel'
import { ResponseType } from '@/models/ResponseType'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'
import { HttpClient } from '@/core'

export const adapter: AdapterModel.Func = (data) => {
  const errorData = data as AdapterModel.ResponseDataError
  /**
   * Сервер всегда возвращает status=200 в заголовке
   * Реальный статус приходит в теле ответа
   */
  if (errorData?.statusCode >= 400) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors:
        errorData.errorDetails?.map(({ error, title }) => ({
          type: error,
          message: title,
        })) || [],
    }
    throw HttpClient.createError(errorData?.statusCode, exception)
  }

  try {
    const successData = data as AdapterModel.ResponseDataSuccess

    /**
     * Бекенд отдает ссылки, которые требуется вставлять в запрос для проксирования внутри банка
     */
    const { checkRequestId, resendRequestId } = successData.links.reduce<{
      checkRequestId: string
      resendRequestId: string
    }>(
      (acc, curr) => {
        if (curr.rel === 'check') {
          acc.checkRequestId = curr.href
          return acc
        }
        if (curr.rel === 'resend') {
          acc.resendRequestId = curr.href
          return acc
        }
        return acc
      },
      {
        checkRequestId: null,
        resendRequestId: null,
      }
    )

    return {
      type: ResponseType.ResolveWithData,
      resend: resendRequestId
        ? {
            isEnable: true,
            requestId: resendRequestId,
          }
        : {
            isEnable: false,
          },
      resendNum: successData.resendNum,
      verifyNum: successData.verifyNum,
      checkRequestId: checkRequestId,
    }
  } catch (error) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: [{ message: 'Ошибка при обработке полученных данных', type: 'ADAPTER_ERROR' }],
    }

    throw HttpClient.createError(500, exception, error)
  }
}
