import { ApiStatus, useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import {
  FormProvider,
  useController,
  UseControllerProps,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import {
  FormContainer,
  LoadingProductsShimmer,
  SelectProductsShimmer,
  ButtonShimmer,
  TitleShimmer,
  IconButtonCustom,
  TextContainer,
  StyledWrapper,
  IconButtonWrapper,
  CircleShimmer,
} from './styled'
import { observer } from 'mobx-react-lite'
import { SelectProducts } from '@/components/SelectProducts'
import { GroupFieldTitle } from '../../components/GroupFieldTitle'
import { FormValues, useFormState } from './useFormState'
import { CardError } from '../../components/CardError'
import { ProductsModel } from '@/api/products/products'
import { ButtonSum } from '../ButtonSum'
import { SumField } from '../SumField'
import { FieldControl } from '@platform-ui/components/FieldControl'
import { useSelectProductFromItemsSelector } from '../../hooks/useSelectProductFromItemsSelector'
import { useSelectProductToItemsSelector } from '../../hooks/useSelectProductToItemsSelector'
import { ErrorDataLoadingModal } from '@/components/ErrorDataLoadingModal'
import { NavBackIcon } from '@platform-ui/icons/NavBackIcon'
import { BetweenIcon } from '@platform-ui/icons/BetweenIcon'
import { ResponseType } from '@/models/ResponseType'
import { Headline20, Body16 } from '@platform-ui/typography'

/**
 * Выпадающий список продуктов доступных для списания
 */
const SelectFromProductsField = observer<
  UseControllerProps<FormValues, 'fromProductId'> & {
    productsSelector: ReturnType<typeof useFormState>['productsSelector']
    betweenSelfProductToListMutation: ReturnType<
      typeof useFormState
    >['betweenSelfProductToListMutation']
    betweenSelfConfirmMutation: ReturnType<typeof useFormState>['betweenSelfConfirmMutation']
    betweenSelfCheckMutation: ReturnType<typeof useFormState>['betweenSelfCheckMutation']
  }
>((props) => {
  const {
    productsSelector,
    betweenSelfProductToListMutation,
    betweenSelfConfirmMutation,
    betweenSelfCheckMutation,
    isPrepare,
    ...otherProps
  } = props
  const { config } = useAppContext<AppContext>()
  const isMobile = config.device.isMobile
  const { setValue } = useFormContext()
  const { field, fieldState } = useController(otherProps)
  const toProductId = useWatch({ name: 'toProductId', control: otherProps.control })

  const selectProductsFromItems = useSelectProductFromItemsSelector(productsSelector, toProductId)

  const isDisabled = betweenSelfConfirmMutation.isLoading || betweenSelfCheckMutation.isLoading

  return productsSelector.status === ApiStatus.Fulfilled && field.value ? (
    <FieldControl isBlock error={fieldState.error?.message}>
      <SelectProducts
        onSelect={(selectProduct) => {
          if (selectProduct.id === field.value) {
            return
          }
          setValue(field.name, selectProduct.id, { shouldValidate: true })

          const product = productsSelector.products[selectProduct.id]

          if (!product) {
            return
          }

          betweenSelfProductToListMutation.mutate({
            productFrom:
              product.categoryType === ProductsModel.CategoryType.Account
                ? {
                    accountNumber: product.accountNumber,
                    productType: 'account',
                  }
                : {
                    productType: 'card',
                    accountNumber: product.accountNumber,
                    maskCardNumber: product.maskCardNumber,
                  },
            requestId: productsSelector.requestId,
          })
        }}
        selectedId={field.value}
        items={selectProductsFromItems}
        isMobile={isMobile}
        isLoading={productsSelector.isPendingFromProductIds}
        idDisabled={isDisabled}
        componentType={isPrepare ? 'card' : 'button'}
      />
    </FieldControl>
  ) : productsSelector.status === ApiStatus.Pending ||
    productsSelector.status === ApiStatus.Idle ? (
    <SelectProductsShimmer />
  ) : (
    <CardError message="Не удалось загрузить список счетов" retryClick={productsSelector.refetch} />
  )
})

/**
 * Выпадающий список продуктов доступных для пополнения
 */
const SelectToProductsField = observer<
  UseControllerProps<FormValues, 'toProductId'> & {
    productsSelector: ReturnType<typeof useFormState>['productsSelector']
    betweenSelfConfirmMutation: ReturnType<typeof useFormState>['betweenSelfConfirmMutation']
    betweenSelfCheckMutation: ReturnType<typeof useFormState>['betweenSelfCheckMutation']
  }
>((props) => {
  const {
    productsSelector,
    betweenSelfConfirmMutation,
    betweenSelfCheckMutation,
    isPrepare,
    ...otherProps
  } = props
  const { config } = useAppContext<AppContext>()
  const isMobile = config.device.isMobile
  const { setValue } = useFormContext()
  const { field } = useController(otherProps)
  const fromProductId = useWatch({ name: 'fromProductId', control: otherProps.control })

  const isDisabled = betweenSelfConfirmMutation.isLoading || betweenSelfCheckMutation.isLoading

  const selectProductsToItems = useSelectProductToItemsSelector(productsSelector, fromProductId)

  return productsSelector.status === ApiStatus.Fulfilled && field.value ? (
    <SelectProducts
      onSelect={(product) => {
        if (product.id === field.value) {
          return
        }
        setValue(field.name, product.id)
      }}
      selectedId={field.value}
      items={selectProductsToItems}
      isMobile={isMobile}
      isLoading={productsSelector.isPendingToProductIds}
      idDisabled={isDisabled}
      componentType={isPrepare ? 'card' : 'button'}
    />
  ) : productsSelector.status === ApiStatus.Pending ||
    productsSelector.status === ApiStatus.Idle ? (
    <SelectProductsShimmer />
  ) : (
    <CardError message="Не удалось загрузить список счетов" retryClick={productsSelector.refetch} />
  )
})

export interface TransfersBetweenProductsFormProps {
  /**
   * Идентификатор продукта списания,
   * который требуется сделать выбранным по умолчанию
   */
  fromProductId?: string
  toProductId?: string
  isSoldOutCredit?: boolean
  onClose: () => void
}

export const TransfersBetweenProductsForm = observer<TransfersBetweenProductsFormProps>((props) => {
  const { fromProductId, toProductId, isSoldOutCredit, onClose } = props

  const {
    form,
    handleInvalidSubmit,
    handleValidSubmit,
    productsSelector,
    getClientChecksQuery,
    betweenSelfProductToListMutation,
    betweenSelfCommissionMutation,
    betweenSelfTypeMutation,
    betweenSelfConfirmMutation,
    betweenSelfCheckMutation,
    businessError,
    isPrepareState,
    setIsPrepareState,
    soldOutCreditPaymentDescription,
    isDisabledReverse,
    handleReverseValues,
  } = useFormState(fromProductId, toProductId)

  if (
    getClientChecksQuery.isError ||
    (getClientChecksQuery.isSuccess &&
      getClientChecksQuery.data.type === ResponseType.ResolveWithData &&
      getClientChecksQuery.data.result)
  ) {
    return (
      <ErrorDataLoadingModal
        title="Произошла ошибка"
        description={getClientChecksQuery.data?.result?.resultText || 'Не удалось загрузить данные'}
        buttonText="Попробовать ещё раз"
        buttonClick={() => {
          getClientChecksQuery.remove()
          getClientChecksQuery.refetch()
        }}
      />
    )
  }

  if (
    productsSelector.status === ApiStatus.Rejected &&
    productsSelector.error.type !== 'IS_EMPTY_PRODUCTS'
  ) {
    return (
      <ErrorDataLoadingModal
        title="Произошла ошибка"
        description="Не удалось загрузить данные"
        buttonText="Попробовать ещё раз"
        buttonClick={productsSelector.refetch}
      />
    )
  }

  if (
    productsSelector.status === ApiStatus.Rejected &&
    productsSelector.error.type === 'IS_EMPTY_PRODUCTS'
  ) {
    return (
      <ErrorDataLoadingModal
        title="Перевод невозможен"
        description="Нет доступных для списания продуктов"
        isWarning
        buttonClick={onClose}
        buttonText="Закрыть"
      />
    )
  }

  if (
    productsSelector.status === ApiStatus.Fulfilled &&
    Object.keys(productsSelector.products).length === 1
  ) {
    return (
      <ErrorDataLoadingModal
        title="Перевод невозможен"
        description="У вас только один счёт"
        isWarning
        buttonClick={onClose}
        buttonText="Закрыть"
      />
    )
  }

  return (
    <>
      {isPrepareState && (
        <IconButtonCustom onClick={() => setIsPrepareState(false)}>
          <NavBackIcon size={'s16'} />
        </IconButtonCustom>
      )}
      <FormProvider {...form}>
        <FormContainer
          as="form"
          onSubmit={form.handleSubmit(handleValidSubmit, handleInvalidSubmit)}
        >
          {productsSelector.status !== ApiStatus.Fulfilled ? (
            <TitleShimmer />
          ) : (
            <GroupFieldTitle>Откуда</GroupFieldTitle>
          )}
          <SelectFromProductsField
            productsSelector={productsSelector}
            betweenSelfProductToListMutation={betweenSelfProductToListMutation}
            betweenSelfConfirmMutation={betweenSelfConfirmMutation}
            betweenSelfCheckMutation={betweenSelfCheckMutation}
            name="fromProductId"
            control={form.control}
            isPrepare={isPrepareState}
          />

          {!isSoldOutCredit && (
            <>
              {productsSelector.status !== ApiStatus.Fulfilled ? (
                <CircleShimmer />
              ) : (
                <IconButtonWrapper
                  onClick={handleReverseValues}
                  isDisabled={isDisabledReverse || isPrepareState}
                >
                  <BetweenIcon width="24px" height="24px" color="primary" />
                </IconButtonWrapper>
              )}
              {productsSelector.status !== ApiStatus.Fulfilled ? (
                <TitleShimmer />
              ) : (
                <>
                  <GroupFieldTitle>Куда</GroupFieldTitle>
                  <SelectToProductsField
                    productsSelector={productsSelector}
                    betweenSelfConfirmMutation={betweenSelfConfirmMutation}
                    betweenSelfCheckMutation={betweenSelfCheckMutation}
                    name="toProductId"
                    control={form.control}
                    isPrepare={isPrepareState}
                  />
                </>
              )}
            </>
          )}

          {productsSelector.status === ApiStatus.Fulfilled && (
            <SumField
              productsSelector={productsSelector}
              betweenSelfTypeMutation={betweenSelfTypeMutation}
              betweenSelfCommissionMutation={betweenSelfCommissionMutation}
              betweenSelfConfirmMutation={betweenSelfConfirmMutation}
              betweenSelfCheckMutation={betweenSelfCheckMutation}
              name="sum"
              control={form.control}
              isPrepare={isPrepareState}
              isSoldOutCredit={isSoldOutCredit}
            />
          )}
          {productsSelector.status === ApiStatus.Fulfilled && !!isSoldOutCredit && (
            <TextContainer>
              <StyledWrapper>
                <div>
                  <Headline20 color="main">Внимание!</Headline20>

                  <Body16>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: soldOutCreditPaymentDescription.replace(/Внимание/g, ''),
                      }}
                    />
                  </Body16>
                </div>
              </StyledWrapper>
            </TextContainer>
          )}
          <ButtonSum
            betweenSelfCommissionMutation={betweenSelfCommissionMutation}
            betweenSelfTypeMutation={betweenSelfTypeMutation}
            betweenSelfConfirmMutation={betweenSelfConfirmMutation}
            betweenSelfCheckMutation={betweenSelfCheckMutation}
            productsSelector={productsSelector}
            control={form.control}
            isDisabled={!!businessError}
            isPrepare={isPrepareState}
          />
          {productsSelector.status !== ApiStatus.Fulfilled && (
            <>
              <LoadingProductsShimmer />
              <ButtonShimmer />
            </>
          )}
        </FormContainer>
      </FormProvider>
    </>
  )
})
