import { useQuery, UseQueryOptions } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { adapter } from './adapters/adapter'
import { GetClientChecksModel } from './GetClientChecksModel'

export const getClientChecks: GetClientChecksModel.Func = async () => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.transfers.getClientChecks,
    {} as ApiManagerModel.ApiOption
  )

  return await HttpClient.get<
    GetClientChecksModel.ResponseData,
    void,
    GetClientChecksModel.RequestHeaders,
    GetClientChecksModel.ErrorResponse
  >({
    url: path,
    headers: null,
    responseAdapter: adapter,
    control,
  })
}

export function getClientChecksQueryKey() {
  return '/api/Client/GetClientChecks/'
}

export function useGetClientChecksQuery<T = GetClientChecksModel.ResponseData>(
  options?: Omit<
    UseQueryOptions<GetClientChecksModel.ResponseData, GetClientChecksModel.ErrorResponse, T>,
    'queryKey' | 'queryFn'
  >
) {
  const key = getClientChecksQueryKey()

  return useQuery(key, async () => getClientChecks().then((res) => res.data), options)
}
