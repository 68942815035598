export const messageRulesReplace = (value: string, options: { max?: number } = {}) => {
  const { max = 210 } = options
  /**
   * replace rules
   */

  const rulesReplaceSearch = [
    ['«', '"'],
    ['»', '"'],
    ['“', '"'],
    ['”', '"'],
    ['„', '"'],
    ['–', '-'],
    ['—', '-'],
    ['¬', '-'],
    ['¬', '-'],
    ['‹', '<'],
    ['›', '>'],
    ['‚', ','],
    ['‘', "'"],
    ['’', "'"],
    ['•', '.'],
    ['·', '.'],
    ['°', '.'],
    ['№', 'N'],
  ]

  value = value.slice(0, max)

  rulesReplaceSearch.forEach((rule) => {
    value = value.replaceAll(rule[0], rule[1])
  })

  value = value.replaceAll(/[^?!,.;=\\><_"'а-яА-ЯёЁN0-9\s\x20-\x7E]+/gm, '')

  return value
}
