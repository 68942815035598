import React, { useEffect, useState } from 'react'
import { CircularProgress } from '@platform-ui/components/CircularProgress'
import { observer } from 'mobx-react-lite'
import { Message, SplitedCode, TextNoWrap, Root, ButtonReSendSms, ErrorMessage } from './styled'
import { Body16 } from '@platform-ui/typography'
import { PureInputStatus } from '@platform-ui/components/PureInput'
import { ApiStatus } from '@/core'

export interface SMSCodeProps {
  /**
   * Номер телефона
   */
  maskedPhoneNumber: string
  /**
   * Максимальное количество отправлений сообщения с кодом, по умолчанию 3
   */
  reSendCodeAttempt?: number
  /**
   * Время в секундах до следующей отправки сообщения с кодом, по умолчанию 60
   */
  reSendCodeTimeWaitSec?: number
  /**
   * Статус ручки при отправке кода
   */
  sendCodeStatus: ApiStatus
  /**
   * Статус ручки при повторном запросе кода
   */
  reSendCodeStatus: ApiStatus
  /**
   * Функция отправки кода
   */
  onSuccess: (pinCode: string) => Promise<void>
  /**
   * Функция повторного запроса
   */
  onReSendCode: () => void
  /**
   * Ошибка при отправке кода
   */
  sendCodeErrorMessage?: string
  /**
   * Ошибка при повторном запросе
   */
  reSendCodeErrorMessage?: string
}

export const SMSCode = observer<SMSCodeProps>((props) => {
  const {
    maskedPhoneNumber,
    reSendCodeAttempt = 3,
    reSendCodeTimeWaitSec = 60,
    sendCodeStatus,
    reSendCodeStatus,
    onSuccess,
    onReSendCode,
    sendCodeErrorMessage,
    reSendCodeErrorMessage,
  } = props
  const [isAutoFocus, setIsAutoFocus] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setIsAutoFocus(true)
    }, 400)
  }, [])

  const [value, setValue] = useState('')
  const [codeStatus, setCodeStatus] = useState<PureInputStatus>('default')

  const onFullfilled = (value: string) => {
    setCodeStatus('success')
    onSuccess(value)
  }

  const onChange = (value: string) => {
    setCodeStatus('default')
    setValue(value)
  }

  useEffect(() => {
    if (sendCodeStatus === ApiStatus.Rejected || sendCodeErrorMessage || reSendCodeErrorMessage) {
      setCodeStatus('error')
    }
  }, [sendCodeStatus, sendCodeErrorMessage, reSendCodeErrorMessage])

  const errorMessage = sendCodeErrorMessage || reSendCodeErrorMessage

  return (
    <Root>
      <>
        <SplitedCode
          length={4}
          value={value}
          onChange={onChange}
          onFullfilled={onFullfilled}
          isReadOnly={sendCodeStatus === ApiStatus.Pending || codeStatus === 'success'}
          status={codeStatus}
          isAutoFocus={isAutoFocus}
        />

        <Message isOpen={true}>
          {sendCodeStatus === ApiStatus.Pending ? (
            <CircularProgress size={32} />
          ) : errorMessage ? (
            <ErrorMessage>{errorMessage}</ErrorMessage>
          ) : (
            <Body16>
              Мы выслали код подтверждения на номер <br />
              <TextNoWrap>{maskedPhoneNumber}</TextNoWrap>
            </Body16>
          )}
        </Message>

        <ButtonReSendSms
          attempt={reSendCodeAttempt}
          timeWaitSec={reSendCodeTimeWaitSec}
          onReSendCode={onReSendCode}
          reSendCodeStatus={reSendCodeStatus}
        />
      </>
    </Root>
  )
})
