import { AdapterModel } from './AdapterModel'
import { ResponseType } from '@/models/ResponseType'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'
import { HttpClient } from '@/core'
import { PhoneCheckModel } from '../PhoneCheckModel'

export const adapter: AdapterModel.Func = (data, _, status) => {
  const errorData = data as AdapterModel.ResponseDataError
  /**
   * Сервер всегда возвращает status=200 в заголовке
   * Реальный статус приходит в теле ответа
   */
  if (errorData?.statusCode >= 400 || (status && status >= 400)) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: errorData.errorDetails?.map(({ error, title }) => ({
        type: error,
        message: title,
      })) || [{ message: 'Ошибка в ответе от сервера', type: 'System' }],
    }
    throw HttpClient.createError(errorData?.statusCode || status, exception)
  }

  try {
    const successData = data as AdapterModel.ResponseDataSuccess

    return {
      type: ResponseType.ResolveWithData,
      result: createResult(successData),
    }
  } catch (error) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: [{ message: 'Ошибка при обработке полученных данных', type: 'ADAPTER_ERROR' }],
    }

    throw HttpClient.createError(500, exception, error)
  }
}

function createResult(data: AdapterModel.ResponseDataSuccess): PhoneCheckModel.Result {
  if (data.resultCode === PhoneCheckModel.ResultCode.Match) {
    if (data.status === PhoneCheckModel.Status.InProgress) {
      const result: PhoneCheckModel.ResultMatchInProgress = {
        resultCode: data.resultCode,
        status: data.status,
        transactionId: +data.transactionId,
      }
      return result
    }
    if (data.status === PhoneCheckModel.Status.Success) {
      const result: PhoneCheckModel.ResultMatchSuccess = {
        resultCode: data.resultCode,
        status: data.status,
        transactionId: +data.transactionId,
      }
      return result
    }
  }
  if (data.resultCode === PhoneCheckModel.ResultCode.Mismatch) {
    /**
     * Ссылка имеет вид
     * https://balancer-gateway-rp.omni.homecredit.ru:8112/transfer/v1/self/transfer/0a787836-1e51-4670-833b-3152df7f0081/commission
     *
     * нужно вытащить guid - это наш session (request) id
     */
    const href = data.links.find((item) => item.rel === 'commission').href
    const hrefArr = href.split('/')
    const requestId = hrefArr[hrefArr.length - 2]

    /**
     * Бекенд отдает ссылки, которые требуется вставлять в запрос для проксирования внутри банка
     */
    const { checkRequestId, resendRequestId } = data.links.reduce<{
      checkRequestId: string
      resendRequestId: string
    }>(
      (acc, curr) => {
        if (curr.rel === 'check') {
          acc.checkRequestId = curr.href
          return acc
        }
        if (curr.rel === 'resend') {
          acc.resendRequestId = curr.href
          return acc
        }
        return acc
      },
      {
        checkRequestId: null,
        resendRequestId: null,
      }
    )

    const result: PhoneCheckModel.ResultMismatch = {
      resultCode: data.resultCode,
      checkRequestId: checkRequestId,
      requestId: requestId,
      resend: resendRequestId
        ? {
            isEnable: true,
            requestId: resendRequestId,
          }
        : {
            isEnable: false,
          },
      resendNum: data.resendNum,
      resultText: data.resultText,
      verifyNum: data.verifyNum,
    }
    return result
  }

  if (data.resultCode === PhoneCheckModel.ResultCode.Blocked) {
    const result: PhoneCheckModel.ResultBlocked = {
      resultCode: data.resultCode,
      resultText: data.resultText,
    }
    return result
  }

  if (data.resultCode === PhoneCheckModel.ResultCode.Error) {
    const result: PhoneCheckModel.ResultError = {
      resultCode: data.resultCode,
      resultText: data.resultText,
    }
    return result
  }

  if (data.resultCode === PhoneCheckModel.ResultCode.Funds) {
    const result: PhoneCheckModel.ResultFundsCanceled = {
      resultCode: data.resultCode,
      resultText: data.resultText,
      status: PhoneCheckModel.Status.Canceled,
    }
    return result
  }

  if (data.resultCode === PhoneCheckModel.ResultCode.Limit) {
    if (data.status) {
      if (data.status === PhoneCheckModel.Status.Canceled) {
        const result: PhoneCheckModel.ResultLimitCanceled = {
          resultCode: data.resultCode,
          resultText: data.resultText,
          status: data.status,
        }
        return result
      }
    } else {
      const result: PhoneCheckModel.ResultLimit = {
        resultCode: data.resultCode,
        resultText: data.resultText,
        status: PhoneCheckModel.Status.None,
      }
      return result
    }
  }

  const result: PhoneCheckModel.ResultNotFound = {
    resultCode: PhoneCheckModel.ResultCode.NotFound,
    resultText: data.resultText || 'От сервера пришел не известный код ответа',
  }

  return result
}
