import { MutateOptions, useMutation } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { ResendingModel } from './ResendingModel'
import { adapter } from './adapters/adapter'

export const resending = async () => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.pms.resending,
    {} as ApiManagerModel.ApiOption
  )

  const response = await HttpClient.get<
    ResendingModel.ResponseData,
    void,
    void,
    ResendingModel.ErrorResponse
  >({
    url: path,
    responseAdapter: adapter,
    control,
  })

  return response
}

export function useResendingMutation(
  options?: MutateOptions<ResendingModel.Response, ResendingModel.ErrorResponse>
) {
  return useMutation(resending, options)
}
