import React from 'react'
import { Button } from '@platform-ui/components/Button'
import { useController } from 'react-hook-form'
import { Currency } from '../Currency'
import currencyjs from 'currency.js'
import { ButtonGroupMain, ButtonGroupItem } from './styled'

import { sumStringToNumber } from './utils'
import { TOP_UP_BUTTONS } from './constants'

export const ButtonsGroup = ({
  name,
  availableBalance,
  limits,
  setValue,
  currencyType,
  control,
}) => {
  if (availableBalance <= limits.minTransferLimit) return null

  const { field } = useController<{ sum: string }>({
    control: control,
    name: name as 'sum',
    defaultValue: '',
  })

  const valueNumber = sumStringToNumber(field.value)
  const availableAmount = currencyjs(availableBalance).subtract(valueNumber).value
  const topUpButtons = TOP_UP_BUTTONS.filter((item) => item.amount < availableAmount)

  return (
    <ButtonGroupMain>
      {availableBalance > limits.minTransferLimit && (
        <ButtonGroupItem>
          <Button
            onClick={() => {
              setValue(name, `${availableBalance}`, { shouldValidate: true })
            }}
            type="button"
            sizeSm="m"
            view="secondary"
          >
            Вся сумма на карте: <Currency amount={availableBalance} type={currencyType} />
          </Button>
        </ButtonGroupItem>
      )}
      {topUpButtons.length > 0 &&
        topUpButtons.map((item) => {
          return (
            <ButtonGroupItem key={item.amount}>
              <Button
                onClick={() => {
                  const result = valueNumber + item.amount
                  setValue(name, `${result}`, { shouldValidate: true })
                }}
                type="button"
                sizeSm="m"
                view="secondary"
              >
                {item.prefix} <Currency amount={item.amount} type={currencyType} />
              </Button>
            </ButtonGroupItem>
          )
        })}
    </ButtonGroupMain>
  )
}
