import { ClientCardsModel } from '@/api/pms/clientCards'
import { SelectProductsModel } from '@/components/SelectProducts'

export const createSelectProductsItems = (
  productIds: string[],
  products: Record<string, any>,
  ignoreIds: string[] = []
): SelectProductsModel.Item[] => {
  const newProductIds =
    ignoreIds.length > 0
      ? productIds.filter((id) => !ignoreIds.some((ignoreId) => ignoreId === id))
      : productIds

  return [
    ...newProductIds
      .reduce<Map<'cards', SelectProductsModel.Category>>(
        (acc, productId) => {
          const item = products[productId]

          if (
            item.productType === ClientCardsModel.ProductType.DebitCard ||
            item.productType === ClientCardsModel.ProductType.CreditCard
          ) {
            acc.get('cards').items.push({
              objectType: SelectProductsModel.ObjectType.Card,
              amount: item.availableBalance,
              bgColor: item.bgColor,
              lastFourCardDigits: item.lastFourCardDigits,
              currencyType: item.currencyType,
              description: item.description,
              id: item.id,
              bgColorTheme: item.bgColorTheme,
              paymentSystem: item.paymentSystem,
              title: item.productName,
            })
            return acc
          }
        },
        new Map([
          [
            'cards',
            {
              objectType: SelectProductsModel.ObjectType.Category,
              title: 'Карты',
              items: [],
            },
          ],
        ])
      )
      .values(),
  ].filter((item) => item.items.length > 0)
}
