import { MutateOptions, useMutation } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { VerificationModel } from './VerificationModel'
import { adapter } from './adapters/adapter'

export const verification = async (props: VerificationModel.Props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.pms.verification,
    {} as ApiManagerModel.ApiOption
  )

  const response = await HttpClient.post<
    VerificationModel.ResponseData,
    void,
    VerificationModel.RequestData,
    VerificationModel.RequestHeaders,
    VerificationModel.ErrorResponse
  >({
    url: path,
    data: {
      SmsCode: props.smsCode,
      PaymentId: props.paymentId,
      PaymentHubSession: props.paymentHubSession,
    },
    headers: {
      _ver_: props.apiVersion,
      _os_: '4',
    },
    responseAdapter: adapter,
    control,
  })

  return response
}

export function useVerificationMutation(
  options?: MutateOptions<
    VerificationModel.Response,
    VerificationModel.ErrorResponse,
    VerificationModel.Props
  >
) {
  return useMutation(verification, options)
}
