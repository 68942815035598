import { ResponseType } from '@/models/ResponseType'
import { createSelector } from 'reselect'
import { ProductsModel } from '@/api/products/products'
import { SystemError } from '@/models/SystemError'
import { ApiStatus } from '@/core'
import _keyBy from 'lodash/keyBy'
import { productsAllSelector } from '@/selectors/productsAllSelector'
import { Refetch, RefetchCollection } from '@/utils/useInjectRefetch'
import { SelectProductsModel } from '@/components/SelectProducts'
import { phoneProductListSelector } from './phoneProductListSelector'

export type Product = ProductsModel.Product
export type ProductWithoutCreditLoan = ProductsModel.DebitCard | ProductsModel.CreditCard

export type AggregationError = SystemError<'PARTIAL_ERRORS' | 'IS_EMPTY_PRODUCTS'> | null
export type RejectedError = SystemError<'SYSTEM'>

export type Response = ResponseIdle | ResponsePending | ResponseFulfilled | ResponseRejected
export interface ResponseIdle {
  status: ApiStatus.Idle
}
export interface ResponsePending {
  status: ApiStatus.Pending
}
export interface ResponseFulfilled {
  error?: AggregationError
  status: ApiStatus.Fulfilled
  productIds: string[]
  products: Record<string, ProductWithoutCreditLoan>
  fromSelectProductsItems: SelectProductsModel.Item[]
}
export interface ResponseRejected extends Refetch {
  status: ApiStatus.Rejected
  error: RejectedError
}

export const productsSelector = createSelector(
  productsAllSelector,
  phoneProductListSelector,
  (products, phoneProductList): Response => {
    const refetch = new RefetchCollection()

    let productsList: ProductWithoutCreditLoan[] = []

    const acc = [products].filter((result) => result.status !== ApiStatus.Idle)

    /**
     * Все запросы не были запрошены
     */
    if (acc.length === 0) {
      return {
        status: ApiStatus.Idle,
      }
    }

    /**
     * Хотя бы один запрос в ожидании получении ответа от сервера
     */
    if (
      acc.some((result) => result.status === ApiStatus.Pending) ||
      phoneProductList.status === ApiStatus.Pending
    ) {
      return {
        status: ApiStatus.Pending,
      }
    }

    /**
     * При наличии ошибки необходимо позволить пользователю повторно запросить данные
     */
    acc.forEach((curr) => {
      if (curr.status === ApiStatus.Rejected) {
        refetch.add(curr.refetchKey)
      }
    })

    if (phoneProductList.status === ApiStatus.Rejected) {
      refetch.add(phoneProductList.refetchKey)
    }

    /**
     * Ошибка во всех запросах
     */
    if (
      acc.every((result) => result.status === ApiStatus.Rejected) ||
      phoneProductList.status === ApiStatus.Rejected
    ) {
      return {
        error: {
          message: 'При получении данных произошла ошибка',
          type: 'SYSTEM',
        },
        status: ApiStatus.Rejected,
        ...refetch,
      }
    }

    let hasPartialError = acc.some((result) => result.status === ApiStatus.Rejected)

    /**
     * Дебетовые карты
     * TODO: на первое время доступны только рублевые счета дебетовых карт и хоум карту со статусом SpendingStage
     */
    if (products.data) {
      if (products.data.type === ResponseType.ResolveWithData) {
        // TODO: на первое время доступны только рублевые счета
        productsList = productsList.concat(
          ...products.data.products.filter((product) => {
            if (product.productType === ProductsModel.ProductType.DebitCard) {
              return product.currencyType === 'RUB'
            }
            if (
              product.productType === ProductsModel.ProductType.CreditCard &&
              product.isHomeCard
            ) {
              const homeCard = product as ProductsModel.CreditCard

              return (
                product.currencyType === 'RUB' &&
                homeCard.consumerCreditInformation?.stage ===
                  ProductsModel.ConsumerCreditInformationStage.SpendingStage
              )
            }

            return false
          })
        )
      } else {
        hasPartialError = true
        refetch.add(products.refetchKey)
      }
    }

    const error: AggregationError = hasPartialError
      ? {
          type: 'PARTIAL_ERRORS',
          message:
            'При получении данных произошла ошибка, возможно отображены не все ваши продукты',
        }
      : !productsList.length
      ? {
          type: 'IS_EMPTY_PRODUCTS',
          message: 'Список продуктов пуст',
        }
      : null

    const productsMap = _keyBy(productsList, 'id')

    const phoneProductListArr =
      phoneProductList.data.type === ResponseType.ResolveWithData &&
      phoneProductList.data.products.map((item) => item.maskCardNumber)

    /**
     * Оставляем продукты, которые пришли в phoneProductList
     */
    const productIds = productsList
      .filter((item) => phoneProductListArr.includes(item.maskCardNumber))
      .map((item) => item.id)

    /**
     * Формирование списка продуктов списания для выпадающего меню
     */
    const fromSelectProductsItems = createSelectProductsItems(productIds, productsMap)

    return {
      error,
      products: productsMap,
      productIds,
      fromSelectProductsItems,
      status: ApiStatus.Fulfilled,
      ...refetch,
    }
  }
)

const createSelectProductsItems = (
  productIds: string[],
  products: Record<string, Product>
): SelectProductsModel.Item[] => {
  return [
    ...productIds
      .reduce<Map<'cards' | 'accounts' | 'credits' | 'deposit', SelectProductsModel.Category>>(
        (acc, productId) => {
          const item = products[productId]

          if (
            item.productType === ProductsModel.ProductType.DebitCard ||
            item.productType === ProductsModel.ProductType.CreditCard
          ) {
            acc.get('cards').items.push({
              objectType: SelectProductsModel.ObjectType.Card,
              amount: item.availableBalance,
              bgColor: item.bgColor,
              lastFourCardDigits: item.lastFourCardDigits,
              currencyType: item.currencyType,
              description: item.description,
              id: item.id,
              bgColorTheme: item.bgColorTheme,
              paymentSystem: item.paymentSystem,
              title: item.productName,
            })
            return acc
          }
        },
        new Map([
          [
            'cards',
            {
              objectType: SelectProductsModel.ObjectType.Category,
              title: 'Карты',
              items: [],
            },
          ],
          [
            'accounts',
            {
              objectType: SelectProductsModel.ObjectType.Category,
              title: 'Счета',
              items: [],
            },
          ],
          [
            'credits',
            {
              objectType: SelectProductsModel.ObjectType.Category,
              title: 'Кредиты',
              items: [],
            },
          ],
          [
            'deposit',
            {
              objectType: SelectProductsModel.ObjectType.Category,
              title: 'Вклады',
              items: [],
            },
          ],
        ])
      )
      .values(),
  ].filter((item) => item.items.length > 0)
}
