import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useFromProductIdQueryParam } from '@/hooks/useFromProductIdQueryParam'
import { BaseModal } from '@platform-ui/components/BaseModal'
import { TRANSACTION_TYPE } from '@/features/transfers-list/constants'
import { TransfersByPhoneNumber } from '@/features/transfers-by-phone-number'
import { TransfersBetweenProducts } from '@/features/transfers-between-products'
import { Payments } from '@/features/payments'

export interface TransfersProps {
  onClose: () => void
  onClosed: () => void
  isOpen: boolean
  selectedProductId: string
  contractNumber?: string
  type: string
  title?: string
  beneficiaryId?: number
  categoryId?: number
  setTitle: () => void
  beneficiaryName?: string
  beneficiaryIcon?: string
  isHaveInitTo?: boolean
  isSoldOutCredit?: boolean
}

const useUpdateState = (isPayments, beneficiaryId, setBeneficiaryId) =>
  useEffect(() => {
    if (isPayments && beneficiaryId) {
      setBeneficiaryId(beneficiaryId)
    }
  }, [setBeneficiaryId, beneficiaryId])

export const Transfers = observer((props: TransfersProps) => {
  const {
    isOpen,
    onClose,
    onClosed,
    selectedProductId,
    contractNumber,
    type,
    title,
    isHaveInitTo,
    isSoldOutCredit,
  } = props
  const { fromProductId } = useFromProductIdQueryParam()

  const [beneficiaryId, setBeneficiaryId] = useState(props.beneficiaryId)
  const isPayments = type === TRANSACTION_TYPE.payments
  const showPaymentsBackBtn = isPayments && beneficiaryId
  useUpdateState(isPayments, props.beneficiaryId, setBeneficiaryId)

  const [isPrepareState, setIsPrepareState] = useState(false)
  const isByPhone = type === TRANSACTION_TYPE.byPhone
  const showByPhoneBackBtn = isByPhone && isPrepareState

  if (!type) {
    return null
  }

  return (
    <BaseModal
      isBackBtn={showPaymentsBackBtn || showByPhoneBackBtn}
      backBtnClick={() => {
        if (showPaymentsBackBtn) {
          setBeneficiaryId(null)
        }
        if (showByPhoneBackBtn) {
          setIsPrepareState(false)
        }
        return
      }}
      isOpen={isOpen}
      onClose={onClose}
      title={title || 'Пополнение'}
      onExited={onClosed}
    >
      {type === TRANSACTION_TYPE.byPhone && (
        <TransfersByPhoneNumber
          fromProductId={fromProductId}
          isPrepareState={isPrepareState}
          setIsPrepareState={setIsPrepareState}
        />
      )}
      {type === TRANSACTION_TYPE.betweenAccounts && (
        <TransfersBetweenProducts
          fromProductId={fromProductId}
          toProductId={isHaveInitTo || isSoldOutCredit ? fromProductId : undefined}
          isSoldOutCredit={isSoldOutCredit}
          contractNumber={contractNumber}
          onClose={onClose}
        />
      )}
      {isPayments && (
        <Payments
          beneficiaryId={beneficiaryId}
          setBeneficiaryId={setBeneficiaryId}
          categoryId={props.categoryId}
          setTitle={props.setTitle}
          beneficiaryName={props.beneficiaryName}
          beneficiaryIcon={props.beneficiaryIcon}
        />
      )}
    </BaseModal>
  )
})
