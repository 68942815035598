import { HttpClientModel } from '@/core'
import { ResponseType } from '@/models/ResponseType'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'

export namespace GetClientChecksModel {
  export type Func = () => Promise<Response>

  export type Response = HttpClientModel.Response<ResponseData>
  export type ErrorResponse = HttpClientModel.ErrorResponse<ErrorResponseData>
  export type ResolveWithDataResponse = {
    type: ResponseType.ResolveWithData
    result?: {
      resultText: string
      checkType: CheckTypeEnum
    }
  }

  export enum CheckTypeEnum {
    MobilePhoneChanged = 1,
    TransfersSms = 2,
    MobilePhoneError = 3,
  }

  export type ResponseData = ResolveWithDataResponse
  export type ErrorResponseData = RejectedWithSystemErrorResponse
  export type RequestData = null
  export type RequestHeaders = null
}
