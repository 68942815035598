import React from 'react'
import { ApiStatus, useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { observer } from 'mobx-react-lite'
import { useController, UseControllerProps, useFormContext } from 'react-hook-form'
import { FieldControl } from '@platform-ui/components/FieldControl'
import { SelectProducts } from '@/components/SelectProducts'
import { FormValues, useFormState } from '../PaymentsForm/useFormState'
import { useSelectProductFromItemsSelector } from '../../hooks/useSelectProductFromItemsSelector'
import { CardError } from '../../components/CardError'
import { SelectProductsShimmer } from './styled'

/**
 * Выпадающий список продуктов доступных для списания
 */
export const SelectFromProductsField = observer<
  UseControllerProps<FormValues, 'fromProductId'> & {
    productsSelector: ReturnType<typeof useFormState>['productsSelector']
    betweenSelfProductToListMutation: ReturnType<
      typeof useFormState
    >['betweenSelfProductToListMutation']
    betweenSelfConfirmMutation: ReturnType<typeof useFormState>['betweenSelfConfirmMutation']
    betweenSelfCheckMutation: ReturnType<typeof useFormState>['betweenSelfCheckMutation']
  }
>((props) => {
  const { productsSelector, isPrepare, isLoading, ...otherProps } = props
  const { config } = useAppContext<AppContext>()
  const isMobile = config.device.isMobile
  const { setValue } = useFormContext()
  const { field, fieldState } = useController(otherProps)

  const selectProductsFromItems = useSelectProductFromItemsSelector(productsSelector)

  return productsSelector.status === ApiStatus.Fulfilled && field.value ? (
    <FieldControl isBlock error={fieldState.error?.message}>
      <SelectProducts
        onSelect={(selectProduct) => {
          if (selectProduct.id === field.value) {
            return
          }
          setValue(field.name, selectProduct.id, { shouldValidate: true })
        }}
        selectedId={field.value}
        items={selectProductsFromItems}
        isMobile={isMobile}
        isLoading={productsSelector.status === 'pending' || isLoading}
        componentType={isPrepare ? 'card' : 'button'}
      />
    </FieldControl>
  ) : productsSelector.status === ApiStatus.Pending ||
    productsSelector.status === ApiStatus.Idle ? (
    <SelectProductsShimmer />
  ) : (
    <CardError message="Не удалось загрузить список счетов" retryClick={productsSelector.retry} />
  )
})
