import { HttpClientModel } from '@/core'
import { ResponseType } from '@/models/ResponseType'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'
import { CurrencyType } from '@/models/CurrencyType'

/**
 * @see https://confluence.homecredit.ru/confluence/pages/viewpage.action?pageId=90283937
 */
export namespace PhoneTransferModel {
  export type Func = (props: Props) => Promise<Response>
  export interface Props {
    requestId: string
    providerId: string
    phoneNumber: string
    transactionProductFrom:
      | {
          productType: 'card'
          /**
           * @example  '446915XXXXXX9498'
           */
          maskCardNumber: string
          /**
           * @example  '40817810790012545944'
           */
          accountNumber: string
        }
      | {
          productType: 'account'

          /**
           * @example  '40817810790012545944'
           */
          accountNumber: string
        }
    currencyType: CurrencyType
    transferType: string
    sum: number
    fee: number
    paymentPurpose?: string
    authId: string
  }
  export type Response = HttpClientModel.Response<ResponseData>
  export type ErrorResponse = HttpClientModel.ErrorResponse<ErrorResponseData>
  export type ErrorResponseData = RejectedWithSystemErrorResponse
  export type ResponseData = ResolveWithDataResponse
  export type RequestHeaders = null

  export interface Requestdata {
    /**
     * @example 'hcfb'
     */
    providerId: string
    /**
     * @example '79630265501'
     */
    alias: string
    transactionProductFrom: {
      maskCardNumber?: string
      accountNumber: string
    }
    fee: number
    sum: number
    currency: string
    paymentPurpose: string | null // для transferType === 6 => null
    transferType: string
    authId: string
  }

  export enum TransferType {
    // Внешний.
    External = 1,

    // Внутренний.
    Internal = 2,

    // Внутренний между своими счетами.
    InternalSelf = 3,

    // Внутренний между своими счетами с разными валютами.
    InternalSelfExchange = 4,

    // СБП перевод.
    Sbp = 5,

    // Прямые переводы в сбербанк.
    Sberbank = 6,
  }

  export enum ResultCode {
    Blocked = 'Blocked',
    Error = 'Error',
    Success = 0,
  }

  export interface ResolveWithDataResponse {
    type: ResponseType.ResolveWithData
    result: Result
  }

  export type Result = ResultSuccess | ResultBlocked

  export interface ResultSuccess {
    phoneNumber: string
    resultCode: ResultCode.Success
    checkRequestId: string
    resendRequestId: string
  }

  export interface ResultBlocked {
    resultCode: ResultCode.Blocked
    resultText: string
  }
}
