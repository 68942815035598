import React from 'react'
import { CommissionFieldProps } from '@/components/CommissionField'
import { StepperSumProps } from '@/components/StepperSum'
import { ApiStatus } from '@/core'
import { ResponseType } from '@/models/ResponseType'
import { currency } from '@/utils/currency'
import { observer } from 'mobx-react-lite'
import { useController, UseControllerProps, useFormContext, useWatch } from 'react-hook-form'
import { FromProduct } from '../../selectors/productsByBetweenSelfSelector'
import { FormValues, useFormState } from '../TransfersBetweenProductsForm/useFormState'
import { Root } from './styled'

const useCommissionData = (props: {
  betweenSelfTypeMutation: ReturnType<typeof useFormState>['betweenSelfTypeMutation']
  betweenSelfCommissionMutation: ReturnType<typeof useFormState>['betweenSelfCommissionMutation']
  value: string
}): CommissionFieldProps['commission'] => {
  const { betweenSelfTypeMutation, betweenSelfCommissionMutation, value } = props

  let description: string
  let title: string

  if (betweenSelfTypeMutation.data?.response?.type === ResponseType.ResolveWithData) {
    description = betweenSelfTypeMutation.data.response.enrolment
  }

  if (betweenSelfCommissionMutation.data?.response?.type === ResponseType.ResolveWithData) {
    const { fee, currencyType } = betweenSelfCommissionMutation.data.response
    title = fee === 0 ? 'Без комиссии' : `Комиссия - ${currency(fee, currencyType).format()}`
  }

  if (!value) {
    return null
  }

  if (!description && !title) {
    return null
  }

  return {
    description: description,
    title: title,
  }
}

const useLimits = (props: {
  betweenSelfTypeMutation: ReturnType<typeof useFormState>['betweenSelfTypeMutation']
}): StepperSumProps['limits'] => {
  const { betweenSelfTypeMutation } = props

  if (betweenSelfTypeMutation.status === 'idle') {
    return {
      status: ApiStatus.Idle,
    }
  }
  if (betweenSelfTypeMutation.status === 'loading') {
    return {
      status: ApiStatus.Pending,
    }
  }
  if (
    betweenSelfTypeMutation.status === 'error' ||
    betweenSelfTypeMutation.data?.response?.type !== ResponseType.ResolveWithData
  ) {
    return {
      status: ApiStatus.Rejected,
    }
  }

  const { maxTransferLimit, minTransferLimit } = betweenSelfTypeMutation.data.response.limit

  return {
    status: ApiStatus.Fulfilled,
    maxTransferLimit,
    minTransferLimit,
  }
}

/**
 * Поле ввода суммы
 */
export const SumField = observer<
  UseControllerProps<FormValues, 'sum'> & {
    productsSelector: ReturnType<typeof useFormState>['productsSelector']
    betweenSelfTypeMutation: ReturnType<typeof useFormState>['betweenSelfTypeMutation']
    betweenSelfCommissionMutation: ReturnType<typeof useFormState>['betweenSelfCommissionMutation']
    betweenSelfConfirmMutation: ReturnType<typeof useFormState>['betweenSelfConfirmMutation']
    betweenSelfCheckMutation: ReturnType<typeof useFormState>['betweenSelfCheckMutation']
  }
>((props) => {
  const {
    productsSelector,
    betweenSelfTypeMutation,
    betweenSelfCommissionMutation,
    betweenSelfCheckMutation,
    betweenSelfConfirmMutation,
    isPrepare,
    isSoldOutCredit,
    ...otherProps
  } = props
  const { control } = useFormContext<FormValues>()
  const { field } = useController(otherProps)
  const fromProductId = useWatch({ name: 'fromProductId', control: control })
  const toProductId = useWatch({ name: 'toProductId', control: control })

  const commissionData = useCommissionData({
    betweenSelfTypeMutation,
    betweenSelfCommissionMutation,
    value: field.value,
  })

  const limits = useLimits({ betweenSelfTypeMutation })

  if (productsSelector.status !== ApiStatus.Fulfilled) {
    return null
  }

  if (!fromProductId || !toProductId) {
    return null
  }

  /**
   * Список productsSelector.products имеет общий тип для продуктов списания и пополнения
   * для решения проблем с типизацией необходимо использовать as FromProduct
   */
  const fromProduct = productsSelector.products[fromProductId] as FromProduct

  if (!fromProduct) {
    return null
  }

  const isLoadingCommission = (betweenSelfTypeMutation.isLoading ||
    betweenSelfCommissionMutation.isLoading) as boolean

  const isDisabled = (betweenSelfConfirmMutation.isLoading ||
    betweenSelfCheckMutation.isLoading ||
    productsSelector.betweenSelfIsRefetching) as boolean

  return (
    <Root
      control={props.control}
      availableBalance={fromProduct.availableBalance}
      currencyType={fromProduct.currencyType}
      limits={limits}
      commission={commissionData}
      isLoadingCommission={isLoadingCommission}
      isDisabled={isDisabled || isPrepare}
      name={field.name}
      isPrepare={isPrepare}
      isSoldOutCredit={isSoldOutCredit}
    />
  )
})
