import React, { forwardRef } from 'react'
import { Root } from './CommissionField.styled'
import { InputCurrency, InputCurrencyProps } from '@platform-ui/components/InputCurrency'

export type CommissionFieldProps = InputCurrencyProps & {
  commission?: {
    title?: string
    description?: string
  }
  /**
   * @default false
   */
  isLoadingCommission: boolean
  inputRef?: React.ForwardedRef<HTMLInputElement>
}

export const CommissionField = forwardRef<HTMLDivElement, CommissionFieldProps>((props, ref) => {
  const {
    className,
    commission,
    inputRef,
    isLoadingCommission = false,
    value,
    ...otherProps
  } = props

  const hasCommissionData = !!commission

  return (
    <Root className={className} hasEmitent={hasCommissionData} ref={ref}>
      <InputCurrency ref={inputRef} value={value} {...otherProps} />
    </Root>
  )
})
