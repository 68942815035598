import { MutateOptions, useMutation } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { BeneficiaryDetailsModel } from './BeneficiaryDetailsModel'
import { adapter } from './adapters/adapter'

export const beneficiaryDetails = async (props: BeneficiaryDetailsModel.Props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.pms.beneficiaryDetails,
    {} as ApiManagerModel.ApiOption
  )

  const paymentHubSession = !!props.paymentHubSession
    ? {
        PaymentHubSession: props.paymentHubSession,
      }
    : {}

  const response = await HttpClient.post<
    BeneficiaryDetailsModel.ResponseData,
    void,
    BeneficiaryDetailsModel.RequestData,
    BeneficiaryDetailsModel.RequestHeaders,
    BeneficiaryDetailsModel.ErrorResponse
  >({
    url: path,
    data: {
      BeneficiaryID: props.beneficiaryId,
      Action: props.action,
      FieldValues: props.fields,
      ...paymentHubSession,
    },
    headers: {
      _ver_: props.apiVersion,
      _os_: '4',
    },
    responseAdapter: adapter,
    control,
  })

  return response
}

export function useBeneficiaryDetailsMutation(
  options?: MutateOptions<
    BeneficiaryDetailsModel.Response,
    BeneficiaryDetailsModel.ErrorResponse,
    BeneficiaryDetailsModel.Props
  >
) {
  return useMutation(beneficiaryDetails, options)
}
