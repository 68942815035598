import { useMutation, UseMutationOptions } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { BetweenSelfTypeModel } from './BetweenSelfTypeModel'
import { adapter } from './adapters/adapter'
import { stringTemplate } from '@/utils/string-template'

export const betweenSelfType: BetweenSelfTypeModel.Func = async (props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.transfers.betweenSelfType,
    {} as ApiManagerModel.ApiOption
  )

  const response = await HttpClient.post<
    BetweenSelfTypeModel.ResponseData,
    void,
    BetweenSelfTypeModel.Requestdata,
    BetweenSelfTypeModel.RequestHeaders,
    BetweenSelfTypeModel.ErrorResponse
  >({
    url: stringTemplate<{ requestId: string }>(path, { requestId: props.requestId }),
    headers: null,
    responseAdapter: adapter,
    data: {
      productFrom:
        props.productFrom.productType === 'account'
          ? {
              accountNumber: props.productFrom.accountNumber,
            }
          : {
              accountNumber: props.productFrom.accountNumber,
              maskCardNumber: props.productFrom.maskCardNumber,
            },
      productTo:
        props.productTo.productType === 'account'
          ? {
              accountNumber: props.productTo.accountNumber,
            }
          : {
              accountNumber: props.productTo.accountNumber,
              maskCardNumber: props.productTo.maskCardNumber,
            },
    },
    control,
  })

  return response
}

export function useBetweenSelfTypeMutation(
  options?: Omit<
    UseMutationOptions<
      BetweenSelfTypeModel.Response,
      BetweenSelfTypeModel.ErrorResponse,
      BetweenSelfTypeModel.Props,
      unknown
    >,
    'mutationFn'
  >
) {
  return useMutation(betweenSelfType, {
    retry: 3,
    ...options,
  })
}
