import { HttpClientModel } from '@/core'
import { ResponseType } from '@/models/ResponseType'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'
import { CurrencyType } from '@/models/CurrencyType'

export namespace BetweenSelfModel {
  export type Func = (signal: AbortSignal, props: Props) => Promise<Response>
  export interface Props {
    contractNumber?: string
  }

  export type Response = HttpClientModel.Response<ResponseData>
  export type ErrorResponse = HttpClientModel.ErrorResponse<ErrorResponseData>
  export type ErrorResponseData = RejectedWithSystemErrorResponse
  export type ResponseData = ResolveWithDataResponse | ResolveWithSystemErrorResponse
  export type RequestHeaders = null
  export type Requestdata = null

  export interface ResolveWithSystemErrorResponse {
    type: ResponseType.ResolveWithSystemError
    errorMessage: string
  }

  export interface ResolveWithDataResponse {
    type: ResponseType.ResolveWithData
    productFrom: ProductFrom[]
    productTo: ProductTo[]
    requestId: string
  }

  export type ProductFrom = ProductFromCredit | ProductFromDebit | ProductFromAccount

  export type ProductTo =
    | ProductFromCredit
    | ProductFromDebit
    | ProductFromAccount
    | ProductToLoan
    | ProductToDeposit

  export type Product = ProductFrom | ProductTo
  export enum ProductType {
    Debit = 'debit',
    Account = 'account',
    Credit = 'credit',
    Loan = 'loan',
    Deposit = 'deposit',
  }

  export interface ProductFromCredit {
    /**
     * @example  '446915******9498'
     */
    maskCardNumber: string
    accountNumber: string
    type: ProductType.Credit
    availableBalance: number
    currencyType: CurrencyType
    // Custom
    id: string
  }

  export interface ProductFromDebit {
    /**
     * @example  '446915******9498'
     */
    maskCardNumber: string
    accountNumber: string
    type: ProductType.Debit
    availableBalance: number
    currencyType: CurrencyType
    // Custom
    id: string
  }

  export interface ProductFromAccount {
    /**
     * @example  '40817810790012545944'
     */
    accountNumber: string
    type: ProductType.Account
    availableBalance: number
    currencyType: CurrencyType
    // Custom
    id: string
  }

  export interface ProductToLoan {
    /**
     * @example  '40817810790012545944'
     */
    accountNumber: string
    type: ProductType.Loan
    availableBalance: number
    currencyType: CurrencyType
    // Custom
    id: string
  }

  export interface ProductToDeposit {
    /**
     * @example  '40817810790012545944'
     */
    accountNumber: string
    type: ProductType.Deposit
    availableBalance: number
    currencyType: CurrencyType
    // Custom
    id: string
  }
}
