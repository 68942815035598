import React from 'react'
import { SuccessIcon } from '@platform-ui/icons/SuccessIcon'
import { SbpIcon } from '../../assets/SbpIcon'
import { SbpIconDisabled } from '../../assets/SbpIconDisabled'
import {
  Root,
  ImageCustom,
  Title,
  Desc,
  Icon,
  SbpIconWrapper,
  InfoContainer,
  IconShimmer,
} from './styled'
import { useFormState } from '@/features/transfers-by-phone-number/containers/TransfersByPhoneNumberForm/useFormState'
import { useBankIcon } from '@/features/transfers-by-phone-number/hooks/useBankIcon'
import { Currency } from '@/components/Currency'

export type TransferProviderItemProps = Readonly<{
  className?: string
  providerBankIcon?: string
  bankId: string
  isSbp?: boolean
  fee: number
  isSbpAccountExist?: boolean
  bankName: string
  isSelected: boolean
  phoneGetSbpLogoMutation: ReturnType<typeof useFormState>['phoneGetSbpLogoMutation']
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}>

export const TransferProviderItem = (props: TransferProviderItemProps) => {
  const {
    className,
    providerBankIcon,
    bankId,
    isSbp,
    fee,
    isSbpAccountExist,
    bankName,
    isSelected,
    phoneGetSbpLogoMutation,
    onClick,
  } = props

  const { bankIcon } = useBankIcon({ bankId, phoneGetSbpLogoMutation })

  return (
    <Root className={className} onClick={onClick}>
      <Icon>
        {providerBankIcon || bankIcon ? (
          <ImageCustom alt={bankName} src={providerBankIcon || bankIcon} width={40} height={40} />
        ) : (
          <IconShimmer />
        )}
        {isSbp && (
          <SbpIconWrapper>{isSbpAccountExist ? <SbpIcon /> : <SbpIconDisabled />}</SbpIconWrapper>
        )}
      </Icon>

      <InfoContainer>
        <Title>{bankName}</Title>
        <Desc>
          Комиссия от <Currency amount={fee} />
        </Desc>
      </InfoContainer>

      {isSelected && <SuccessIcon color="icons/colors/success" />}
    </Root>
  )
}
