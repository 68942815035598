import React from 'react'
import { ApiStatus } from '@/core'
import { observer } from 'mobx-react-lite'
import { useController, UseControllerProps, useFormContext, useWatch } from 'react-hook-form'
import { useProductsSelector } from '../../hooks/useProductsSelector'
import { FormValues, useFormState } from '../TransfersByPhoneNumberForm/useFormState'
import { FieldControlCustom, InputPhoneNumberStyled, ButtonStyled } from './styled'
import { authorizationStorage } from '@/storage/authorization'
import { SuccessIcon } from '@platform-ui/icons/SuccessIcon'

/**
 * Поле для ввода номера телефона
 */
export const PhoneNumberField = observer<
  UseControllerProps<FormValues, 'phoneNumber'> & {
    productsSelector: ReturnType<typeof useProductsSelector>
    handlePhoneNumberComplete: ReturnType<typeof useFormState>['handlePhoneNumberComplete']
    isPrepare: boolean
  }
>((props) => {
  const { handlePhoneNumberComplete, productsSelector, isPrepare, ...otherProps } = props
  const { setValue, clearErrors } = useFormContext<FormValues>()
  const { field, fieldState } = useController(otherProps)
  const fromProductId = useWatch({ name: 'fromProductId', control: props.control })

  if (productsSelector.status !== ApiStatus.Fulfilled) {
    return null
  }

  const handleClear: React.MouseEventHandler<HTMLButtonElement> = () => {
    setValue(field.name, '')
    setValue('providerId', '')
    setValue('bankId', '')
    setValue('message', '')
  }

  const { maskedPhoneNumber } = authorizationStorage.getState()

  const setPhoneNumber: React.MouseEventHandler<HTMLButtonElement> = () => {
    setValue(field.name, maskedPhoneNumber)
    clearErrors('phoneNumber')
  }
  const isDisabled = !fromProductId

  return (
    <>
      <FieldControlCustom isBlock error={fieldState.error?.message}>
        <InputPhoneNumberStyled
          rightSlot={isPrepare && <SuccessIcon color="icons/colors/success" />}
          placeholder="Номер телефона"
          inputMode="tel"
          isBlock
          onValueChange={handlePhoneNumberComplete}
          status={fieldState.error?.message ? 'error' : 'default'}
          onClear={handleClear}
          isReadOnly={isPrepare}
          isDisabled={isDisabled}
          {...field}
        />
      </FieldControlCustom>

      {!isDisabled && (!field.value || (field.value && field.value.length < 18)) && (
        <div>
          <ButtonStyled view={'secondary'} size="m" onClick={setPhoneNumber}>
            {' '}
            Себе на номер: {maskedPhoneNumber}
          </ButtonStyled>
        </div>
      )}
    </>
  )
})
