import React from 'react'
import { PaymentsForm } from './containers/PaymentsForm'
import { SearchItem } from '@/components/SearchItem'
import { useBeneficiariesQuery } from '@/api/pms/beneficiaries'
import { ResponseType } from '@/models/ResponseType'
import { Button } from '@platform-ui/components/Button'
import styled from '@emotion/styled'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { ErrorDataLoadingModal } from '@/components/ErrorDataLoadingModal'

export const ButtonContainer = styled.div(({ theme }) => ({
  height: 'auto',
  width: '100%',
  padding: '0px 16px 50px',
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  boxShadow: `0px -32px 16px -10px ${theme.color['background/main']}`,
  background: theme.color['background/main'],

  [`${theme.media.md}`]: {
    padding: '0px 32px 32px',
    borderBottomLeftRadius: theme.borderRadius['r12'],
    borderBottomRightRadius: theme.borderRadius['r12'],
  },
}))

export const ListWrapper = styled.div(({ theme }) => ({
  height: '85vh',

  [`${theme.media.md}`]: {
    height: 'auto',
    paddingBottom: 100,
  },
}))

export type TransfersHCSServiceProps = Readonly<{
  beneficiaryId?: number
  categoryId?: number
  setBeneficiaryId?: () => void
}>

export const BeneficiariesList = ({ setBeneficiaryId, beneficiariesQuery }) => {
  const context = useAppContext<AppContext>()
  const { isSuccess, isLoading, isError, data, refetch } = beneficiariesQuery

  if (isLoading) {
    return <SearchItem isLoading />
  }

  if (isError) {
    return (
      <ErrorDataLoadingModal
        description="Не удалось загрузить список бенефициаров"
        buttonText="Закрыть"
        buttonClick={refetch}
      />
    )
  }

  if (isSuccess && data.type === ResponseType.ResolveWithData) {
    return (
      <ListWrapper>
        <div>
          {data.result.map((item) => (
            <SearchItem
              id={item.beneficiaryId}
              icon={item.beneficiaryIcon}
              text={item.beneficiaryName}
              onClick={() => setBeneficiaryId(item.beneficiaryId)}
            />
          ))}
        </div>
        <ButtonContainer>
          <Button
            isFullWidth
            onClick={() => {
              context.eventBus.transfersModal.close.emit()
            }}
          >
            Закрыть
          </Button>
        </ButtonContainer>
      </ListWrapper>
    )
  }

  return null
}

export const Payments = (props: TransfersHCSServiceProps) => {
  const { beneficiaryId, setBeneficiaryId } = props

  const beneficiariesQuery = useBeneficiariesQuery(
    {
      categoryId: props.categoryId,
    },
    {
      retry: 0,
      enabled: !beneficiaryId,
    }
  )

  if (!beneficiaryId) {
    return (
      <BeneficiariesList
        setBeneficiaryId={setBeneficiaryId}
        beneficiariesQuery={beneficiariesQuery}
      />
    )
  }

  return (
    <PaymentsForm
      {...props}
      categoryId={props.categoryId}
      beneficiaryId={beneficiaryId}
      setBeneficiaryId={setBeneficiaryId}
      beneficiariesQuery={beneficiariesQuery}
    />
  )
}
