import { AdapterModel } from './AdapterModel'
import { ResponseType } from '@/models/ResponseType'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'
import { HttpClient } from '@/core'
import { BetweenSelfCheckModel } from '../BetweenSelfCheckModel'

export const adapter: AdapterModel.Func = (data, _, status) => {
  const errorData = data as AdapterModel.ResponseDataError
  /**
   * Сервер всегда возвращает status=200 в заголовке
   * Реальный статус приходит в теле ответа
   */
  if (errorData?.StatusCode >= 400 || (status && status >= 400)) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: errorData.ErrorDetails?.map(({ Error, Title }) => ({
        type: Error,
        message: Title,
      })) || [{ message: 'Ошибка в ответе от сервера', type: 'System' }],
    }
    throw HttpClient.createError(errorData?.StatusCode || status, exception)
  }

  const errorInternalData = data as AdapterModel.ResponseDataInternalError

  if (errorInternalData?.error && errorInternalData?.description) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: [
        {
          message: errorInternalData.description,
          type: errorInternalData.error,
        },
      ],
    }
    throw HttpClient.createError(500, exception)
  }

  try {
    const successData = data as AdapterModel.ResponseDataSuccess

    return {
      type: ResponseType.ResolveWithData,
      result: createResult(successData),
    }
  } catch (error) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: [{ message: 'Ошибка при обработке полученных данных', type: 'ADAPTER_ERROR' }],
    }

    throw HttpClient.createError(500, exception, error)
  }
}

function createResult(data: AdapterModel.ResponseDataSuccess): BetweenSelfCheckModel.Result {
  if (data.resultCode === BetweenSelfCheckModel.ResultCode.Match) {
    if (data.status === BetweenSelfCheckModel.Status.InProgress) {
      const result: BetweenSelfCheckModel.ResultMatchInProgress = {
        resultCode: data.resultCode,
        status: data.status,
        transactionId: +data.transactionId,
      }
      return result
    }
    if (data.status === BetweenSelfCheckModel.Status.Success) {
      const result: BetweenSelfCheckModel.ResultMatchSuccess = {
        resultCode: data.resultCode,
        status: data.status,
        transactionId: +data.transactionId,
      }
      return result
    }
  }
  if (data.resultCode === BetweenSelfCheckModel.ResultCode.Mismatch) {
    /**
     * Ссылка имеет вид
     * https://balancer-gateway-rp.omni.homecredit.ru:8112/transfer/v1/self/transfer/0a787836-1e51-4670-833b-3152df7f0081/commission
     *
     * нужно вытащить guid - это наш session (request) id
     */
    const href = data.links.find((item) => item.rel === 'commission').href
    const hrefArr = href.split('/')
    const requestId = hrefArr[hrefArr.length - 2]

    /**
     * Бекенд отдает ссылки, которые требуется вставлять в запрос для проксирования внутри банка
     */
    const { checkRequestId, resendRequestId } = data.links.reduce<{
      checkRequestId: string
      resendRequestId: string
    }>(
      (acc, curr) => {
        if (curr.rel === 'check') {
          acc.checkRequestId = curr.href
          return acc
        }
        if (curr.rel === 'resend') {
          acc.resendRequestId = curr.href
          return acc
        }
        return acc
      },
      {
        checkRequestId: null,
        resendRequestId: null,
      }
    )

    const result: BetweenSelfCheckModel.ResultMismatch = {
      resultCode: data.resultCode,
      checkRequestId: checkRequestId,
      requestId: requestId,
      resend: resendRequestId
        ? {
            isEnable: true,
            requestId: resendRequestId,
          }
        : {
            isEnable: false,
          },
      resendNum: data.resendNum,
      resultText: data.resultText,
      verifyNum: data.verifyNum,
    }
    return result
  }

  if (data.resultCode === BetweenSelfCheckModel.ResultCode.Blocked) {
    const result: BetweenSelfCheckModel.ResultBlocked = {
      resultCode: data.resultCode,
      resultText: data.resultText,
    }
    return result
  }

  if (data.resultCode === BetweenSelfCheckModel.ResultCode.Error) {
    const result: BetweenSelfCheckModel.ResultError = {
      resultCode: data.resultCode,
      resultText: data.resultText,
    }
    return result
  }

  if (data.resultCode === BetweenSelfCheckModel.ResultCode.Funds) {
    const result: BetweenSelfCheckModel.ResultFundsCanceled = {
      resultCode: data.resultCode,
      resultText: data.resultText,
      status: BetweenSelfCheckModel.Status.Canceled,
    }
    return result
  }

  if (data.resultCode === BetweenSelfCheckModel.ResultCode.Limit) {
    if (data.status) {
      if (data.status === BetweenSelfCheckModel.Status.Canceled) {
        const result: BetweenSelfCheckModel.ResultLimitCanceled = {
          resultCode: data.resultCode,
          resultText: data.resultText,
          status: data.status,
        }
        return result
      }
    } else {
      const result: BetweenSelfCheckModel.ResultLimit = {
        resultCode: data.resultCode,
        resultText: data.resultText,
        status: BetweenSelfCheckModel.Status.None,
      }
      return result
    }
  }

  /**
   * Прикрутить логгер
   */
  console.log(`BetweenSelfCheckModel.ResultNotFound resultCode: ${data.resultCode}`)

  const result: BetweenSelfCheckModel.ResultNotFound = {
    resultCode: BetweenSelfCheckModel.ResultCode.NotFound,
    resultText: data.resultText || 'От сервера пришел не известный код ответа',
  }

  return result
}
