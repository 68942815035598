import { ResponseType } from '@/models/ResponseType'
import { createSelector } from 'reselect'
import { ProductsModel } from '@/api/products/products'
import { ClientProductsModel } from '@/api/products/clientProducts'
import { SystemError } from '@/models/SystemError'
import { ApiStatus } from '@/core'
import _keyBy from 'lodash/keyBy'
import { productsAllSelector } from '@/selectors/productsAllSelector'
import { clientProductsAllSelector } from '@/selectors/clientProductsAllSelector'
import { Refetch, RefetchCollection } from '@/utils/useInjectRefetch'

export type Product =
  | ProductsModel.DebitCard
  | ProductsModel.CreditCard
  | ProductsModel.Deposit
  | ClientProductsModel.CreditLoan
export type AggregationError = SystemError<'PARTIAL_ERRORS' | 'IS_EMPTY_PRODUCTS'> | null
export type RejectedError = SystemError<'SYSTEM'>

export type Response = ResponseIdle | ResponsePending | ResponseFulfilled | ResponseRejected
export interface ResponseIdle {
  status: ApiStatus.Idle
}
export interface ResponsePending {
  status: ApiStatus.Pending
}
export interface ResponseFulfilled extends Refetch {
  error?: AggregationError
  status: ApiStatus.Fulfilled
  productIds: string[]
  products: Record<string, Product>
}
export interface ResponseRejected extends Refetch {
  status: ApiStatus.Rejected
  error: RejectedError
}

/**
 * Данный селектор загружает зависимости и
 * пока зависимости не будут загружены полностью или не произойдет ошибка,
 * то будет возвращен статус loading или idle
 */
export const productsSelector = createSelector(
  productsAllSelector,
  clientProductsAllSelector,
  (products, clientProducts): Response => {
    const refetch = new RefetchCollection()

    let productsList: Product[] = []

    /**
     * ВАЖНО: дебетовые карты не запрашиваются при низком уровне доступа
     * статус запроса будет idle
     */
    const acc = [products, clientProducts].filter((result) => result.status !== ApiStatus.Idle)

    /**
     * Все запросы не были запрошены
     */
    if (acc.length === 0) {
      return {
        status: ApiStatus.Idle,
      }
    }

    /**
     * Хотя бы один запрос в ожидании получении ответа от сервера
     */
    if (acc.some((result) => result.status === ApiStatus.Pending)) {
      return {
        status: ApiStatus.Pending,
      }
    }

    /**
     * При наличии ошибки необходимо позволить пользователю повторно запросить данные
     */
    acc.forEach((curr) => {
      if (curr.status === ApiStatus.Rejected) {
        refetch.add(curr.refetchKey)
      }
    })

    /**
     * Ошибка во всех запросах
     */
    if (acc.every((result) => result.status === ApiStatus.Rejected)) {
      return {
        error: {
          message: 'При получении данных произошла ошибка',
          type: 'SYSTEM',
        },
        status: ApiStatus.Rejected,
        ...refetch,
      }
    }

    /**
     * Запросов много и в результате доступен частичный показ продуктов
     * при этом пользователю необходимо показать ошибку на интерфейсе
     */
    let hasPartialError = acc.some((result) => result.status === ApiStatus.Rejected)

    /**
     * Кредиты наличными
     */
    if (clientProducts.data) {
      if (
        clientProducts.data.type === ResponseType.ResolveWithData ||
        clientProducts.data.type === ResponseType.ResolveWithDataAndSystemError
      ) {
        /**
         * Первое время доступны только рублевые счета
         */
        productsList = productsList.concat(
          ...clientProducts.data.products.filter((product) => {
            const creditLoans =
              product.status !== ClientProductsModel.CreditLoanGuiStatus.FullRepayment &&
              product.status !== ClientProductsModel.CreditLoanGuiStatus.AllPrepayment
            return creditLoans && product.currencyType === 'RUB'
          })
        )

        if (clientProducts.data.type === ResponseType.ResolveWithDataAndSystemError) {
          /**
           * Ответ частично с ошибками - это связано с тем, что бек выполняет
           * запросы к нескольким сервисам TW и Homer, соответственно может
           * вернуть часть продуктов например от сервиса TW и ошибку от Homer
           */
          if (
            clientProducts.data.systemErrors.some(
              (item) =>
                item.type === 'CREDIT_CARD_AND_CREDIT_LOAN_HOMER_ERROR' ||
                item.type === 'CREDIT_CARD_TW_ERROR'
            )
          ) {
            hasPartialError = true
            refetch.add(clientProducts.refetchKey)
          }
        }
      } else if (clientProducts.data.type !== ResponseType.ResolveWithSystemError) {
        hasPartialError = true
        refetch.add(clientProducts.refetchKey)
      }
    }

    /**
     * Первое время доступны только рублевые счета
     */
    if (products.data) {
      if (products.data.type === ResponseType.ResolveWithData) {
        productsList = productsList.concat(
          ...products.data.products.filter((product) => {
            const debitCards =
              product.productType === ProductsModel.ProductType.DebitCard &&
              product.cardStatusDisplayed === ProductsModel.CardStatusDisplayed.Active
            const creditCards =
              product.productType === ProductsModel.ProductType.CreditCard &&
              product.cardStatusDisplayed === ProductsModel.CardStatusDisplayed.Active
            const accounts =
              product.productType === ProductsModel.ProductType.Account &&
              product.status === ProductsModel.AccountStatus.Open

            return (debitCards || creditCards || accounts) && product.currencyType === 'RUB'
          })
        )
      } else {
        hasPartialError = true
        refetch.add(products.refetchKey)
      }
    }

    const error: AggregationError = hasPartialError
      ? {
          type: 'PARTIAL_ERRORS',
          message:
            'При получении данных произошла ошибка, возможно отображены не все ваши продукты',
        }
      : !productsList.length
      ? {
          type: 'IS_EMPTY_PRODUCTS',
          message: 'Список продуктов пуст',
        }
      : null

    const productsMap = _keyBy(productsList, 'id')

    const productIds = productsList.map((item) => item.id)

    return {
      error,
      products: productsMap,
      productIds,
      status: ApiStatus.Fulfilled,
      ...refetch,
    }
  }
)
