import { Response } from '../selectors/productsByBetweenSelfSelector'
import { selectProductFromItemsSelector } from '../selectors/selectProductFromItemsSelector'
export function useSelectProductFromItemsSelector(
  productsByBetweenSelfSelector: Response,
  ignoreProductId?: string
) {
  return selectProductFromItemsSelector({
    ignoreProductId,
    productsByBetweenSelfSelector,
  })
}
