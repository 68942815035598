import { CommissionFieldProps } from '@/components/CommissionField'
import { StepperSumProps } from '@/components/StepperSum'
import { ApiStatus } from '@/core'
import { ResponseType } from '@/models/ResponseType'
import { currency } from '@/utils/currency'
import { observer } from 'mobx-react-lite'
import { useController, UseControllerProps, useFormContext, useWatch } from 'react-hook-form'
import { FormValues, useFormState } from '../TransfersByPhoneNumberForm/useFormState'
import { Root } from './styled'

const useCommissionData = (props: {
  phoneCommissionMutation: ReturnType<typeof useFormState>['phoneCommissionMutation']
  value: string
}): CommissionFieldProps['commission'] => {
  const { phoneCommissionMutation, value } = props
  let title: string

  if (phoneCommissionMutation.data?.response?.type === ResponseType.ResolveWithData) {
    const { fee, currencyType } = phoneCommissionMutation.data.response
    title = fee === 0 ? 'Без комиссии' : `Комиссия - ${currency(fee, currencyType).format()}`
  }

  if (!value || !title) {
    return null
  }

  return {
    title,
  }
}

const useLimits = (props: {
  providersSelector: ReturnType<typeof useFormState>['providersSelector']
  providerId: string
}): StepperSumProps['limits'] => {
  const { providersSelector, providerId } = props

  if (providersSelector.status === ApiStatus.Idle) {
    return {
      status: ApiStatus.Idle,
    }
  }
  if (providersSelector.status === ApiStatus.Pending) {
    return {
      status: ApiStatus.Pending,
    }
  }
  if (providersSelector.status === ApiStatus.Rejected || !providersSelector.providers[providerId]) {
    return {
      status: ApiStatus.Rejected,
    }
  }

  const provider = providersSelector.providers[providerId]

  const {
    limit: { maxTransferLimit, minTransferLimit },
  } = provider

  return {
    status: ApiStatus.Fulfilled,
    maxTransferLimit,
    minTransferLimit,
  }
}

/**
 * Поле ввода суммы
 */
export const SumField = observer<
  UseControllerProps<FormValues, 'sum'> & {
    providersSelector: ReturnType<typeof useFormState>['providersSelector']
    phoneCommissionMutation: ReturnType<typeof useFormState>['phoneCommissionMutation']
    productsSelector: ReturnType<typeof useFormState>['productsSelector']
    isPrepare: boolean
  }
>((props) => {
  const { providersSelector, phoneCommissionMutation, productsSelector, isPrepare, ...otherProps } =
    props
  const { control } = useFormContext<FormValues>()
  const { field } = useController(otherProps)
  const fromProductId = useWatch({ name: 'fromProductId', control: control })
  const providerId = useWatch({ name: 'providerId', control: control })

  const commissionData = useCommissionData({
    phoneCommissionMutation,
    value: field.value,
  })

  const limits = useLimits({ providerId, providersSelector })

  if (productsSelector.status !== ApiStatus.Fulfilled) {
    return null
  }

  const fromProduct = productsSelector.products[fromProductId]

  if (!fromProduct) {
    return null
  }

  const isLoadingCommission = phoneCommissionMutation.isLoading

  return (
    <Root
      control={props.control}
      availableBalance={fromProduct.availableBalance}
      currencyType={fromProduct.currencyType}
      limits={limits}
      commission={commissionData}
      isLoadingCommission={isLoadingCommission}
      isDisabled={!providerId}
      name={field.name}
      isPrepare={isPrepare}
    />
  )
})
