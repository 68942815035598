import { MutateOptions, useMutation } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { CreatePaymentModel } from './CreatePaymentModel'
import { adapter } from './adapters/adapter'
import { currencyType } from '@/utils/adapter-helpers/currencyType'

export const createPayment = async (props: CreatePaymentModel.Props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.pms.createPayment,
    {} as ApiManagerModel.ApiOption
  )

  const response = await HttpClient.post<
    CreatePaymentModel.ResponseData,
    void,
    CreatePaymentModel.RequestData,
    CreatePaymentModel.RequestHeaders,
    CreatePaymentModel.ErrorResponse
  >({
    url: path,
    data: {
      Amount: props.amount,
      CurrencyCode: currencyType(props.currencyType).toServer(),
      AccountNumber: props.accountNumber,
      BeneficiaryCode: props.beneficiaryId,
      PaymentId: props.paymentId,
      ProcessPaymentType: props.processPaymentType,
      FieldValues: props.fields,
    },
    headers: {
      _ver_: props.apiVersion,
      _os_: '4',
    },
    responseAdapter: adapter,
    control,
  })

  return response
}

export function useCreatePaymentMutation(
  options?: MutateOptions<
    CreatePaymentModel.Response,
    CreatePaymentModel.ErrorResponse,
    CreatePaymentModel.Props
  >
) {
  return useMutation(createPayment, options)
}
