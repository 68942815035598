import { AdapterModel } from './AdapterModel'
import { ResponseType } from '@/models/ResponseType'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'
import { HttpClient } from '@/core'
import { BetweenSelfModel } from '../BetweenSelfModel'
import { currencyType } from '@/utils/adapter-helpers/currencyType'
import { createAccountId } from '@/utils/adapter-helpers/createAccountId'
import { createCreditCardId } from '@/utils/adapter-helpers/createCreditCardId'
import { createDebitCardId } from '@/utils/adapter-helpers/createDebitCardId'
import { createDepositId } from '@/utils/adapter-helpers/createDepositId'
import { createCreditLoanId } from '@/utils/adapter-helpers/createCreditLoanId'

const AVAILABLE_TYPES = new Set<AdapterModel.ProductFrom['type']>([
  'account',
  'credit',
  'debit',
  'loan',
  'deposit',
])
export const adapter: AdapterModel.Func = (data, _, status) => (contractNumber) => {
  /**
   * В частных случаях сервер возвращает статус в заголовке
   * заголовок приходит только если status не валиден
   * @see isValidStatus в HttpClient
   */
  if (status) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: [
        {
          message: 'Произошла ошибка на стороне сервера',
          type: 'UNHANDLED',
        },
      ],
    }
    throw HttpClient.createError(status, exception)
  }

  const rejectedErrorData = data as AdapterModel.ResponseRejectedData

  /**
   * Сервер всегда возвращает status=200 в заголовке
   * Реальный статус приходит в теле ответа
   * Все результаты проверены
   * Считаем статус >= 400 системной ошибкой
   */
  if (rejectedErrorData?.StatusCode >= 400) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors:
        rejectedErrorData.ErrorDetails?.map(({ Error, Title }) => ({
          type: Error,
          message: Title,
        })) || [],
    }
    throw HttpClient.createError(rejectedErrorData?.StatusCode, exception)
  }

  try {
    const errorData = data as AdapterModel.ResponseDataError

    if (errorData.resultCode && errorData.resultCode === 'Error') {
      const result: BetweenSelfModel.ResolveWithSystemErrorResponse = {
        type: ResponseType.ResolveWithSystemError,
        errorMessage: errorData.resultText,
      }
      return result
    }

    const successData = data as AdapterModel.ResponseDataSuccess

    /**
     * Ссылка имеет вид
     * https://balancer-gateway-rp.omni.homecredit.ru:8112/transfer/v1/self/transfer/0a787836-1e51-4670-833b-3152df7f0081/commission
     *
     * нужно вытащить guid - это наш session id
     */
    const href = successData.links.find((item) => item.rel === 'commission').href
    const hrefArr = href.split('/')
    const requestId = hrefArr[hrefArr.length - 2]

    const result: BetweenSelfModel.ResolveWithDataResponse = {
      type: ResponseType.ResolveWithData,
      /**
       * prepareProducts - универсальный, но формирует списки продуктов
       * для соответствующих типов
       */
      productFrom: prepareProducts(successData.productFrom) as BetweenSelfModel.ProductFrom[],
      productTo: prepareProducts(successData.productTo),
      requestId: requestId,
    }
    return result
  } catch (error) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: [{ message: 'Ошибка при обработке полученных данных', type: 'ADAPTER_ERROR' }],
    }

    throw HttpClient.createError(500, exception, error)
  }
}

function prepareProducts(products: AdapterModel.ResponseDataSuccess['productFrom']) {
  return (
    products
      .filter((item) => AVAILABLE_TYPES.has(item.type))
      .map<BetweenSelfModel.Product>((product) => {
        if (product.type === 'account') {
          return {
            type: BetweenSelfModel.ProductType.Account,
            accountNumber: product.accountNumber,
            availableBalance: product.availableBalance,
            currencyType: currencyType(product.currency).toClient(),
            id: createAccountId(product.accountNumber),
          }
        }
        if (product.type === 'credit') {
          return {
            type: BetweenSelfModel.ProductType.Credit,
            accountNumber: product.accountNumber,
            availableBalance: product.availableBalance,
            currencyType: currencyType(product.currency).toClient(),
            maskCardNumber: product.maskCardNumber.replaceAll(' ', ''),
            id: createCreditCardId(product.maskCardNumber),
          }
        }
        if (product.type === 'debit') {
          return {
            type: BetweenSelfModel.ProductType.Debit,
            accountNumber: product.accountNumber,
            availableBalance: product.availableBalance,
            currencyType: currencyType(product.currency).toClient(),
            maskCardNumber: product.maskCardNumber.replaceAll(' ', ''),
            id: createDebitCardId(product.maskCardNumber),
          }
        }
        if (product.type === 'loan') {
          return {
            type: BetweenSelfModel.ProductType.Loan,
            accountNumber: product.accountNumber,
            availableBalance: product.availableBalance,
            currencyType: currencyType(product.currency).toClient(),
            id: createCreditLoanId(product.accountNumber),
          }
        }
        return {
          type: BetweenSelfModel.ProductType.Deposit,
          accountNumber: product.accountNumber,
          availableBalance: product.availableBalance,
          currencyType: currencyType(product.currency).toClient(),
          id: createDepositId(product.accountNumber),
        }
      })
      /**
       * TODO: на первом этапе переводы только с рублевых счетов
       */
      .filter((item) => item.currencyType === 'RUB')
  )
}
