import { useFormState } from '@/features/transfers-by-phone-number/containers/TransfersByPhoneNumberForm/useFormState'
import { ResponseType } from '@/models/ResponseType'
import DefaultBankIcon from '@/features/transfers-by-phone-number/assets/default-bank-icon.svg'

/**
 * Хук находит логотип банка по bankId
 */
export const useBankIcon = (props: {
  bankId: string
  phoneGetSbpLogoMutation: ReturnType<typeof useFormState>['phoneGetSbpLogoMutation']
}) => {
  const { bankId, phoneGetSbpLogoMutation } = props

  /**
   * При загрузке показать шиммер
   */
  if (phoneGetSbpLogoMutation.isLoading || phoneGetSbpLogoMutation.isIdle) {
    return {
      bankIcon: '',
    }
  }
  /**
   * При ошибке дефолтную иконку
   */
  if (
    phoneGetSbpLogoMutation.isError ||
    phoneGetSbpLogoMutation.data?.response?.type !== ResponseType.ResolveWithData ||
    !bankId
  ) {
    return {
      bankIcon: DefaultBankIcon,
    }
  }

  const sbpBank = phoneGetSbpLogoMutation.data.response.data.find(
    ({ sbpBankId }) => sbpBankId === bankId
  )

  return {
    bankIcon: sbpBank?.sbpBankLogo || DefaultBankIcon,
  }
}
