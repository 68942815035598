import { AdapterModel } from './AdapterModel'
import { ResponseType } from '@/models/ResponseType'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'
import { HttpClient } from '@/core'
import { currencyType } from '@/utils/adapter-helpers/currencyType'

export const adapter: AdapterModel.Func = (data) => {
  const errorData = data as AdapterModel.ResponseDataError
  /**
   * Сервер всегда возвращает status=200 в заголовке
   * Реальный статус приходит в теле ответа
   * 401 или 500+ считаем rejected ответами с наличием критической ошибки
   */
  if (errorData?.StatusCode >= 400) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors:
        errorData.ErrorDetails?.map(({ Error, Title }) => ({
          type: Error,
          message: Title,
        })) || [],
    }
    throw HttpClient.createError(errorData?.StatusCode, exception)
  }

  try {
    const successData = data as AdapterModel.ResponseDataSuccess
    /**
     * Есть ошибки от сервера
     */
    if (successData?.resultCode || successData?.resultText) {
      return {
        type: ResponseType.ResolveWithSystemError,
        systemErrors: [
          {
            type: successData?.resultCode || 'Error',
            message: successData?.resultText || '',
          },
        ],
      }
    }

    return {
      type: ResponseType.ResolveWithData,
      products: successData.products.map((product) => ({
        accountNumber: product.accountNumber,
        availableBalance: product.availableBalance,
        currency: currencyType(product.currency).toClient(),
        isDefault: product.isDefault,
        maskCardNumber: product.maskCardNumber,
        type: product.type,
      })),
    }
  } catch (error) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: [{ message: 'Ошибка при обработке полученных данных', type: 'ADAPTER_ERROR' }],
    }

    throw HttpClient.createError(500, exception, error)
  }
}
