import styled from '@emotion/styled'
import { FieldControl } from '@platform-ui/components/FieldControl'
import { Input } from '@platform-ui/components/Input'

export const FieldControlCustom = styled(FieldControl)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  paddingBottom: 110,
}))

export const InputStyled = styled(Input)(({ theme, isDisabled, isReadOnly }) => ({
  input: {
    background: isDisabled && theme.color['surface/tertiary'],
    color: isReadOnly && theme.color['text/secondary'],
  },
  span: {
    color: isDisabled && '#BCC0CC',
  },
}))
