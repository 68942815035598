import { SelectProductsModel } from '@/components/SelectProducts'
import { ApiStatus } from '@/core'
import { createSelector } from 'reselect'
import { createSelectProductsItems } from '../utils/createSelectProductItems'
import { Response } from './productsByBetweenSelfSelector'

export const selectProductToItemsSelector = createSelector(
  (state: { productsByBetweenSelfSelector: Response }) => state.productsByBetweenSelfSelector,
  (state: { ignoreProductId: string }) => state.ignoreProductId,
  (productsByBetweenSelfSelector, ignoreProductId): SelectProductsModel.Item[] => {
    if (productsByBetweenSelfSelector.status !== ApiStatus.Fulfilled) {
      return []
    }

    return createSelectProductsItems(
      productsByBetweenSelfSelector.toProductIds,
      productsByBetweenSelfSelector.products,
      ignoreProductId ? [ignoreProductId] : []
    )
  }
)
