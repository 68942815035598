import React, { useState, useRef, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useWatch } from 'react-hook-form'
import { ChevronDownIcon } from '@platform-ui/icons/ChevronDownIcon'
import { SearchList } from '@/features/search-beneficiaries/components/SearchList'
import DefaultIcon from '@/assets/default-icon.svg'
import { useForkRef } from '@platform-ui/utils'
import { BeneficiaryFieldWrapper, BankLogo, InputStyled } from './styled'
import { SuccessIcon } from '@platform-ui/icons/SuccessIcon'
import { BeneficiariesModel } from '@/api/pms/beneficiaries/BeneficiariesModel'
import { useObserver } from '@/hooks/useObserver'

export const BeneficiaryField = observer((props, ref) => {
  const {
    setTitle,
    setBeneficiaryId,
    control,
    isPrepare,
    name,
    selectedBeneficiaryName,
    selectedBeneficiaryIcon,
    isLoading,
    categoryId,
  } = props

  const [isOpenList, setIsOpenList] = useState(false)
  const [isScrolling, setIsScrolling] = useState(false)
  const handleClosePopover = () => setIsOpenList(false)

  const elemRef = useRef<HTMLDivElement>()
  const rootRef = useForkRef(elemRef, ref)
  const [searchValue, setSearchValue] = useState('')
  const beneficiaryId = useWatch({ name: 'beneficiaryId', control: control })

  const observerElem = useRef(null)
  const { isVisible } = useObserver(observerElem, { root: null, rootMargin: '-180px 0px 0px 0px' })

  const handleClick = (item: BeneficiariesModel.Beneficiary) => {
    setBeneficiaryId(item.beneficiaryId)
    setTitle(item.categoryName)
    handleClosePopover()
  }

  const handleInputClick = () => {
    if (!isLoading) {
      setIsOpenList(!isOpenList)

      if (!isVisible) {
        setIsScrolling(true)
        observerElem.current.scrollIntoView()
      }
    }

    return
  }

  useEffect(() => {
    if (isOpenList && !isVisible && !isScrolling) {
      setIsOpenList(false)
    }
    if (isScrolling && isOpenList && isVisible) {
      setIsScrolling(false)
    }
  }, [isOpenList, isVisible, isScrolling])

  return (
    <>
      <BeneficiaryFieldWrapper ref={observerElem}>
        {selectedBeneficiaryName && (
          <BankLogo
            src={selectedBeneficiaryIcon}
            height={isPrepare ? 40 : 24}
            width={isPrepare ? 40 : 24}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null // prevents looping
              currentTarget.src = DefaultIcon
            }}
          />
        )}

        <InputStyled
          isPrepare={isPrepare}
          isDisabled={props.isDisabled}
          isReadOnly
          name={name}
          control={props.control}
          rootRef={rootRef}
          isBlock
          placeholder="Поставщик услуг"
          value={selectedBeneficiaryName}
          rightSlot={isPrepare ? <SuccessIcon color="icons/colors/success" /> : <ChevronDownIcon />}
          onClick={handleInputClick}
          isOpen={isOpenList}
        />
      </BeneficiaryFieldWrapper>
      <SearchList
        elemRef={elemRef}
        isOpen={isOpenList}
        onClose={handleClosePopover}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        onClick={handleClick}
        categoryId={categoryId}
        showSearchInput
        selectedBeneficiaryId={beneficiaryId}
      />
    </>
  )
})
