import { MutateOptions, useMutation } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { PhoneGetSbpLogoModel } from './PhoneGetSbpLogoModel'
import { adapter } from './adapters/adapter'

export const phoneGetSbpLogo: PhoneGetSbpLogoModel.Func = async (props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.transfers.phoneGetSbpLogo,
    {} as ApiManagerModel.ApiOption
  )

  return await HttpClient.post({
    url: path,
    data: {
      Banks: props.banks,
    },
    responseAdapter: adapter,
    control,
  })
}

export function usePhoneGetSbpLogoMutation(
  options?: MutateOptions<
    PhoneGetSbpLogoModel.Response,
    PhoneGetSbpLogoModel.ErrorResponse,
    PhoneGetSbpLogoModel.Props
  >
) {
  return useMutation(phoneGetSbpLogo, options)
}
