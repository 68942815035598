import { useRouter } from '@/next/router'

export const useFromProductIdQueryParam = (): {
  fromProductId: string | null
} => {
  const router = useRouter()

  const query = router.query as {
    productId?: string
  }

  if (!query.productId) {
    return {
      fromProductId: null,
    }
  }

  return {
    fromProductId: query.productId,
  }
}
