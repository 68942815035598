import React from 'react'

export const SbpIcon = () => (
  <svg width="24" height="24" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
      fill="#F5F5F7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3999 13.2246L13.3164 18.4201L13.3165 21.5879L10.4047 26.7731L10.3999 13.2246Z"
      fill="#575FAA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.5947 16.5289L24.3265 14.8597L29.9163 14.8555L21.5947 19.9381V16.5289Z"
      fill="#DB185B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.5801 13.1943L21.5953 20.0715L18.6729 18.2821V8L21.5801 13.1943Z"
      fill="#FCB52E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.9167 14.8555L24.3268 14.8597L21.5801 13.1943L18.6729 8L29.9167 14.8555Z"
      fill="#F47732"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.5947 26.8019V23.4646L18.6729 21.709L18.6733 32.0001L21.5947 26.8019Z"
      fill="#60BB46"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.3196 25.1482L13.3164 18.4201L10.3999 13.2246L29.9051 25.1418L24.3196 25.1482Z"
      fill="#128ACB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.6733 32.0001L21.5948 26.8019L24.3198 25.1481L29.9053 25.1416L18.6733 32.0001Z"
      fill="#098441"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4043 26.7725L18.6951 21.7088L15.9071 20.0039L13.316 21.5871L10.4043 26.7725Z"
      fill="#9151A0"
    />
  </svg>
)
