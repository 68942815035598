import { ResponseType } from '@/models/ResponseType'
import { createSelector } from 'reselect'
import { SystemError } from '@/models/SystemError'
import { ApiStatus } from '@/core'
import _keyBy from 'lodash/keyBy'
import { PhoneModel } from '@/api/transfers/phone'
import { transferPhoneSelector } from './phoneSelector'
import { Refetch, RefetchCollection } from '@/utils/useInjectRefetch'
import { CurrencyType } from '@/models/CurrencyType'
export type RejectedError = SystemError<'SYSTEM'>
export type AggregationError = SystemError<
  'NETWORK' | 'ERROR_NO_RECIPIENT_CARDS' | 'INTERNAL_SERVER_ERROR'
> | null

export type Response = ResponseIdle | ResponsePending | ResponseFulfilled | ResponseRejected
export interface ResponseIdle {
  status: ApiStatus.Idle
}
export interface ResponsePending {
  status: ApiStatus.Pending
}
export interface ResponseFulfilled {
  error?: AggregationError
  status: ApiStatus.Fulfilled
  screenSelectableProviderList: {
    id: string
    /**
     * Логотип банка получателя
     */
    bankIcon?: string
    /**
     * Наименование банка получателя
     */
    bankName: string
    /**
     * ФИО
     */
    recipientName: string
    fee: number
    currencyType: CurrencyType
    isAvailableSendMessage: boolean
    type: string
    defaultBankId?: string
    banks?: {
      id: string
      name: string
      isSbpAccountExist: boolean
      isDuplicate: boolean
    }[]
  }[]
  screenActionProviderList: {
    type: 'sbp'
    id: string
  }[]
  providers: Record<string, PhoneModel.Provider>
  providerIds: PhoneModel.Provider['id'][]
  commissionRequestId: string
  prepareRequestId: string
  transferRequestId: string
  requestId: string
  reset: () => void
}
export interface ResponseRejected extends Refetch {
  status: ApiStatus.Rejected
  error: RejectedError
}

export const providerSelector = createSelector(transferPhoneSelector, (transferPhone): Response => {
  const refetch = new RefetchCollection()

  if (transferPhone.status === ApiStatus.Idle) {
    return {
      status: ApiStatus.Idle,
    }
  }

  if (transferPhone.status === ApiStatus.Pending) {
    return {
      status: ApiStatus.Pending,
    }
  }

  if (transferPhone.status === ApiStatus.Rejected) {
    refetch.add(transferPhone.refetchKey)
    return {
      error: {
        message: 'При получении списка счетов произошла ошибка',
        type: 'SYSTEM',
      },
      status: ApiStatus.Rejected,
      ...refetch,
    }
  }

  const { response } = transferPhone.data

  if (response.type === ResponseType.ResolveWithSystemError) {
    const systemError = response.systemErrors[0]

    return {
      error: {
        message: systemError.message,
        type: systemError.type,
      },
      screenSelectableProviderList: [],
      screenActionProviderList: [],
      providerIds: [],
      providers: {},
      status: transferPhone.status,
      commissionRequestId: '',
      prepareRequestId: '',
      transferRequestId: '',
      requestId: '',
      reset: transferPhone.reset,
    }
  }

  const providers: Record<string, PhoneModel.Provider> = _keyBy(response.providers, 'id')
  const providerIds: PhoneModel.Provider['id'][] = response.providers.map((item) => item.id)

  const screenProviderList: ResponseFulfilled['screenSelectableProviderList'] =
    response.providers.map((item) => {
      if (item.id === 'sbp') {
        return {
          bankName: item.bankName,
          fee: item.fee,
          currencyType: item.currencyType,
          id: item.id,
          recipientName: item.recipientName,
          type: 'sbp',
          isAvailableSendMessage: item.isAvailableSendMessage,
          defaultBankId: item.defaultBankId,
          banks: item.banks,
        }
      }

      return {
        bankIcon: item.bankLogo,
        bankName: item.bankName,
        fee: item.fee,
        currencyType: item.currencyType,
        id: item.id,
        recipientName: item.recipientName,
        type: 'provider',
        isAvailableSendMessage: item.isAvailableSendMessage,
      }
    })

  return {
    screenSelectableProviderList: screenProviderList,
    screenActionProviderList: [],
    providerIds,
    providers,
    status: transferPhone.status,
    commissionRequestId: response.commissionRequestId,
    prepareRequestId: response.prepareRequestId,
    transferRequestId: response.transferRequestId,
    requestId: response.requestId,
    reset: transferPhone.reset,
  }
})
