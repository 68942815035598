import styled from '@emotion/styled'
import { Accordion } from '@platform-ui/components/Accordion'
import { Body16, Headline32 } from '@platform-ui/typography'
import { SplitedCodeInputs } from '@platform-ui/components/SplitedCodeInputs'
import { ReSendCode } from '@/features/transfers-by-phone-number/containers/SMSCode/ReSendCode'

export const Root = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

export const Main = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: 'auto',
  marginBottom: 'auto',

  [`${theme.media.md}`]: {
    marginTop: '0px',
  },
}))

export const Title = styled(Headline32)(({ theme }) => ({
  marginTop: '8px',
  textAlign: 'center',
  width: '100%',
  [`${theme.media.md}`]: {
    marginTop: '16px',
  },
}))

export const SplitedCode = styled(SplitedCodeInputs)(() => ({
  marginTop: '140px',
}))

export const Message = styled(Accordion)(({ theme }) => ({
  ...theme.typography['body/14'],
  marginTop: '24px',
  textAlign: 'center',

  [`${theme.media.md}`]: {
    ...theme.typography['body/16'],
    marginTop: '24px',
  },
}))

export const TextNoWrap = styled.span(() => ({
  whiteSpace: 'nowrap',
}))

export const Footer = styled.div(() => {
  return {
    marginTop: '48px',
  }
})

export const ButtonReSendSms = styled(ReSendCode)(() => ({
  position: 'fixed',
  bottom: 32,
  width: 'calc(100% - 64px)',
}))

export const ErrorMessage = styled(Body16)(({ theme }) => ({
  color: theme.color['text/error'],
  maxWidth: 320,
}))
